import { View, TextInput, StyleProp, TextStyle } from "react-native";
import { DepopDataType, DepopInputType } from "./datatype";
import { useEffect, useState } from "react";

type IDepopOption = DepopInputType & {
  option: DepopInputType;
  style?: StyleProp<TextStyle>;
};

export const DepopTextInput = ({option, style, onChange}: IDepopOption) => {
  const [value, setValue] = useState<DepopDataType>({
    id: option.id,
    value: option.default,
    type: option.type,
  });
  useEffect(() => {
    onChange?.(value);
  }, [value]);
  return (
    <View>
      <TextInput
        style={style}
        onChangeText={(text) => setValue({
          ...value,
          value: text,
        })}
        value={(value.value ?? '').toString()}
        placeholder={option.label}
        keyboardType={option.type === "number" ? "numeric" : "default"}
      />
    </View>
  );
};