import { GenericFields } from "../../types/form-data";

export type DepopInputType = {
  label: string;
  id: string;
  options: DepopOptionType[];
  type?: DepopInputTypeEnum;
  isList: boolean;
  default: any;
  onChange: (value: DepopDataType) => void;
}

export type DepopCheckboxType = DepopInputType & DepopOptionType & {
  default: boolean;
}

export type DepopListType = DepopInputType & {
  default: null;
}

export enum DepopInputTypeEnum {
  checkbox = 'checkbox',
  removed = 'removed',
  number = 'number',
  text = 'text',
}

export type DepopOptionType = {
  label: string,
  id: string,
  inputs: {[k:string]: DepopInputType};
}

export type DepopContainerType = {
  section: string;
  inputs: {[k:string]: DepopInputType};
}

// Essentially mapped to page.click's
export type DepopDataType = {
  id: string;
  value?: string | boolean | number;
  type?: DepopInputTypeEnum;
  selectedValues?: DepopDataType[];
}

export type DepopSectionType = {
  id: string;
  value: DepopDataType[];
}

export type GenericDepopFormType = {
  id: string;
  value: string | boolean | number;
  type?: DepopInputTypeEnum;
}