import { DocumentData } from 'firebase/firestore'

export function getTotalSalesByMarketplace(items: DocumentData[]) {
  // get sold items
  const soldItems = items.filter((item) => item.status === 'sold')

  // calculate total sales
  let totalSales = 0

  for (const sale of soldItems) {
    totalSales += sale.quantity * sale.price
  }
  return totalSales
}
