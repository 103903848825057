import AsyncStorage from '@react-native-async-storage/async-storage'
import { createContext, useEffect, useState } from 'react'

export type platformtType = {
  name: string
  logo: string
}

export type userType =
  | {
      email?: string | null
      isLoggedIn?: boolean | null
      userId?: string | null
      name?: string | null
      country?: string
      state?: string
      photo?: string
      platforms?: platformtType[] | null
      profileImage?: { uri: string | null } | null
    }
  | undefined

type stateType = {
  user: userType
}

type contextType = {
  userState: stateType
  setUserState: (data: stateType) => void
}

const initialState: stateType = {
  user: undefined,
}

export const AppUserContext = createContext<contextType>(initialState as any)
export function AppUserProvider({ children }: { children: React.ReactNode }) {
  const [userState, setUserState] = useState(initialState)
  useEffect(() => {
    AsyncStorage.getItem('user')
      .then((value: any) => {
        if (value) {
          setUserState(JSON.parse(value))
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    AsyncStorage.setItem('user', JSON.stringify(userState))
  }, [userState])

  return (
    <AppUserContext.Provider value={{ userState, setUserState }}>
      {children}
    </AppUserContext.Provider>
  )
}
