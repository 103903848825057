import { SafeAreaView, StyleSheet, View, ScrollView } from 'react-native'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { themeColors } from '../../themes'
import { Logo } from '../../icons/logo'
import { doc, DocumentData, getDoc, getFirestore, onSnapshot } from 'firebase/firestore'
import { AuthContext } from '../../context/auth-context'
import { useGetProfilePlatforms } from '../account/hooks/use-get-profile-platforms'
import { ConnectedMarketplace } from './component/connected-marketplace'
import { DepopListingsSection } from './component/depop-listings-section'
import { MercariListingsSection } from './component/mercari-listing-section'
import { ProductDetail } from '../product-detail/components/product-detail'
import { useGetMercariListings } from '../../hooks/use-get-mercari-listings'
import { BottomSheetModal } from '@gorhom/bottom-sheet'

export function HomeScreen() {
  const db = getFirestore()
  const { authState } = useContext(AuthContext)
  const { profilePlatforms, isLoadingPlatform } = useGetProfilePlatforms()
  const { loading, setLoading } = useGetMercariListings()
  const [listing, setListing] = useState({})
  const [ready, setReady] = useState<boolean>(false)

  const bottomSheetModalRef = useRef<BottomSheetModal>(null)
  const snapPoints = useMemo(() => ['92%'], [])

  const onOpenButtomSheet = (item: DocumentData) => {
    //@todo type
    setLoading(true)
    const activeListing = doc(
      db,
      'profiles',
      authState.userId,
      'platforms',
      'mercari',
      'activeListings',
      item.id,
    )
    getDoc(activeListing).then((doc) => {
      if (doc.exists()) {
        const item = doc.data()
        setListing(item)
        setLoading(false)
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!')
        setLoading(false)
      }
    })

    bottomSheetModalRef.current?.present()
  }

  const onConfirmPress = () => {
    bottomSheetModalRef.current?.close()
  }

  useEffect(() => {
    if (!authState.isLoggedIn) return
    const profile = doc(db, 'profiles', authState.userId, 'platforms', 'mercari')
    const unsub = onSnapshot(profile, (doc) => {
      console.log('Current data: ', doc.data())
      setReady(doc.data()?.ready)
    })
    return unsub
  }, [])

  return (
    <SafeAreaView style={styles.container}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={[styles.headerWrapper]}>
          <View style={styles.header}>
            <View style={{ paddingBottom: 16 }}>
              <Logo />
            </View>
            <ConnectedMarketplace
              ready={ready}
              isLoadingPlatform={isLoadingPlatform}
              profilePlatforms={profilePlatforms}
            />
          </View>
        </View>

        {/* Mercari listings section */}
        <MercariListingsSection
          profilePlatforms={profilePlatforms}
          isLoadingPlatform={isLoadingPlatform}
          ready={ready}
          onOpenButtomSheet={onOpenButtomSheet}
        />

        {/* Depop listings section */}
        <DepopListingsSection
          profilePlatforms={profilePlatforms}
          isLoadingPlatform={isLoadingPlatform}
          ready={ready}
        />
      </ScrollView>

      {/* <ProductDetail
        item={listing}
        isLoadingItem={loading}
        onConfirmPress={onConfirmPress}
        bottomSheetModalRef={bottomSheetModalRef}
        snapPoints={snapPoints}
      /> */}
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: themeColors.appBackground,
    flex: 1,
  },

  header: {
    paddingHorizontal: 16,
  },

  headerWrapper: {
    paddingTop: 16,
  },
})
