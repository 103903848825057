import { createStackNavigator } from '@react-navigation/stack'
import { ForgotPasswordScreen } from '../screen/auth/forgot-password-screen'
import { LoginScreen } from '../screen/auth/login-screen'
import { SignUpScreen } from '../screen/auth/sign-up-screen'
import { LandingScreen } from '../screen/landing/landing-screen'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'

const AuthStack = createStackNavigator()
const AuthSignUpStack = createStackNavigator()

const Tab = createBottomTabNavigator()

export function AuthTabNav() {
  return (
    <Tab.Navigator initialRouteName="Login">
      <Tab.Screen
        name="login"
        component={AuthStackNavigator}
        options={{
          headerShown: false,
          tabBarStyle: { display: 'none' },
        }}
      />
      <Tab.Screen
        name="signUp"
        component={AuthSignUpStackNavigator}
        options={{
          headerShown: false,
          tabBarStyle: { display: 'none' },
        }}
      />
    </Tab.Navigator>
  )
}

export const AuthSignUpStackNavigator = () => {
  return (
    <AuthSignUpStack.Navigator>
      <AuthStack.Screen name={'SignUp'} component={SignUpScreen} options={{ headerShown: false }} />
    </AuthSignUpStack.Navigator>
  )
}

export const AuthStackNavigator = () => {
  return (
    <AuthStack.Navigator>
      <AuthStack.Screen
        name={'Landing'}
        component={LandingScreen}
        options={{ headerShown: false, animationTypeForReplace: 'pop' }}
      />
      <AuthStack.Screen name={'Login'} component={LoginScreen} options={{ headerShown: false }} />
      <AuthStack.Screen
        name={'ForgotPassword'}
        component={ForgotPasswordScreen}
        options={{ headerTitle: 'Forgot Password' }}
      />
    </AuthStack.Navigator>
  )
}
