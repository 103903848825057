export const themeFontSizes = {
  xs: 10,
  sm: 12,
  md: 14,
  lg: 16,
  xl: 25,
  xxl: 30,
  xxxl: 50,
  '2xl': 34,
  '3xl': 62,
}
