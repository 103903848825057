import { DocumentData } from 'firebase/firestore'
import { getSoldItems } from './get-total-sales'

export function calculateChartMonthlySales(
  mercariListings: DocumentData[],
  depopListings: DocumentData[],
) {
  const mercari = getSoldItems(mercariListings)
  const depop = getSoldItems(depopListings)

  let totalMonthlySales = Array(12).fill(0)

  ;[...mercari, ...depop].forEach((marketplace) => {
    let updated = new Date(marketplace.updated * 1000)
    if (updated.getFullYear() === new Date().getFullYear()) {
      let month = updated.getMonth()
      totalMonthlySales[month] += parseInt(marketplace.price)
    }
  })

  return totalMonthlySales
}
