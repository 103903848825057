import { DocumentData } from 'firebase/firestore'
import React from 'react'
import { View, Text, ActivityIndicator, StyleSheet } from 'react-native'
import { BaseButton } from '../../../components/base-button'
import { goToPostItem } from '../../../service/navigation-service'
import { themeColors, themeFontSizes } from '../../../themes'
import { themeFontFamily } from '../../../themes/theme-font-family'
import ProductListFlatList from './product-list-flatlist'

type MyStuffDepopListingsProps = {
  isLoadingPlatform: boolean
  loading: boolean
  listings: DocumentData[]
}
export function MyStuffDepopListings(props: MyStuffDepopListingsProps) {
  const { isLoadingPlatform, listings, loading } = props

  if (loading || isLoadingPlatform) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color={themeColors.black} />
      </View>
    )
  }

  if (listings.length === 0) {
    return (
      <View style={{ paddingVertical: 16 }}>
        <View style={{ backgroundColor: themeColors.white, padding: 12, borderRadius: 2 }}>
          <Text style={styles.noItemTitle}>Welcome!</Text>
          <Text style={styles.noItemContent}>
            It looks like you haven’t posted anything from your closet. It’s time to post your first
            item.{' '}
          </Text>
        </View>
        <BaseButton
          onPress={goToPostItem}
          title="Post item"
          backgroundColor={themeColors.black}
          color={themeColors.white}
        />
      </View>
    )
  }

  return (
    <View>
      <ProductListFlatList lists={listings} platform="depop" />
    </View>
  )
}

const styles = StyleSheet.create({
  loadingContainer: {
    height: 210,
    justifyContent: 'center',
    alignItems: 'center',
  },

  noItemTitle: {
    fontSize: themeFontSizes.lg,
    lineHeight: 14,
    color: themeColors.black,
    fontWeight: '400',
    fontFamily: 'chalet',
    opacity: 0.8,
    marginBottom: 8,
  },

  noItemContent: {
    fontFamily: themeFontFamily.itcGaramondStd,
    fontWeight: '300',
    fontSize: themeFontSizes.md,
    lineHeight: 20,
    color: themeColors.black,
    opacity: 0.8,
  },
})
