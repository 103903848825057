import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { Logo } from '../../icons/logo'
import { themeColors, themeFontSizes } from '../../themes'
import { themeFontFamily } from '../../themes/theme-font-family'
import { collection, DocumentData, getDocs, getFirestore } from 'firebase/firestore'
import { AuthContext } from '../../context/auth-context'
import { MyStuffMarketplaces } from './component/my-stuff-marketplaces'
import { useGetProfilePlatforms } from '../account/hooks/use-get-profile-platforms'
import { MyStuffMercariListings } from './component/my-stuff-mercari-listings'
import { MyStuffDepopListings } from './component/my-stuff-depop-listings'

const salesStatus = [
  {
    value: 'sold',
    label: 'my sales',
  },
  {
    value: 'on_sale',
    label: 'my listings',
  },
]

export function MyStuffScreen() {
  const [items, setItems] = useState<DocumentData[]>([])
  const [isLoadingListings, setIsLoadingListings] = useState<boolean>(false)
  const [filteredListings, setFilteredListings] = useState<DocumentData[]>([])
  const [selectedSalesStatus, setSelectedSalesStatus] = useState<string>()
  const db = getFirestore()
  const { authState } = useContext(AuthContext)
  const { profilePlatforms, isLoadingPlatform } = useGetProfilePlatforms()
  const [selectedMarketplace, setSelectedMarketplace] = useState<string>('mercari')

  useEffect(() => {
    setIsLoadingListings(true)
    const activeListings = collection(
      db,
      'profiles',
      authState.userId,
      'platforms',
      selectedMarketplace,
      'activeListings',
    )
    getDocs(activeListings).then((querySnapshot) => {
      const items = querySnapshot.docs.map((doc) => doc.data())
      setItems(items)
      setFilteredListings(items)
      setIsLoadingListings(false)
    })
  }, [selectedMarketplace])

  const handleSelectedSalesStatus = (item: DocumentData) => {
    setSelectedSalesStatus(item.value)
    const filteredData = items.filter((val) => val.status === item.value)
    setFilteredListings(filteredData)
  }

  const handleCancleFilter = useCallback(() => {
    const activeListings = collection(
      db,
      'profiles',
      authState.userId,
      'platforms',
      selectedMarketplace,
      'activeListings',
    )
    getDocs(activeListings).then((querySnapshot) => {
      const items = querySnapshot.docs.map((doc) => doc.data())
      setFilteredListings(items)
      setIsLoadingListings(false)
    })
    setSelectedSalesStatus('')
  }, [selectedMarketplace])

  return (
    <View style={styles.container}>
      <View style={[styles.headerWrapper]}>
        <View>
          <View>
            <Logo />
          </View>

          <MyStuffMarketplaces
            profilePlatforms={profilePlatforms}
            isLoadingPlatform={isLoadingPlatform}
            setSelectedMarketplace={setSelectedMarketplace}
            selectedMarketplace={selectedMarketplace}
          />
          <View style={{ paddingVertical: 16 }}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Text style={styles.filterTitle}>Filter</Text>
              <Text
                style={{
                  fontSize: themeFontSizes.lg,
                  fontFamily: 'chalet',
                  fontWeight: '400',
                  lineHeight: 22,
                }}
              >
                by
              </Text>
            </View>

            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {salesStatus.map((item, index) => {
                const isActive = item.value === selectedSalesStatus
                return (
                  <TouchableOpacity
                    style={{
                      borderWidth: 1,
                      paddingHorizontal: 10,
                      paddingVertical: 4,
                      marginRight: 10,
                      width: '30%',
                      borderRadius: 15,
                      marginTop: 10,
                      backgroundColor: isActive ? themeColors.secondary : themeColors.white,
                    }}
                    key={index}
                    onPress={() => handleSelectedSalesStatus(item)}
                  >
                    <Text
                      style={{
                        fontSize: themeFontSizes.lg,
                        fontFamily: 'chalet',
                        fontWeight: '400',
                        lineHeight: 22,
                        textTransform: 'capitalize',
                        textAlign: 'center',
                        color: isActive ? themeColors.white : themeColors.black,
                      }}
                    >
                      {item.label}
                    </Text>
                  </TouchableOpacity>
                )
              })}
              <TouchableOpacity onPress={handleCancleFilter}>
                <Text
                  style={{
                    fontSize: themeFontSizes.lg,
                    fontFamily: 'chalet',
                    fontWeight: '400',
                    lineHeight: 22,
                    textTransform: 'capitalize',
                    textAlign: 'center',
                    textDecorationLine: 'underline',
                  }}
                >
                  Clear filter
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Text style={styles.filterTitle}>My</Text>
        <Text
          style={{
            fontSize: themeFontSizes.lg,
            fontFamily: 'chalet',
            fontWeight: '400',
            lineHeight: 22,
          }}
        >
          Closet
        </Text>
      </View>

      <ScrollView showsVerticalScrollIndicator={false}>
        {selectedMarketplace === 'mercari' ? (
          <MyStuffMercariListings
            selectedSalesStatus={selectedSalesStatus}
            loading={isLoadingListings}
            listings={filteredListings}
            isLoadingPlatform={isLoadingPlatform}
          />
        ) : (
          <MyStuffDepopListings
            isLoadingPlatform={isLoadingPlatform}
            loading={isLoadingListings}
            listings={filteredListings}
          />
        )}
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: themeColors.appBackground,
    paddingHorizontal: 16,
    flex: 1,
  },

  headerWrapper: {
    paddingTop: 16,
  },

  filterTitle: {
    fontSize: themeFontSizes.lg,
    fontFamily: themeFontFamily.itcGaramondStd,
    fontWeight: '300',
    lineHeight: 20,
    opacity: 0.8,
    marginRight: 8,
  },
})
