import { CommonActions } from '@react-navigation/native'
import { DocumentData } from 'firebase/firestore'
import { Keyboard } from 'react-native'

type NavigateToPropType = {
  routeName?: string
  params?: Record<string, any>
}

let navigationRef: any
export function setNavigationRef(ref: any) {
  navigationRef = ref
}

export function navigateTo(opt: NavigateToPropType = {}) {
  Keyboard.dismiss()
  const { routeName, params } = opt
  navigationRef && navigationRef.navigate(routeName, params)
}

export const goToLandingScreen = () => {
  navigateTo({ routeName: 'Landing' })
}

export const goToLoginScreen = () => {
  navigateTo({ routeName: 'Login' })
}

export const goToSignUpScreen = () => {
  navigateTo({ routeName: 'signUp' })
}

export const gotoForgotPasswordScreen = () => {
  navigateTo({ routeName: 'ForgotPassword' })
}

export const goHome = () => {
  navigateTo({ routeName: 'home' })
}

export const goToPostItem = () => {
  navigateTo({ routeName: 'Post Item' })
}

export const goProfile = () => {
  navigateTo({ routeName: 'My Profile' })
}

export const goProductDetail = ({
  productId,
  product,
  platform,
}: {
  productId: string
  product: DocumentData
  platform: string
}) => {
  navigateTo({ routeName: 'productDetail', params: { productId, product, platform } })
}

export const goToMyCloset = () => {
  navigateTo({ routeName: 'My Closet' })
}

export const goBack = () => {
  navigationRef.dispatch(CommonActions.goBack())
}
