// Import the functions you need from the SDKs you need
import Constants from 'expo-constants'
import { getApp, getApps, initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: Constants.manifest?.extra?.firebaseApiKey,
  authDomain: Constants.manifest?.extra?.firebaseAuthDomain,
  databaseURL: 'https://sellstuff-cfb37-default-rtdb.firebaseio.com',
  projectId: Constants.manifest?.extra?.firebaseProjectId,
  storageBucket: Constants.manifest?.extra?.firebaseStorageBucket,
  messagingSenderId: Constants.manifest?.extra?.firebaseMessagingSenderId,
  appId: Constants.manifest?.extra?.firebaseAppId,
  measurementId: Constants.manifest?.extra?.firebaseMeasurementId,
}

// Initialize Firebase
if (getApps().length === 0) initializeApp(firebaseConfig)
const firebaseApp = getApp()
const auth = getAuth()
const db = getDatabase()
const firestore = getFirestore()
const functions = getFunctions()
if (Constants.manifest?.extra?.FIREBASE_EMULATOR_AUTH_HOST) {
  connectAuthEmulator(auth, Constants.manifest?.extra?.FIREBASE_EMULATOR_AUTH_HOST);
}
if (Constants.manifest?.extra?.FIREBASE_EMULATOR_DATABASE_HOST) {
  connectDatabaseEmulator(db, Constants.manifest?.extra?.FIREBASE_EMULATOR_DATABASE_HOST, Constants.manifest?.extra?.FIREBASE_EMULATOR_DATABASE_PORT);
}
if (Constants.manifest?.extra?.FIREBASE_EMULATOR_FIRESTORE_HOST) {
  connectFirestoreEmulator(firestore, Constants.manifest?.extra?.FIREBASE_EMULATOR_FIRESTORE_HOST, Constants.manifest?.extra?.FIREBASE_EMULATOR_FIRESTORE_PORT);
}
if (Constants.manifest?.extra?.FIREBASE_EMULATOR_FUNCTIONS_HOST) {
  connectFunctionsEmulator(functions, Constants.manifest?.extra?.FIREBASE_EMULATOR_FUNCTIONS_HOST, Constants.manifest?.extra?.FIREBASE_EMULATOR_FUNCTIONS_PORT);
}

export { firebaseApp, auth, db, firestore, functions, firebaseConfig }
