import { DocumentData } from 'firebase/firestore'
import { supportedMarketplacesType } from './types'

export const mergeArrays = (
  profilePlatforms: supportedMarketplacesType[] = [],
  platformStatus: DocumentData | undefined = [],
) => {
  let res = []
  return profilePlatforms?.length > 0
    ? profilePlatforms?.map((profilePlatform: DocumentData) => {
        const index = platformStatus?.findIndex(
          (platform: DocumentData) => platform['id'] == profilePlatform['id'],
        )
        const data = index !== -1 ? platformStatus[index] : {}
        return {
          ...profilePlatform,
          ...data,
        }
      })
    : []
}
