import { onAuthStateChanged, User } from 'firebase/auth'
import { useContext, useEffect, useState } from 'react'
import { configValue } from '../config/config-values'
import { auth } from '../config/firebase'
import { AuthContext } from '../context/auth-context'

export function useAuthentication() {
  const [user, setUser] = useState<User>()
  const [token, setToken] = useState<string>()
  const { setAuthState, authState } = useContext(AuthContext)
  useEffect(() => {
    const unsubscribeFromAuthStatusChanged = onAuthStateChanged(auth, (updatedUser) => {
      if (updatedUser) {
        updatedUser.getIdToken().then((token) => {
          setUser(updatedUser)
          setToken(token)
          fetch(`${configValue.apiHost}/validateToken`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `${token}`,
            },
          }).then((res) => {
            if (res.ok) {
              res.json().then((data) => {
                setAuthState({
                  token: token,
                  email: updatedUser?.email as string,
                  isLoggedIn: true,
                  userId: updatedUser?.uid as string,
                  name: updatedUser.displayName as string,
                  profileImage: { uri: updatedUser.photoURL },
                })
              })
            }
          })
        })
      } else {
        console.info('setAuthState2', user, updatedUser)
        setAuthState({ token: null, email: '', isLoggedIn: false, userId: '' })
        setUser(undefined)
      }
    })
    return unsubscribeFromAuthStatusChanged
  }, [authState?.isLoggedIn])

  return {
    user,
    authState,
    token,
  }
}
