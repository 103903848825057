import { View, Text, TouchableOpacity, GestureResponderEvent } from "react-native";
import { DepopCheckboxType, DepopDataType, DepopInputType, DepopInputTypeEnum, DepopOptionType } from "./datatype";
import Checkbox from 'expo-checkbox'
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { DepopInput } from "./depop-input";

type IDepopOption = DepopInputType & {
  option: DepopCheckboxType;
};

export const DepopCheckbox = ({id, option, onChange}: IDepopOption) => {
  const [selected, setSelected] = useState<boolean>(option.default);
  const value = useRef<DepopDataType>();
  const _onChangeChild = useCallback((_value: DepopDataType) => {
    const newValue = {
      id: id,
      value: selected,
      type: DepopInputTypeEnum.checkbox,
      selectedValues: (value?.current?.selectedValues || []).filter((v) => v.id !== _value.id).concat(_value),
    };
    value.current = newValue;
    onChange(newValue);
  }, [value]);
  // if option.default is true then set selected to true
  // if option.default is false then set selected to false
  useEffect(() => {
    // if option.default is true and selected is true, onPress(0)
    // if option.default is false and selected is false, onPress(0)
    // if option.default is false and selected is true, onPress(1)
    // if option.default is true and selected is false, onPress(1)
    const selectedOption = option.options[+((option.default && selected) || (!option.default && !selected) ? 0 : 1)];
    onChange({
      id: id,
      type: DepopInputTypeEnum.checkbox,
      value: selected ?? option.default,
      selectedValues: Object.values(selectedOption.inputs).map((input) => {
        return {
          id: input.id,
          type: input.type,
          value: input.default,
        };
      }),
    })
  }, [selected]);
  return (
    <Fragment>
      <View>
        <TouchableOpacity
          onPress={() => setSelected(!selected)}
          key={option.id}
          >
          <Checkbox
            value={selected}
            color={selected ? "#4630EB" : undefined}
          />
        </TouchableOpacity>
        <Text>{option.label}</Text>
      </View>
      {
        Object.values(option.options[+selected].inputs).map((input) => {
          return (<DepopInput onChange={_onChangeChild} input={input} key={input.id}></DepopInput>);
        })
      }
    </Fragment>
  );
};