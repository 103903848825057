import { FlatList, StyleSheet, View } from 'react-native'
import React from 'react'
import { ProductCard } from './product-card'

type productFlatListType = {
  productLists: any[]
  platform: string
}
export default function ProductsFlatList(props: productFlatListType) {
  const { productLists, platform } = props
  return (
    <View style={styles.container}>
      <FlatList
        data={productLists}
        renderItem={({ item }) => <ProductCard data={item} platform={platform} />}
        horizontal
        showsHorizontalScrollIndicator={false}
        keyExtractor={(item, index) => String(index)}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingStart: 16 }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {},
})
