import { useCallback, useRef, useState } from 'react';
import { View, Text } from 'react-native'
import { DepopContainerType, DepopDataType } from './datatype';
import { DepopInput } from './depop-input';

type IDepopSection = {
  data: DepopContainerType;
  onChange: (value: DepopDataType[]) => void;
};

export const DepopSection = ({data, onChange}: IDepopSection) => {
  const { inputs, section } = data;
  const value = useRef<DepopDataType[]>(new Array(Object.keys(inputs).length).fill({} as DepopDataType));
  const _onChange = useCallback((_value: DepopDataType, index: number) => {
    value.current[index] = _value;
    onChange(value.current);
  }, [value]);
  return (
    <View>
      <Text>{section}</Text>
      <View>
        {Object.values(inputs).map((input, index) => {
          return (<DepopInput onChange={(value) => _onChange(value, index)} key={input.id} input={input}></DepopInput>);
        })}
      </View>
    </View>
  );
};