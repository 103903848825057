import {
  BottomSheetBackdrop,
  BottomSheetBackdropProps,
  BottomSheetModal,
} from '@gorhom/bottom-sheet'
import { ReactNode, RefObject, useCallback, useMemo } from 'react'
import { StyleSheet, View } from 'react-native'

type BaseBottomSheetModalProps = {
  snapPoints?: string[]
  onDismiss: () => void
  children: ReactNode
  bottomSheetModalRef: RefObject<BottomSheetModal>
}

export function BaseBottomSheetModal(props: BaseBottomSheetModalProps) {
  const { bottomSheetModalRef, children } = props

  const snapPoints = useMemo<string[]>(() => {
    return props?.snapPoints ? props.snapPoints : ['40%']
  }, [props?.snapPoints])

  const onDismiss = useCallback(() => {
    props?.onDismiss && props.onDismiss()
  }, [props?.onDismiss])

  return (
    <BottomSheetModal
      index={0}
      onDismiss={onDismiss}
      snapPoints={snapPoints}
      ref={bottomSheetModalRef}
      backdropComponent={BackDropComponent}
    >
      <View style={styles.container}>{children}</View>
    </BottomSheetModal>
  )
}

function BackDropComponent(props: BottomSheetBackdropProps) {
  return <BottomSheetBackdrop {...props} appearsOnIndex={0} disappearsOnIndex={-1} />
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})
