import AsyncStorage from '@react-native-async-storage/async-storage'
import { createContext, useEffect, useState } from 'react'

export interface ftueType {
  isFTUE: boolean //(First time user experience)
}

type contextType = {
  ftueState: ftueType
  setFtueState: (data: ftueType) => void
}

const initialState = {
  isFTUE: false,
}

export const FtueContext = createContext<contextType>(initialState as any)
export function FTUEProvider({ children }: { children: React.ReactNode }) {
  const [ftueState, setFtueState] = useState(initialState)
  useEffect(() => {
    AsyncStorage.getItem('ftue')
      .then((value: any) => {
        setFtueState(JSON.parse(value))
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    AsyncStorage.setItem('ftue', JSON.stringify(ftueState))
  }, [ftueState])

  return <FtueContext.Provider value={{ ftueState, setFtueState }}>{children}</FtueContext.Provider>
}
