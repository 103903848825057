import React from 'react'
import Svg, { Path } from 'react-native-svg'

export function MyClosetIcon({ color }: { color: string }) {
  return (
    <Svg width="24" height="24" fill="none" viewBox="0 0 24 24">
      <Path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.75"
        d="M21 8v13H3V8M23 3H1v5h22V3zM10 12h4"
      ></Path>
    </Svg>
  )
}
