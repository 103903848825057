import { DocumentData } from 'firebase/firestore'
import { getSoldItems } from './get-total-sales'

export function getTotalMonthlySales(
  mercariListings: DocumentData[],
  depopListings: DocumentData[],
) {
  // get sold items
  const mercari = getSoldItems(mercariListings)
  const depop = getSoldItems(depopListings)

  // calculate total monthly sales
  let totalMonthlySales = [...mercari, ...depop].reduce((acc, marketplace) => {
    let updated = new Date(marketplace.updated * 1000)
    if (updated.getMonth() === new Date().getMonth()) {
      return acc + parseInt(marketplace.price)
    }
    return acc
  }, 0)
  return totalMonthlySales
}
