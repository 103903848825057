import { View, Text, StyleSheet, ScrollView } from 'react-native'
import React from 'react'
import Modal from 'react-native-modal'

import { BaseButton } from '../../../components/base-button'
import { themeColors, themeFontSizes } from '../../../themes'
import { themeFontFamily } from '../../../themes/theme-font-family'

type ProcessingModalComponentProps = {
  visible: boolean
  setProcessingModal: (e: boolean) => void
  message: string
  title: string
  isProcessing: boolean
  onRoute?: () => void
}
export const ProcessingModalComponent = (props: ProcessingModalComponentProps) => {
  const { visible, setProcessingModal, message, title, isProcessing, onRoute } = props
  return (
    <View>
      <Modal isVisible={visible}>
        <ScrollView style={styles.modalContainer}>
          <View>
            <Text style={styles.title}>{title}</Text>
          </View>
          <View style={styles.messageWrapper}>
            <Text>{message}</Text>
            {isProcessing && <Text>Processing...</Text>}
          </View>
          <View
            style={{
              flex: 1,
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <BaseButton
              onPress={() => {
                setProcessingModal(false)
                onRoute && onRoute()
              }}
              marginTop={16}
              marginBottom={0}
              title="Continue"
              color={themeColors.black}
              backgroundColor={themeColors.white}
            />
          </View>
        </ScrollView>
      </Modal>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  modalContainer: {
    // flex: 1,
    backgroundColor: themeColors.white,
    marginVertical: 100,
    paddingHorizontal: 20,
    paddingVertical: 24,
    borderRadius: 5,
    borderWidth: 1,
  },

  title: {
    // textAlign: 'center',
    fontSize: themeFontSizes.xl,
    lineHeight: 22,
    fontWeight: '400',
    fontFamily: 'chalet',
    letterSpacing: 0.05,
  },

  subTitle: {
    fontSize: themeFontSizes.lg,
    color: themeColors.black,
    marginTop: 4,
    fontFamily: themeFontFamily.itcGaramondStd,
    fontWeight: '300',
    lineHeight: 20,
    opacity: 0.8,
  },

  textInputStyle: {
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: themeColors.gray100,
    paddingHorizontal: 16,
    borderRadius: 25,
    borderColor: themeColors.gray100,
  },

  input: {
    flex: 1,
    backgroundColor: 'transparent',
    color: '#424242',
    paddingVertical: 16,
  },

  shippingContainer: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: themeColors.gray200,
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    paddingVertical: 16,
    outlineColor: 'transparent',
  },

  messageWrapper: {
    paddingVertical: 16,
  },
})
