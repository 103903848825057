import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import React, { useState } from 'react'
import { themeFontSizes } from '../../../themes'
import { shippingOptions, ShippingPayerOptions } from '../../../utils/static-data'
import { shippingOptionType, ShippingPayerType } from '../../../utils/types'
import Checkbox from 'expo-checkbox'
import { themeFontFamily } from '../../../themes/theme-font-family'
import { BaseDropDownInput } from '../../../components/base-dropdown-input'

type ShippingOptionProps = {
  selectedShippingOptionId: string
  selectedShippingPayer?: ShippingPayerType
  handleShippingMethod: (option: shippingOptionType) => void
  handleShippingPayerChange: (option: ShippingPayerType) => void
}

export const ShippingOptions = (props: ShippingOptionProps) => {
  const {
    selectedShippingOptionId,
    selectedShippingPayer,
    handleShippingMethod,
    handleShippingPayerChange,
  } = props
  const selectedPayer = selectedShippingPayer ?? ShippingPayerOptions[0]

  return (
    <View style={styles.deliveryContainer}>
      <Text style={styles.crossPostText}>Delivery</Text>
      {shippingOptions?.map((option, index) => {
        const isChecked = selectedShippingOptionId === option.id
        return (
          <TouchableOpacity
            onPress={() => handleShippingMethod(option)}
            style={[styles.shippingContainer]}
            key={index}
          >
            <Checkbox value={isChecked} color={isChecked ? '#4630EB' : undefined} />
            <View style={{ marginLeft: 16, width: '100%' }}>
              <Text style={styles.marketPlaceText}>{option.title}</Text>
              <Text style={styles.subTitle}>{option.description}</Text>
            </View>
          </TouchableOpacity>
        )
      })}
      <Text style={styles.payerText}>Who will pay for delivery?</Text>
      {/* Product category */}
      <BaseDropDownInput
        title="Delivery payer"
        value={selectedPayer.description}
        snapPointsValue={['80%']}
        lists={ShippingPayerOptions}
        handleSelect={handleShippingPayerChange}
        placeholder={'Select payer'}
        textInputStyle={{ marginTop: 10 }}
        setErrorState={() => {}}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  deliveryContainer: {
    maxWidth: 280,
    marginTop: 10,
  },

  crossPostText: {
    fontFamily: 'chalet',
    fontSize: themeFontSizes.lg,
    fontWeight: '400',
    lineHeight: 22,
    letterSpacing: 0.05,
  },

  shippingContainer: {
    flexDirection: 'row',
    marginTop: 16,
  },

  marketPlaceText: {
    fontFamily: 'chalet',
    fontSize: themeFontSizes.md,
    fontWeight: '400',
    lineHeight: 20,
    letterSpacing: 0.1,
    textTransform: 'capitalize',
  },

  subTitle: {
    fontFamily: themeFontFamily.itcGaramondStd,
    fontSize: themeFontSizes.md,
    fontWeight: '300',
    lineHeight: 20,
    opacity: 0.8,
    marginTop: 4,
  },

  payerText: {
    fontSize: themeFontSizes.lg,
    lineHeight: 22,
    fontFamily: 'chalet',
    fontWeight: '400',
    marginTop: 10,
  },
})
