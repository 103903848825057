import { StyleSheet, ScrollView } from 'react-native'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { BaseBottomSheetModal } from '../../../components/base-bottom-sheet'
import { BottomSheetModal } from '@gorhom/bottom-sheet'
import { doc, DocumentData, getFirestore, onSnapshot } from 'firebase/firestore'
import { ProductImageUpload } from '../../create-stuff/component/product-image-upload'
import * as ImagePickers from 'expo-image-picker'
import { themeColors } from '../../../themes'
import { ControlledInput } from '../../../components/controlled-input'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { BaseDropDownInput } from '../../../components/base-dropdown-input'
import {
  categoriesType,
  shippingOptionType,
  ShippingPayerType,
  sizeType,
  subCategoriesType,
  supportedMarketplacesType,
} from '../../../utils/types'
import { itemConditons, ShippingPayerOptions } from '../../../utils/static-data'
import { ShippingOptions } from '../../create-stuff/component/shipping-options'
import { PrepaidShippingOption } from '../../create-stuff/component/prepaid-shipping-options'
import { ShippingChoicesModal } from '../../create-stuff/component/shipping-choices-modal'
import { ProcessingModal } from '../../create-stuff/component/processing-modal'
import useTransaction from '../../../hooks/useTransaction'
import { BaseButton } from '../../../components/base-button'
import { useGetProfilePlatforms } from '../../account/hooks/use-get-profile-platforms'
import useJob from '../../../hooks/useJob'
import { track } from '../../../utils/mix-panel-utils'
import { axiosPost } from '../../../utils/https-utils'
import { CrossPostMarketplaces } from '../../create-stuff/component/cross-post-marketplaces'

type ProductUpdateProps = {
  onConfirmPress: () => void
  bottomSheetModalRef: React.RefObject<BottomSheetModal>
  item: DocumentData
  platform: string
}

const productUpdateScheme = yup.object().shape({
  title: yup.string(),
  description: yup.string(),
  price: yup.string(),
  weightInPounds: yup.string(),
  weightInOz: yup.string(),
})

export const ProductUpdate = (props: ProductUpdateProps) => {
  const { onConfirmPress, bottomSheetModalRef, item, platform } = props
  const likeNewCondition =
    itemConditons.find(
      (condition) => condition.description === item?.itemCondition?.conditionName,
    ) || {}

  const snapPoints = useMemo(() => ['92%'], [])
  const [isLoading, setIsLoading] = useState(false)
  const [productImages, setProductImages] = useState<ImagePickers.ImageInfo[]>(item?.photos)
  const [imageErrorMsg, setImageErrorMsg] = useState<string>('')
  const [descriptionErrorMsg, setDescriptionErrorMsg] = useState<string>('')
  const [selectedCategory, setSelectedCategory] = useState<categoriesType>({
    ...item?.itemCategoryHierarchy?.[0],
    id: `categoryId-${item?.itemCategoryHierarchy?.[0]?.itemCategoryHierarchyId}`,
  })
  const [selectedSubCategory, setSelectedSubCategory] = useState<subCategoriesType>({
    ...item?.itemCategoryHierarchy?.[1],
    id: `subCategoryId-${item?.itemCategoryHierarchy?.[1]?.itemCategoryHierarchyId}`,
  })
  const [categoryList, setCatetoryList] = useState<any[]>([])
  const [itemCondition, setItemCondition] = useState<any>(likeNewCondition)
  const [selectedSubSubCatetories, setSelectedSubSubCatetories] = useState<any>({
    ...item?.itemCategoryHierarchy?.[2],
    id: `subSubCategoryId-${item?.itemCategoryHierarchy?.[2]?.itemCategoryHierarchyId}`,
  }) // type this
  const [selectedSize, setSelectedSize] = useState<sizeType>({
    ...item?.itemSize,
    id: `itemSizeId-${item?.itemSize?.id}`,
  })
  const [selectedShippingOptionId, setSelectedShippingOptionId] =
    useState<string>('prepaid-shipping-1')
  const [shippingChoicesModalVisible, setShippingChoicesModalVisible] = useState<boolean>(false)
  const [processingModalVisible, setProcessingModalVisible] = useState<boolean>(false)
  const [hasImageChanged, setHasImageChanged] = useState<boolean>(false)
  const [selectedMarketplacesId, setSelectedMarketplacesId] = useState<string[]>(['mercari'])
  const transactions = useTransaction()
  const [jobId, setJobId] = useState<string>('')
  const [catchError, setCatchError] = useState<any>()
  const [selectedShippingPayer, setSelectedShippingPayer] = useState<ShippingPayerType | undefined>(
    ShippingPayerOptions.find((option) => option.id === item?.shippingPayer?.id),
  )

  const { profilePlatforms, isLoadingPlatform } = useGetProfilePlatforms()
  const job = useJob(jobId)

  const db = getFirestore()

  const formatedPrice = (Number(item?.price) / 100).toFixed(2)

  useEffect(() => {
    const docPlatform = doc(db, 'platforms', 'mercari')
    const unsub = onSnapshot(docPlatform, (doc) => {
      let categories = doc.data()?.categories
      let categoriesJson = JSON.parse(categories)
      setCatetoryList(categoriesJson)
    })

    const depopPlatform = doc(db, 'platforms', 'depop')
    onSnapshot(depopPlatform, (doc) => {
      let categories = doc.data()?.categories
      let categoriesJson = JSON.parse(categories)
      // setDepopCategoryList(categoriesJson);
    })

    return unsub
  }, [])

  useEffect(() => {
    if (job?.ready) {
      track('post_item_success')
    }
    if (job.ready || !!job.error) {
      setProcessingModalVisible(true)
      setIsLoading(false)
    }
  }, [job])

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(productUpdateScheme),
  })

  const handleSelectCategory = (categoryItem: categoriesType) => {
    setSelectedCategory(categoryItem)
    setSelectedSubCategory({} as subCategoriesType)
    setSelectedSubSubCatetories({} as subCategoriesType)
    setSelectedSize({} as sizeType)
  }

  const handleShippingMethod = useCallback(
    (item: shippingOptionType) => {
      setSelectedShippingOptionId(item.id)
    },
    [selectedShippingOptionId],
  )

  // handle update product
  const formdata = new FormData()
  const onSubmit = async (data: any) => {
    // todo api not ready
    let wordCount = data.description.split(' ').length
    if (wordCount < 5) {
      setDescriptionErrorMsg('Description must be at least 5 words')
      return
    }
    let postItemData
    try {
      // clean data and return only array of imageUrls
      if (hasImageChanged) {
        let images = productImages.filter((image) => {
          if (Object.keys(image).length !== 0) {
            return true
          }
          return false
        })

        for (const image of images) {
          // const byteString = Buffer.from(image.uri, 'base64');
          const blob = await fetch(image.uri).then((res) => res.blob())
          formdata.append('file', blob, image.fileName ?? 'filename.png')
        }
      }
      // todo api post listing
      const postItemData = selectedMarketplacesId.map((platform) => {
        if (platform === 'mercari') {
          return {
            images: hasImageChanged,
            itemId: item?.itemId,
            platform,
            ...data,
            category: selectedCategory?.id,
            subCategory: selectedSubCategory?.id,
            subCategorySize: selectedSize?.id,
            subSubCategory: selectedSubSubCatetories?.id,
            condition: itemCondition?.value,
            shipping: selectedShippingOptionId,
            smartPrice: false,
            minSmartPrice: '',
            smartOffer: false,
            smartOfferPrice: '',
            shippingPayer: selectedShippingPayer?.name,
          }
        } else if (platform === 'depop') {
          return {
            platform,
            ...data,
            // depop data
          }
        }
      })

      formdata.append('data', JSON.stringify(postItemData))

      console.log('sending data:::', postItemData)

      setIsLoading(true)

      const res = await axiosPost({
        url: 'postItem',
        data: formdata,
      })

      if (res) {
        setJobId(res?.jobId[0])
        track('post_item_data', postItemData)
        return
      }
    } catch (error) {
      setIsLoading(false)
      setCatchError(error)
      track('post_item_error', error)
      console.log('--error--', error)
    }
  }

  const handleMarketplaceSelect = useCallback(
    (item: supportedMarketplacesType) => {
      const isActive = selectedMarketplacesId.includes(item.id)
      const newActive = isActive
        ? selectedMarketplacesId.filter((x) => x !== item.id)
        : selectedMarketplacesId.concat(item.id)
      setSelectedMarketplacesId(newActive)
    },
    [selectedMarketplacesId],
  )

  console.log('--job--', job)

  return (
    <BaseBottomSheetModal
      snapPoints={snapPoints}
      onDismiss={onConfirmPress}
      bottomSheetModalRef={bottomSheetModalRef}
    >
      <ScrollView style={styles.container}>
        <CrossPostMarketplaces
          profilePlatforms={profilePlatforms}
          selectedMarketplacesId={selectedMarketplacesId}
          handleMarketplaceSelect={handleMarketplaceSelect}
          isLoadingPlatform={isLoadingPlatform}
        />

        {/* Product image */}
        <ProductImageUpload
          setProductImages={setProductImages}
          productImages={productImages}
          imageErrorMsg={imageErrorMsg}
          setErrorState={setImageErrorMsg}
          setHasImageChanged={setHasImageChanged}
        />

        {/* product name */}
        <ControlledInput
          control={control}
          name="title"
          placeholder="Item Name*"
          error={!!errors.title}
          required={true}
          defaultValue={item?.name}
        />

        {/* Product description */}
        <ControlledInput
          control={control}
          name="description"
          placeholder="Item Descriptions*"
          error={!!errors.description}
          hasWordCountError={!!descriptionErrorMsg}
          descriptionErrorMsg={descriptionErrorMsg}
          required={false}
          defaultValue={item?.description}
          inputContainer={{ marginTop: 10 }}
          inputStyle={{ height: 100, justifyContent: 'flex-start' }}
          multiline={true}
          onFocus={() => setDescriptionErrorMsg('')}
        />

        {/* Product price */}
        <ControlledInput
          control={control}
          name="price"
          placeholder="Item Price ($)*"
          error={!!errors.price}
          defaultValue={formatedPrice}
          keyboardType="number-pad"
          required={true}
          inputContainer={{ marginTop: 10 }}
          hasCurrencySymbol={true}
        />

        {/* Product category */}
        <BaseDropDownInput
          title="Category"
          value={selectedCategory?.description ?? selectedCategory?.name}
          snapPointsValue={['80%']}
          lists={categoryList}
          handleSelect={handleSelectCategory}
          placeholder={'Select category*'}
          textInputStyle={{ marginTop: 10 }}
          // errorMsg={categoryErrorMsg}
          setErrorState={() => {}}
        />

        {/* product Sub Category */}
        <>
          {!!selectedCategory && selectedCategory?.subcategories?.length > 0 ? (
            <BaseDropDownInput
              key={selectedCategory?.id}
              title="Subcategory"
              value={selectedSubCategory?.description}
              snapPointsValue={['80%']}
              lists={selectedCategory?.subcategories}
              handleSelect={setSelectedSubCategory}
              placeholder={'Select subcategory*'}
              textInputStyle={{ marginTop: 10 }}
              errorMsg={''}
              setErrorState={() => {}}
            />
          ) : (
            <BaseDropDownInput
              key={selectedCategory?.id}
              title="Subcategory"
              value={selectedSubCategory?.name}
              snapPointsValue={['80%']}
              lists={selectedCategory?.subcategories}
              handleSelect={() => {}}
              isDisabled={true}
              placeholder={'Select subcategory*'}
              textInputStyle={{ marginTop: 10 }}
              errorMsg={''}
              setErrorState={() => {}}
            />
          )}
        </>

        {/* product Sub sub Category */}
        <>
          {!!selectedSubCategory && selectedSubCategory?.subsubcategories?.length > 0 && (
            <BaseDropDownInput
              title="Sub Subcategory"
              value={selectedSubSubCatetories?.description}
              snapPointsValue={['80%']}
              lists={selectedSubCategory?.subsubcategories}
              handleSelect={setSelectedSubSubCatetories}
              placeholder={'Select sub subcategory*'}
              textInputStyle={{ marginTop: 10 }}
              errorMsg={''}
              setErrorState={() => {}}
            />
          )}

          {!!selectedSubSubCatetories?.name && (
            <BaseDropDownInput
              title="Sub Subcategory"
              value={selectedSubSubCatetories?.name}
              snapPointsValue={['80%']}
              lists={selectedSubCategory?.subsubcategories}
              handleSelect={() => {}}
              isDisabled={true}
              placeholder={'Select sub subcategory*'}
              textInputStyle={{ marginTop: 10 }}
              errorMsg={''}
              setErrorState={() => {}}
            />
          )}
        </>

        {/* Product size */}
        <>
          {!!selectedSubCategory && selectedSubCategory?.sizes?.length > 0 && (
            <BaseDropDownInput
              title="Item Sizes"
              value={selectedSize?.description}
              snapPointsValue={['80%']}
              lists={selectedSubCategory?.sizes}
              handleSelect={setSelectedSize}
              placeholder={'Select size*'}
              textInputStyle={{ marginTop: 10 }}
              errorMsg={''}
              setErrorState={() => {}}
            />
          )}
          {!!selectedSize?.sizeName && (
            <BaseDropDownInput
              title="Item Sizes"
              value={selectedSize?.sizeName}
              snapPointsValue={['80%']}
              lists={selectedSubCategory?.sizes}
              handleSelect={() => {}}
              isDisabled={true}
              placeholder={'Select size*'}
              textInputStyle={{ marginTop: 10 }}
              errorMsg={''}
              setErrorState={() => {}}
            />
          )}
        </>

        {/* Product Condition */}
        <BaseDropDownInput
          title="Item Condition"
          value={itemCondition.description}
          snapPointsValue={['40%']}
          lists={itemConditons}
          handleSelect={setItemCondition}
          placeholder={'Select item condition*'}
          textInputStyle={{ marginTop: 10 }}
          errorMsg={''}
          setErrorState={() => {}}
        />

        {/* Product Delivery Method */}
        <ShippingOptions
          selectedShippingOptionId={selectedShippingOptionId}
          selectedShippingPayer={selectedShippingPayer}
          handleShippingMethod={handleShippingMethod}
          handleShippingPayerChange={setSelectedShippingPayer}
        />

        {/* prepaid shipping option selected  */}
        {selectedShippingOptionId?.includes('prepaid-shipping-1') && (
          <PrepaidShippingOption
            control={control}
            errors={errors}
            defaultWeightValue={item?.weightlb || item?.weightoz}
            weightInPounds={!!item?.weightoz}
          />
        )}

        <ShippingChoicesModal
          visible={shippingChoicesModalVisible}
          setShippingChoicesModalVisible={setShippingChoicesModalVisible}
          transactions={transactions}
        />

        <ProcessingModal
          visible={processingModalVisible}
          setProcessingModal={setProcessingModalVisible}
          syncing={job?.syncing}
          error={job?.error}
          ready={job?.ready}
          jobId={jobId}
        />

        <BaseButton
          onPress={handleSubmit(onSubmit)}
          isLoading={isLoading || job?.syncing}
          title="Update listing"
          backgroundColor={themeColors.black}
          color={themeColors.white}
        />
      </ScrollView>
    </BaseBottomSheetModal>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: themeColors.white,
    flex: 1,
    paddingHorizontal: 16,
  },
})
