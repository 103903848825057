import { collection, getFirestore, query } from '@firebase/firestore'
import { where, onSnapshot, QuerySnapshot, DocumentData, updateDoc, doc } from 'firebase/firestore'
import { useCallback, useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/auth-context'

const db = getFirestore()

export type shippingChoicesType = {
  index: number
  logo: string
  price: string
  title: string
}

type detailsType = {
  shippingChoices: shippingChoicesType[]
}

export type Transaction = {
  id: string
  RequestType: string
  Platform: string
  sendResponse: (response: string) => void
  details: detailsType
}

const useTransaction = () => {
  // grab transactions from the transaction collection on firestore with your userId
  const [transactionList, setTransactionList] = useState<Transaction[]>([])
  const { authState } = useContext(AuthContext)

  const sendResponse = useCallback((transactionId: string, response: string) => {
    // send response to firebase transaction collection
    updateDoc(doc(db, 'transactions', transactionId), {
      Response: response,
    })
  }, [])

  const transactionListCallback = useCallback(
    (querySnapshot: QuerySnapshot<DocumentData>) => {
      const transactions: Transaction[] = []
      querySnapshot.forEach((doc) => {
        transactions.push({
          id: doc.id,
          RequestType: doc.data().RequestType,
          Platform: doc.data().Platform,
          sendResponse: (response: string) => sendResponse(doc.id, response),
          details: doc.data().Details,
        })
      })
      setTransactionList(transactions)
    },
    [setTransactionList],
  )

  useEffect(() => {
    if (!authState?.isLoggedIn) {
      return () => {}
    }
    const db = getFirestore()
    const transactions = collection(db, 'transactions')
    const q = query(
      transactions,
      where('UserId', '==', authState.userId),
      where('Response', '==', null),
    )
    const unsubscribe = onSnapshot(q, transactionListCallback)
    return unsubscribe
  }, [authState])
  return transactionList
}

export default useTransaction
