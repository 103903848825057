import AsyncStorage from '@react-native-async-storage/async-storage'
import { createContext, useEffect, useState } from 'react'

export type userCountryType = {
  country: string | undefined
}

type contextType = {
  userCountry: userCountryType
  setUserCountry: (data: userCountryType) => void
}

const initialState: userCountryType = {
  country: undefined,
}

export const UserCountryContext = createContext<contextType>(initialState as any)
export function UserCountryProvider({ children }: { children: React.ReactNode }) {
  const [userCountry, setUserCountry] = useState(initialState)
  useEffect(() => {
    AsyncStorage.getItem('userCountry')
      .then((value: any) => {
        if (value) {
          setUserCountry(JSON.parse(value))
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    AsyncStorage.setItem('userCountry', JSON.stringify(userCountry))
  }, [userCountry])

  return (
    <UserCountryContext.Provider value={{ userCountry, setUserCountry }}>
      {children}
    </UserCountryContext.Provider>
  )
}
