import { onSnapshot, getFirestore, DocumentData, collection } from 'firebase/firestore'
import { useEffect, useContext, useState } from 'react'

import { AuthContext } from '../context/auth-context'

// todo time out the txn
const usePlatformStatus = (platform?: string) => {
  const db = getFirestore()
  const { authState } = useContext(AuthContext)
  const [data, setData] = useState<DocumentData[]>([])

  useEffect(() => {
    if (!authState.isLoggedIn) {
      return
    }

    const profile = collection(db, 'profiles', authState.userId, 'platforms')
    const unsub = onSnapshot(profile, (doc) => {
      const m = doc.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        }
      })
      console.log('d0cs', m)
      setData(m)
      //   doc.docs.forEach((doc) => {
      //     const data = {
      //       id: doc.id,
      //       ...doc.data(),
      //     }
      //     setData(data);
      //   });
    })
    return unsub
  }, [platform, authState, setData])

  console.log('STATUS HOOKS', { data })

  return { data }
}

export default usePlatformStatus
