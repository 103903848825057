import React from 'react'
import Svg, { Path } from 'react-native-svg'

export function AnalyticsIcon({ color }: { color: string }) {
  return (
    <Svg width="24" height="24" fill="none" viewBox="0 0 24 24">
      <Path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.75"
        d="M18 20V10M12 20V4M6 20v-6"
      ></Path>
    </Svg>
  )
}
