import React, { useEffect } from 'react'
import { Text, ImageBackground, StyleSheet, View, Pressable, SafeAreaView } from 'react-native'
import { Logo } from '../../icons/logo'
import { goToLoginScreen, goToSignUpScreen } from '../../service/navigation-service'
import { themeColors, themeFontSizes } from '../../themes'
import { themeFontFamily } from '../../themes/theme-font-family'
import { deviceInfo } from '../../utils/device-info'
import { track } from '../../utils/mix-panel-utils'
// import bgImage from './../../../assets/sellstuffs-landing-image.jpg'
const bgImage =
  'https://res.cloudinary.com/code-freak/image/upload/v1671701765/Sellstuffs-landing-image_l9zqzc.jpg'

export function LandingScreen() {
  useEffect(() => {
    track('Landing screen', deviceInfo)
  }, [])

  return (
    <View style={styles.containter}>
      <ImageBackground source={{ uri: bgImage }} resizeMode="cover" style={styles.imageBackground}>
        {/* LOGO HERE */}
        <View style={styles.logoWrapper}>
          <View style={{ borderBottomWidth: 1.5, paddingBottom: 10 }}>
            <Logo />
          </View>

          <View style={{ marginTop: 40 }}>
            <Text
              style={{
                fontSize: themeFontSizes.xxl,
                lineHeight: 38,
                fontWeight: '500',
                fontFamily: 'Druk-medium',
                textTransform: 'uppercase',
              }}
            >
              Welcome to sellstuff
            </Text>
          </View>
        </View>

        <View style={styles.buttonWrapper}>
          <View style={styles.buttonContainer}>
            <Pressable
              onPress={() => {
                track('First time login button')
                goToLoginScreen()
              }}
              style={[styles.button, { backgroundColor: themeColors.black }]}
            >
              <Text style={styles.buttonText}>Log in</Text>
            </Pressable>

            <Pressable
              onPress={() => {
                track('First time signup button')
                goToSignUpScreen()
              }}
              style={[styles.button, { backgroundColor: themeColors.white }]}
            >
              <Text style={[styles.buttonText, { color: themeColors.black }]}>create account</Text>
            </Pressable>
          </View>
        </View>
      </ImageBackground>
    </View>
  )
}

const styles = StyleSheet.create({
  containter: {
    flex: 1,
    backgroundColor: 'transparent',
  },

  imageBackground: {
    flex: 1,
    paddingVertical: 70,
    paddingHorizontal: 16,
  },

  logoWrapper: {
    flex: 2.5,
  },

  logoContainer: {
    backgroundColor: themeColors.primary,
    width: '52%',
    borderRadius: 15,
    paddingHorizontal: 6,
  },

  buttonWrapper: {
    flex: 0.5,
    width: '100%',
    // justifyContent: 'center',
    // flexDirection: 'column'
  },

  buttonContainer: {
    // paddingHorizontal: 24
  },

  button: {
    height: 52,
    justifyContent: 'center',
    marginBottom: 16,
    borderRadius: 6,
    borderWidth: 1,
  },

  buttonText: {
    textTransform: 'uppercase',
    color: themeColors.white,
    textAlign: 'center',
    fontWeight: '500',
    fontSize: themeFontSizes.md,
    lineHeight: 20,
    fontFamily: themeFontFamily.medium,
  },
})
