import { Ionicons } from '@expo/vector-icons'
import { createStackNavigator } from '@react-navigation/stack'
import { TouchableOpacity } from 'react-native'
import { AnalyticsScreen } from '../screen/analytics/analytics-screen'
import { CreateStuffScreen } from '../screen/create-stuff/create-stuff-screen'
import { HomeScreen } from '../screen/home/home-screen'
import { MyStuffScreen } from '../screen/my-stuff/my-stuff-screen'
import { ProductDetailScreen } from '../screen/product-detail/product-detail-screen'
import { ProfileScreen } from '../screen/profile/profile-screen'
import { themeColors } from '../themes'

const HomeStack = createStackNavigator()
const MyStuffStack = createStackNavigator()
const CreateStuffStack = createStackNavigator()
const AnalysticsStack = createStackNavigator()
const ProfileStack = createStackNavigator()

export const HomeStackNavigator = () => {
  return (
    <HomeStack.Navigator>
      <HomeStack.Screen name={'home'} component={HomeScreen} options={{ headerShown: false }} />
      <HomeStack.Screen
        name={'productDetail'}
        component={ProductDetailScreen}
        options={{
          headerTitle: 'Product Detail',
          //  headerShown: false,
        }}
      />
    </HomeStack.Navigator>
  )
}
export const MyStuffStackNavigator = () => {
  return (
    <MyStuffStack.Navigator>
      <MyStuffStack.Screen
        name={'myCloset'}
        component={MyStuffScreen}
        options={{ headerShown: false }}
      />
      <MyStuffStack.Screen
        name={'productDetail'}
        component={ProductDetailScreen}
        options={{
          // headerShown: false,
          headerTitle: 'Product Detail',
          // headerRight(props) {
          //   return (
          //     <TouchableOpacity style={{
          //       marginRight: 16,
          //       // borderWidth: 1,
          //       borderRadius: 20,
          //       width: 32,
          //       height: 32,
          //       alignItems: 'center',
          //       justifyContent: 'center',
          //       backgroundColor: themeColors.inputBoarder
          //     }}>
          //       <Ionicons name={'create-outline'} size={20} borderWidth={2} />
          //     </TouchableOpacity>
          //   )
          // },
        }}
      />
    </MyStuffStack.Navigator>
  )
}

export const CreateStuffStackNavigator = () => {
  return (
    <CreateStuffStack.Navigator screenOptions={{ presentation: 'modal' }}>
      <CreateStuffStack.Screen
        name={'createStuff'}
        component={CreateStuffScreen}
        options={{ headerShown: false }}
      />
    </CreateStuffStack.Navigator>
  )
}

export const AnalyticsStackNavigator = () => {
  return (
    <AnalysticsStack.Navigator>
      <AnalysticsStack.Screen
        name={'analytics'}
        component={AnalyticsScreen}
        options={{ headerShown: false }}
      />
    </AnalysticsStack.Navigator>
  )
}

export const ProfileStackNavigator = () => {
  return (
    <ProfileStack.Navigator>
      <ProfileStack.Screen
        name={'myProfile'}
        component={ProfileScreen}
        options={{ headerShown: false }}
      />
    </ProfileStack.Navigator>
  )
}
