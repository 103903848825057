import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import {
  ActivityIndicator,
  Image,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import { AppUserContext } from '../../context/app-user-context'
import { FtueContext } from '../../context/ftue-context'
import { UserCountryContext } from '../../context/user-country-context'
import { signOut } from 'firebase/auth'
import { auth } from '../../config/firebase'
import { themeColors, themeFontSizes } from '../../themes'
import { AuthContext } from '../../context/auth-context'
import { useGetProfilePlatforms } from '../account/hooks/use-get-profile-platforms'
import { BottomSheetModal } from '@gorhom/bottom-sheet'
import { BaseBottomSheetModal } from '../../components/base-bottom-sheet'
import { PlatformLoginBottomSheet } from '../account/platform-login-bottomsheet'
import usePlatformStatus from '../../hooks/usePlatformStatus'
import { Logo } from '../../icons/logo'
import { themeFontFamily } from '../../themes/theme-font-family'
import { ProfilePlatform } from './component/profile-platform'
import useJob from '../../hooks/useJob'
import { AlertModal } from './component/alert-modal'
import { mergeArrays } from '../../utils/merge-arrays'
import { track } from '../../utils/mix-panel-utils'

const imgUrl =
  'https://res.cloudinary.com/code-freak/image/upload/v1648607324/placeholder-image-person-jpg_wlychq.jpg'
export function ProfileScreen() {
  const { setUserState, userState } = useContext(AppUserContext)
  const { authState } = useContext(AuthContext)
  const { setFtueState } = useContext(FtueContext)
  const { setUserCountry } = useContext(UserCountryContext)
  const [platformId, setPlatformId] = useState<string>('')
  const [platformName, setPlatformName] = useState<string>('mercari')
  // const [connectedPlatformId, setConnectedPlatformId] = useState<string>('');
  const [loggedInPlatformId, setLoggedInPlatformId] = useState<string[]>([])
  const [isLoadingPlatformLogin, setIsLoadingPlatformLogin] = useState<boolean>(false)
  // const [ready, setReady] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const { data } = usePlatformStatus()
  const [jobId, setJobId] = useState('')
  const job = useJob(jobId)

  const { profilePlatforms, isLoadingPlatform } = useGetProfilePlatforms()
  const bottomSheetModalRef = useRef<BottomSheetModal>(null)
  const snapPoints = useMemo(() => ['90%'], [])
  const mergedData = mergeArrays(profilePlatforms, data)

  const onConfirmPress = () => {
    bottomSheetModalRef.current?.close()
  }

  useEffect(() => {
    if (job?.ready) {
      setIsLoadingPlatformLogin(false)
    }

    if (job?.error) {
      setTitle('Error')
      setMessage(
        'An error has occured while connecting to your martketplace, please try again later.',
      )
      setIsVisible(true)
      setIsLoadingPlatformLogin(false)
      return
    }

    if (job?.result) {
      setTitle('Success')
      setMessage(
        'You have successfully connected to your marketplace, enjoy the full experience of sellstuff.',
      )
      setIsVisible(true)
      setIsLoadingPlatformLogin(false)
      return
    }

    return () => {}
  }, [job, data])

  const handleSignOut = () => {
    setUserState({ user: undefined })
    setFtueState({ isFTUE: false })
    signOut(auth)
    setUserCountry({ country: undefined })
    track('app_logout')
  }

  const onOpenButtomSheet = () => {
    bottomSheetModalRef.current?.present()
  }

  const handleOpenPlatformLogin = (id: string, name: string) => {
    // todo disable when is user already logged in to that platform
    onOpenButtomSheet()
    setPlatformId(id)
    setPlatformName(id)
    track('selected_marketplace_login', { marketplace: id })
  }

  return (
    <View style={styles.container}>
      <View style={[styles.headerWrapper]}>
        <View>
          <View style={{ paddingBottom: 16 }}>
            <Logo />
          </View>
        </View>
      </View>

      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={styles.content}>
          {/* <View style={{
            flexDirection: 'row',
            alignItems: 'center',
            paddingBottom: 16
          }}>
            <Text style={{
              fontSize: themeFontSizes.xl,
              fontFamily: themeFontFamily.itcGaramondStd,
              fontWeight: '300',
              lineHeight: 20,
              opacity: 0.8,
              marginRight: 8
            }}>My</Text>
            <Text style={{
              fontSize: themeFontSizes.xl,
              fontFamily: 'chalet',
              fontWeight: '400',
              lineHeight: 22
            }}>Profile</Text>
          </View>
          <View style={styles.imageWrapper}>
            <Image
              source={{ uri: imgUrl }}
              style={styles.image}
            />
          </View>

          <View style={styles.infoSection}>
            <Text style={styles.userName}>{userState.user?.name}</Text>
            <Text style={styles.userEmail}>{authState?.email}</Text>
            <Text style={styles.userCountry}>{userState.user?.country}, {userState.user?.state}</Text>
          </View> */}

          <View style={styles.platformsSection}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                // paddingBottom: 16
              }}
            >
              <Text
                style={{
                  fontSize: themeFontSizes.xl,
                  fontFamily: themeFontFamily.itcGaramondStd,
                  fontWeight: '300',
                  lineHeight: 20,
                  opacity: 0.8,
                  marginRight: 8,
                }}
              >
                Your
              </Text>
              <Text
                style={{
                  fontSize: themeFontSizes.xl,
                  fontFamily: 'chalet',
                  fontWeight: '400',
                  lineHeight: 22,
                }}
              >
                Accounts
              </Text>
            </View>
            <Text
              style={{
                fontSize: themeFontSizes.md,
                fontFamily: 'chalet',
                lineHeight: 22,
                fontWeight: '400',
                opacity: 0.8,
                color: themeColors.gray300,
                marginVertical: 8,
              }}
            >
              Here is where you can connect other marketplaces for crossposting,
            </Text>

            <View>
              {isLoadingPlatform && (
                <View
                  style={{
                    paddingVertical: 17,
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  }}
                >
                  <ActivityIndicator size="large" color={themeColors.gray300} />
                </View>
              )}

              {profilePlatforms?.length > 0 &&
                mergedData?.map((platform, index) => {
                  const selectedMarketplace = platformId === platform?.id
                  return (
                    <ProfilePlatform
                      key={index}
                      platform={platform}
                      selectedMarketplace={selectedMarketplace}
                      handleOpenPlatformLogin={handleOpenPlatformLogin}
                      isLoadingPlatformLogin={isLoadingPlatformLogin}
                    />
                  )
                })}
            </View>

            <Pressable style={styles.logoutBtn} onPress={handleSignOut}>
              <Text style={styles.logoutText}>Log out</Text>
            </Pressable>

            <AlertModal
              isVisible={isVisible}
              setIsVisible={setIsVisible}
              title={title}
              message={message}
            />

            <BaseBottomSheetModal
              snapPoints={snapPoints}
              onDismiss={onConfirmPress}
              bottomSheetModalRef={bottomSheetModalRef}
            >
              <PlatformLoginBottomSheet
                platformId={platformId}
                onConfirmPress={onConfirmPress}
                platformName={platformName}
                setLoggedInPlatformId={setLoggedInPlatformId}
                loggedInPlatformId={loggedInPlatformId}
                setIsLoadingPlatform={setIsLoadingPlatformLogin}
                setJobId={setJobId}
              />
            </BaseBottomSheetModal>
          </View>
        </View>
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: themeColors.appBackground,
    paddingHorizontal: 16,
    flex: 1,
  },

  headerWrapper: {
    paddingTop: 16,
  },

  imageWrapper: {
    width: 100,
    height: 100,
    borderRadius: 50,
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: themeColors.white,
  },

  image: {
    height: 90,
    width: 90,
    borderRadius: 50,
  },

  infoSection: {
    paddingTop: 16,
  },

  userName: {
    fontWeight: '600',
    fontSize: themeFontSizes.xl,
    lineHeight: 22,
    fontFamily: 'chalet',
    textTransform: 'capitalize',
  },

  userEmail: {
    fontWeight: '300',
    fontSize: themeFontSizes.lg,
    lineHeight: 20,
    fontFamily: themeFontFamily.itcGaramondStd,
    marginTop: 12,
  },

  userCountry: {
    fontWeight: '300',
    fontSize: themeFontSizes.lg,
    lineHeight: 20,
    fontFamily: themeFontFamily.itcGaramondStd,
    marginTop: 12,
  },

  loginText: {
    textAlign: 'center',
    fontWeight: '500',
    lineHeight: 24,
  },

  platformStyle: {
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: themeColors.gray100,
    paddingRight: 16,
    paddingLeft: 5,
    paddingVertical: 8,
    borderRadius: 25,
    borderColor: themeColors.gray100,
  },

  platformSection: {
    paddingHorizontal: 24,
  },

  content: {},

  platformsSection: {
    paddingVertical: 16,
  },

  platformWrapper: {
    flexDirection: 'row',
  },

  platformContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1.5,
    borderColor: themeColors.gray400,
    paddingVertical: 17,
  },

  platformInfo: {
    paddingLeft: 16,
  },

  logoutBtn: {
    marginTop: 24,
    padding: 16,
  },

  logoutText: {
    color: themeColors.black,
    fontWeight: '500',
    lineHeight: 20,
    fontFamily: themeFontFamily.medium,
    textAlign: 'center',
  },
})
