import { useEffect, useRef, useState } from 'react'
import { Animated, StyleSheet, Text, View } from 'react-native'
import { themeColors, themeFontSizes } from '../../../themes'

export const ProgressBar = ({ percentage }: { percentage: number }) => {
  const [progressCount, setProgressCount] = useState(percentage)
  const counter = useRef(new Animated.Value(0)).current

  console.log('xxxxxx', progressCount)

  const load = (count: number) => {
    Animated.timing(counter, {
      toValue: count,
      duration: 500,
      useNativeDriver: true,
    }).start()
  }

  useEffect(() => {
    load(progressCount)
    if (progressCount >= 100) {
      setProgressCount(100)
    }
  }, [progressCount])

  const width = counter.interpolate({
    inputRange: [0, 100],
    outputRange: ['0%', '100%'],
    extrapolate: 'clamp',
  })

  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <View style={styles.progressBar}>
        <Animated.View
          style={[
            [StyleSheet.absoluteFill],
            {
              backgroundColor: themeColors.blue,
              width,
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
            },
          ]}
        ></Animated.View>
      </View>

      <View
        style={{
          width: '20%',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
        }}
      >
        <Text style={styles.progressBarText}>{progressCount}%</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  progressBar: {
    height: 20,
    width: '80%',
    backgroundColor: themeColors.inputBoarder,
    borderColor: '#000',
    // borderWidth: 1,
    borderRadius: 5,
  },

  progressBarText: {
    fontFamily: 'chalet',
    fontSize: themeFontSizes.md,
    lineHeight: 20,
    fontWeight: '400',
    color: themeColors.blue,
  },
})
