import {
  Alert,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native'
import React, { useCallback, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { Ionicons } from '@expo/vector-icons'
import { BaseButton } from '../../components/base-button'
import { themeColors, themeFontSizes } from '../../themes'
import { axiosPost } from '../../utils/https-utils'
import { configValue } from '../../config/config-values'
import { track } from '../../utils/mix-panel-utils'
import { themeFontFamily } from '../../themes/theme-font-family'

type PlatformLoginBottomSheetProps = {
  platformId: string
  platformName: string
  onConfirmPress: () => void
  setLoggedInPlatformId: (e: string[]) => void
  loggedInPlatformId: string[]
  setIsLoadingPlatform: (e: boolean) => void
  setJobId: (e: string) => void
}

const loginScheme = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
})

const platformLinks = [
  {
    platformId: 'depop',
    link: 'https://www.depop.com/password-reset/',
  },
  {
    platformId: 'mercari',
    link: 'https://www.mercari.com/password/reset/start/',
  },
]

export const PlatformLoginBottomSheet = (props: PlatformLoginBottomSheetProps) => {
  const {
    platformId,
    onConfirmPress,
    platformName,
    setLoggedInPlatformId,
    loggedInPlatformId,
    setIsLoadingPlatform,
    setJobId,
  } = props
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const platformCreatePasswordLink = platformLinks.find(
    (link) => link.platformId === platformId,
  )?.link

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(loginScheme),
  })

  const onSubmit = useCallback(async (data: any) => {
    const { username, password } = data
    setIsLoading(true)
    try {
      const res = await axiosPost({
        url: `${configValue.apiHost}/profilePlatforms`,
        data: {
          id: platformId,
          username,
          password,
        },
      })
      setIsLoading(false)
      if (res.message === 'success') {
        setLoggedInPlatformId([...loggedInPlatformId, platformId])
        onConfirmPress()
        setIsLoadingPlatform(true)
        track('plaform_login_success', { platform: platformName, id: platformId, username })
        setJobId(res.jobId)
      }
    } catch (error) {
      setIsLoading(false)
      track('plaform_login_failed', { platform: platformName, id: platformId, username })
      return error
    }
  }, [])

  console.log('-- id', platformId)
  console.log('-- link', platformCreatePasswordLink)

  return (
    <ScrollView style={styles.container}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Text style={styles.link}>Link</Text>
        <Text style={styles.title}>{platformName}</Text>
      </View>
      <Text style={styles.info}>
        To connect your {platformName} account, log in using your {platformName} credentials below.
      </Text>
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <View style={styles.textInputStyle}>
            <TextInput
              style={[
                styles.input,
                {
                  fontSize: themeFontSizes.md,
                  color: themeColors.placeHolderColor,
                  fontFamily: 'chalet',
                  fontWeight: '400',
                  lineHeight: 14,
                },
              ]}
              placeholder="Enter username or email"
              onChangeText={(value: string) => {
                onChange(value)
              }}
            />
            <Ionicons name="mail-outline" size={24} />
          </View>
        )}
        name="username"
        rules={{ required: true }}
      />

      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <View style={[styles.textInputStyle, { marginTop: 10 }]}>
            <TextInput
              style={[
                styles.input,
                {
                  fontSize: themeFontSizes.md,
                  color: themeColors.placeHolderColor,
                  fontFamily: 'chalet',
                  fontWeight: '400',
                  lineHeight: 14,
                },
              ]}
              placeholder="Password"
              secureTextEntry
              onChangeText={(value: string) => {
                onChange(value)
              }}
            />
            <Ionicons name="lock-closed-outline" size={24} />
          </View>
        )}
        name="password"
        rules={{ required: true }}
      />

      <BaseButton
        onPress={handleSubmit(onSubmit)}
        isLoading={isLoading}
        title="Login"
        marginBottom={8}
        backgroundColor={themeColors.black}
      />
      <TouchableOpacity
        style={styles.forgotPassword}
        onPress={() => window.open(platformCreatePasswordLink as string, '_blank')}
      >
        <Text style={styles.externalLink}>Reset password?</Text>
      </TouchableOpacity>

      <View>
        <Text style={[styles.title, { marginStart: 0 }]}>Social Authentication</Text>
        <Text style={styles.info}>
          If you typically use social authentication methods like Google, Facebook, or Apple to
          access {platformName}, you can still log in to SellStuff by setting up a new password for
          your {platformName} account. To do this, simply reset your password using the email
          address that's associated with your {platformName} account. This is the same email address
          that {platformName} uses to communicate with you.
        </Text>
        <TouchableOpacity
          onPress={() => window.open(platformCreatePasswordLink as string, '_blank')}
        >
          <Text style={styles.externalLink}>Click here to create a new password</Text>
        </TouchableOpacity>
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 24,
    paddingVertical: 24,
  },

  logoWrapper: {
    flex: 1.5,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: themeColors.primary,
  },

  logoContainer: {
    backgroundColor: themeColors.primary,
    width: '52%',
    borderRadius: 15,
    paddingHorizontal: 6,
    paddingTop: 54,
  },

  loginContainer: {
    flex: 2.5,
    backgroundColor: themeColors.white,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    paddingHorizontal: 24,
  },

  handle: {
    borderWidth: 1,
    height: 5,
    width: '12%',
    alignSelf: 'center',
    marginTop: 10,
    borderRadius: 15,
    backgroundColor: themeColors.gray200,
    borderColor: themeColors.gray200,
    marginBottom: 30,
  },

  title: {
    fontFamily: themeFontFamily.medium,
    fontSize: themeFontSizes.lg,
    lineHeight: 20,
    opacity: 0.8,
    marginStart: 7,
  },

  link: {
    fontFamily: themeFontFamily.itcGaramondStd,
    fontStyle: 'italic',
    fontSize: themeFontSizes.lg,
    lineHeight: 20,
    opacity: 0.8,
    fontWeight: '300',
  },

  externalLink: {
    fontFamily: themeFontFamily.itcGaramondStd,
    fontStyle: 'italic',
    fontSize: themeFontSizes.lg,
    lineHeight: 20,
    opacity: 0.8,
    fontWeight: '300',
    textDecorationLine: 'underline',
  },

  socialsWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 30,
    fontWeight: '300',
  },

  socialsContainer: {
    borderRadius: 25,
    backgroundColor: themeColors.gray100,
    height: 48,
    width: 48,
    alignItems: 'center',
    justifyContent: 'center',
  },

  form: {
    width: '100%',
  },

  textInputStyle: {
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: themeColors.white,
    paddingHorizontal: 16,
    borderRadius: 2,
    borderColor: themeColors.inputBoarder,
  },

  input: {
    flex: 1,
    backgroundColor: 'transparent',
    height: 46,
  },

  forgotPasswordText: {
    textAlign: 'right',
    color: themeColors.gray500,
    marginVertical: 24,
  },

  button: {
    height: 50,
    justifyContent: 'center',
    marginTop: 16,
    marginBottom: 24,
    borderRadius: 20,
  },

  buttonText: {
    textTransform: 'uppercase',
    color: themeColors.white,
    textAlign: 'center',
    fontWeight: '700',
  },

  altAction: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  textAction: {
    fontWeight: '300',
    color: themeColors.gray500,
  },

  textLink: {
    color: themeColors.link,
    textDecorationLine: 'underline',
    fontWeight: '600',
    marginStart: 5,
  },

  info: {
    fontSize: themeFontSizes.md,
    color: themeColors.gray300,
    marginBottom: 16,
    lineHeight: 20,
    fontFamily: 'chalet',
    fontWeight: '400',
    opacity: 0.8,
  },

  forgotPassword: {
    marginBottom: 16,
  },
})
