import { Pressable, StyleSheet, Text, View } from 'react-native'
import React, { useContext, useEffect, useState } from 'react'
import * as Facebook from 'expo-auth-session/providers/facebook'
import { ResponseType } from 'expo-auth-session'
import { getAuth, FacebookAuthProvider, signInWithCredential } from 'firebase/auth'
import { axiosGet } from '../../../utils/https-utils'
import { AuthContext } from '../../../context/auth-context'
import { FtueContext } from '../../../context/ftue-context'
import { FacebookSvgIcon, themeColors } from '../../../themes'
import * as WebBrowser from 'expo-web-browser'
import { configValue } from '../../../config/config-values'
import { registerUser, track } from '../../../utils/mix-panel-utils'
import { BaseButton } from '../../../components/base-button'

WebBrowser.maybeCompleteAuthSession()

type FaceBookAuthProps = {
  isLogin?: boolean
  setUserState?: any // todo fix this
  setLoadingSocialAuth: (data: boolean) => void
  trackId: string
}

export const FaceBookAuth = (props: FaceBookAuthProps) => {
  const { isLogin, setUserState, setLoadingSocialAuth, trackId } = props
  const { setAuthState } = useContext(AuthContext)
  const { setFtueState } = useContext(FtueContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [request, response, promptAsync] = Facebook.useAuthRequest({
    responseType: ResponseType.Token,
    clientId: configValue.fbid,
    redirectUri: 'https://auth.expo.io/@davidfosterbrown/sellstuff',
  })

  useEffect(() => {
    if (response?.type === 'success') {
      const { access_token } = response.params
      const auth = getAuth()
      const credential = FacebookAuthProvider.credential(access_token)
      setLoadingSocialAuth(true)
      // Sign in with the credential from the Facebook user.
      signInWithCredential(auth, credential)
        .then((user) => {
          if (user) {
            // make api call to validate token
            axiosGet({
              url: '/validateToken',
            })
              .then(async (response) => {
                if (response.message === 'success') {
                  // @ts-ignore
                  setAuthState({
                    token: user._tokenResponse.idToken,
                    email: user._tokenResponse.email,
                    isLoggedIn: true,
                    userId: user._tokenResponse.localId,
                    profileImage: { uri: user._tokenResponse.photoURL },
                    name: user._tokenResponse.displayName,
                  })
                  if (isLogin) {
                    track(trackId)
                    // @ts-ignore
                    registerUser({ email: user._tokenResponse.email })
                    // @ts-ignore
                    setUserState({
                      user: {
                        email: user._tokenResponse.email,
                        isLoggedIn: true,
                        userId: user._tokenResponse.localId,
                        name: user._tokenResponse.displayName,
                        profileImage: { uri: user._tokenResponse.photoURL },
                      },
                    })
                    setLoadingSocialAuth(false)
                    return
                  }
                  setFtueState({ isFTUE: true })
                  setLoadingSocialAuth(false)
                  return
                }
              })
              .catch((error) => {
                setLoadingSocialAuth(false)
                console.log('error: ', error)
              })
          }
        })
        .catch((error) => {
          console.log('--error--', error)
          setLoadingSocialAuth(false)
          alert(error?.message)
          return error
        })
    }
  }, [response])

  return (
    <BaseButton
      onPress={() => promptAsync()}
      marginTop={0}
      marginBottom={16}
      title="Countinue with Facebook"
      color={themeColors.black}
      backgroundColor={themeColors.white}
      hasLeftIcon={true}
      leftIcon={<FacebookSvgIcon />}
    />
  )
}

const styles = StyleSheet.create({})
