import React from 'react'
import { Control, Controller, FieldValues } from 'react-hook-form'
import { View, Text, StyleSheet, TextInput, StyleProp, ViewStyle, TextStyle } from 'react-native'
import { themeColors, themeFontSizes } from '../themes'
import { themeFontFamily } from '../themes/theme-font-family'

type ControlledInputProps = {
  value?: string
  // onChangeText: (value: string) => void;
  placeholder: string
  inputContainer?: StyleProp<ViewStyle>
  inputStyle?: StyleProp<TextStyle>
  control: Control<FieldValues, any>
  name: string
  error?: boolean
  hasWordCountError?: boolean
  descriptionErrorMsg?: string
  required?: boolean
  hasCurrencySymbol?: boolean
  defaultValue?: string
} & TextInput['props']

export function ControlledInput(props: ControlledInputProps) {
  const {
    value,
    placeholder,
    inputContainer,
    control,
    name,
    error,
    required,
    inputStyle,
    hasWordCountError,
    descriptionErrorMsg,
    hasCurrencySymbol = false,
    defaultValue = '',
    ...rest
  } = props

  return (
    <View>
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <View
            style={[
              styles.textInputContainer,
              inputContainer,
              { borderColor: error ? 'red' : themeColors.inputBoarder },
            ]}
          >
            {hasCurrencySymbol && !!value && <Text style={{ fontSize: themeFontSizes.md }}>$</Text>}
            <TextInput
              {...rest}
              style={[styles.input, inputStyle]}
              placeholder={placeholder}
              value={value}
              onChangeText={(value: string) => {
                onChange(value)
              }}
            />
          </View>
        )}
        name={name}
        rules={{ required }}
        defaultValue={defaultValue}
      />
      {error && <Text style={styles.errorText}>{name} is required.</Text>}
      {hasWordCountError && <Text style={styles.errorText}>{descriptionErrorMsg}</Text>}
    </View>
  )
}

const styles = StyleSheet.create({
  textInputContainer: {
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: themeColors.white,
    paddingHorizontal: 16,
    borderRadius: 2,
    borderColor: themeColors.inputBoarder,
  },

  input: {
    flex: 1,
    backgroundColor: 'transparent',
    color: '#424242',
    paddingVertical: 16,
  },

  errorText: {
    color: 'red',
    fontFamily: themeFontFamily.itcGaramondStd,
    fontSize: themeFontSizes.md,
    lineHeight: 24,
    fontWeight: '400',
  },
})
