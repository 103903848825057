import { StyleSheet, View } from 'react-native'
import { NavigationContainer, NavigationContainerRef } from '@react-navigation/native'
import React, { useCallback, useEffect, useRef } from 'react'
import { RootNavigator } from './src/navigation/root-navigation'
import * as SplashScreen from 'expo-splash-screen'
import { AppUserProvider } from './src/context/app-user-context'
import { setNavigationRef } from './src/service/navigation-service'
import { AuthProvider } from './src/context/auth-context'
import { FTUEProvider } from './src/context/ftue-context'
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet'
import { PortalProvider } from '@gorhom/portal'
import { initMixPanel } from './src/utils/mix-panel-utils'
import { UserCountryProvider } from './src/context/user-country-context'
import { TransactionProvider } from './src/context/TransactionProvider'
import { TwoFactorErrorHandler } from './src/components/TwoFactorErrorHandler'
import { useFonts } from 'expo-font'
import { injectWebCss } from './src/utils/inject-web-css'

export type RootStackParamList = {
  Home: string
  Profile: { userId: string }
  NotFound: string
}

export default function App() {
  const routeNameRef = useRef<NavigationContainerRef<ReactNavigation.RootParamList> | null>(null)
  const [fontsLoaded] = useFonts({
    'Druk-bold': require('./assets/fonts/DrukWide-Bold-App.ttf'),
    'Druk-medium': require('./assets/fonts/DrukWide-Medium-App.ttf'),
    chalet: require('./assets/fonts/Chalet-LondonNineteenSixty.otf'),
    'itc-garamond-std': require('./assets/fonts/ITCGaramondStd-LtIta.otf'),
  })

  useEffect(() => {
    async function prepare() {
      try {
        // Keep the splash screen visible while we fetch resources
        await SplashScreen.preventAutoHideAsync()
        initMixPanel()
        injectWebCss()
      } catch (e) {
        console.warn(e)
      } finally {
        // Tell the application to render
        // setAppIsReady(true);
      }
    }
    prepare()
  }, [])

  const onLayoutRootView = useCallback(async () => {
    if (fontsLoaded) {
      await SplashScreen.hideAsync()
    }
  }, [fontsLoaded])

  if (!fontsLoaded) {
    return null
  }

  return (
    <PortalProvider>
      <BottomSheetModalProvider>
        <NavigationContainer
          ref={(ref) => {
            routeNameRef.current = ref
            setNavigationRef(ref)
          }}
        >
          <AppUserProvider>
            <AuthProvider>
              <TransactionProvider>
                <TwoFactorErrorHandler />
                <FTUEProvider>
                  <UserCountryProvider>
                    <View style={{ flex: 1 }} onLayout={onLayoutRootView}>
                      <RootNavigator />
                    </View>
                  </UserCountryProvider>
                </FTUEProvider>
              </TransactionProvider>
            </AuthProvider>
          </AppUserProvider>
        </NavigationContainer>
      </BottomSheetModalProvider>
    </PortalProvider>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
