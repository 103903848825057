import { View, Text, StyleSheet, ActivityIndicator } from 'react-native'
import React from 'react'
import { themeColors, themeFontSizes } from '../../../themes'
import { themeFontFamily } from '../../../themes/theme-font-family'
import { BaseButton } from '../../../components/base-button'
import { goProfile, goToPostItem } from '../../../service/navigation-service'
import ProductsFlatList from '../../product-detail/components/product-flatlist'
import { DocumentData } from 'firebase/firestore'
import { supportedMarketplacesType } from '../../../utils/types'

type MercariItemsProps = {
  listings: DocumentData[]
  profilePlatforms: supportedMarketplacesType[]
  onOpenButtomSheet: (item: DocumentData) => void
  isLoadingItems: boolean
  isLoadingPlatform: boolean
  ready: boolean
}
export const MercariItems = (props: MercariItemsProps) => {
  const {
    listings,
    profilePlatforms,
    onOpenButtomSheet,
    isLoadingItems,
    isLoadingPlatform,
    ready,
  } = props

  if (isLoadingItems || isLoadingPlatform) {
    return (
      <View style={styles.loadingStyle}>
        <ActivityIndicator size="large" color={themeColors.black} />
      </View>
    )
  }

  if (profilePlatforms.length === 0 || !ready) {
    return (
      <View style={styles.noPlatformContainer}>
        <View style={styles.noPlatformInfo}>
          <Text style={styles.noPlatformTitle}>Welcome!</Text>
          <Text style={styles.noPlatformContent}>
            Before you create your first post, you will need to add a connected account that you can
            add your post to.
          </Text>
        </View>
        <BaseButton
          onPress={goProfile}
          title="Add connected account"
          backgroundColor={themeColors.black}
          color={themeColors.white}
        />
      </View>
    )
  }

  if (ready && listings.length === 0) {
    return (
      <View style={styles.noItemContainer}>
        <View style={styles.noItemInfo}>
          <Text style={styles.noItemTitle}>Welcome!</Text>
          <Text style={styles.noItemContent}>
            It looks like you haven’t posted anything from your closet. It’s time to post your first
            item.{' '}
          </Text>
        </View>
        <BaseButton
          onPress={goToPostItem}
          title="Post item"
          backgroundColor={themeColors.black}
          color={themeColors.white}
        />
      </View>
    )
  }
  return <ProductsFlatList productLists={listings} platform="mercari" />
}

const styles = StyleSheet.create({
  container: {},

  noPlatformContainer: {
    padding: 16,
  },

  noPlatformInfo: {
    backgroundColor: themeColors.white,
    padding: 16,
    borderRadius: 2,
  },

  noPlatformTitle: {
    fontSize: themeFontSizes.lg,
    lineHeight: 14,
    color: themeColors.black,
    fontWeight: '400',
    fontFamily: 'chalet',
    opacity: 0.8,
    marginBottom: 8,
  },

  noPlatformContent: {
    fontFamily: themeFontFamily.itcGaramondStd,
    fontWeight: '300',
    fontSize: themeFontSizes.md,
    lineHeight: 20,
    color: themeColors.black,
    opacity: 0.8,
  },

  noItemContainer: { padding: 16 },

  noItemInfo: {
    backgroundColor: themeColors.white,
    padding: 12,
    borderRadius: 2,
  },

  noItemTitle: {
    fontSize: themeFontSizes.lg,
    lineHeight: 14,
    color: themeColors.black,
    fontWeight: '400',
    fontFamily: 'chalet',
    opacity: 0.8,
    marginBottom: 8,
  },

  noItemContent: {
    fontFamily: themeFontFamily.itcGaramondStd,
    fontWeight: '300',
    fontSize: themeFontSizes.md,
    lineHeight: 20,
    color: themeColors.black,
    opacity: 0.8,
  },

  loadingStyle: {
    height: 210,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
