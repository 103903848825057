import { FlatList, StyleSheet, View } from 'react-native'
import React from 'react'
import { ProductCard } from '../../product-detail/components/product-card'
import { DocumentData } from 'firebase/firestore'

type productListFlatListType = {
  lists: DocumentData[]
  platform: string
}

export default function ProductListFlatList(props: productListFlatListType) {
  const { lists, platform } = props
  return (
    <View style={styles.container}>
      <FlatList
        data={lists}
        renderItem={({ item }) => <ProductCard data={item} layout="grid" platform={platform} />}
        numColumns={2}
        showsHorizontalScrollIndicator={false}
        keyExtractor={(item, index) => index.toString()}
        showsVerticalScrollIndicator={false}
        columnWrapperStyle={{ width: '100%', justifyContent: 'space-between', paddingTop: 16 }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    // paddingBottom: 140,
  },
})
