import Modal from 'react-native-modal'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { themeColors, themeFontSizes } from '../../../themes'
import { BaseButton } from '../../../components/base-button'
import { themeFontFamily } from '../../../themes/theme-font-family'
import { goHome } from '../../../service/navigation-service'

type AlertModalType = {
  itemName: string
  isVisible: boolean
  isLoading: boolean
  isDeleted: boolean
  setIsVisible: (value: boolean) => void
  handleDeleteListing: () => void
}

export function DeleteItemAlert(props: AlertModalType) {
  const { itemName, isVisible, setIsVisible, handleDeleteListing, isLoading, isDeleted } = props

  return (
    <View style={{ flex: 1 }}>
      <Modal isVisible={isVisible}>
        <View style={styles.container}>
          <View style={styles.titleWrapper}>
            <Text style={styles.title}>Delete Item</Text>
          </View>

          <View style={styles.wrapper}>
            <View style={styles.messageWrapper}>
              <Text style={styles.message}>
                {!isDeleted
                  ? `Are you certain you want to remove ${itemName} from your list of available products?`
                  : `${itemName} deleted successfully`}
              </Text>
            </View>

            <View
              style={{
                flex: 1,
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              {!isDeleted && (
                <BaseButton
                  onPress={handleDeleteListing}
                  marginTop={0}
                  marginBottom={0}
                  title="Delete listing"
                  isLoading={isLoading}
                  backgroundColor={themeColors.white}
                  color={themeColors.soldBgBadge}
                />
              )}
              <BaseButton
                onPress={() => {
                  isDeleted ? goHome() : setIsVisible(false)
                }}
                disabled={isLoading}
                marginTop={16}
                marginBottom={0}
                title={`${isDeleted ? 'Done' : 'Cancel'}`}
                color={themeColors.black}
                backgroundColor={themeColors.white}
              />
            </View>
          </View>
        </View>
      </Modal>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    marginVertical: 150,
    borderRadius: 10,
  },

  titleWrapper: {
    borderBottomColor: themeColors.gray200,
    borderBottomWidth: 1,
    paddingVertical: 16,
  },

  wrapper: {
    paddingHorizontal: 20,
    paddingVertical: 16,
  },

  title: {
    textAlign: 'center',
    fontSize: themeFontSizes.xl,
    fontFamily: themeFontFamily.regular,
  },

  messageWrapper: {
    paddingBottom: 16,
  },

  message: {
    fontSize: themeFontSizes.lg,
    color: themeColors.black,
    fontFamily: 'chalet',
    lineHeight: 22,
  },
})
