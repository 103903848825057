import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import React from 'react'
import { Text, View } from 'react-native'
import {
  AnalyticsStackNavigator,
  CreateStuffStackNavigator,
  HomeStackNavigator,
  MyStuffStackNavigator,
  ProfileStackNavigator,
} from './stack-navigation'
import { Ionicons } from '@expo/vector-icons'
import { themeColors } from '../themes'
import { HomeIcon } from './navigation-icons/home-icon'
import { MyClosetIcon } from './navigation-icons/closet-icon'
import { PostListingIcon } from './navigation-icons/post-item-icon'
import { AnalyticsIcon } from './navigation-icons/analytics-icon'
import { UserIcon } from './navigation-icons/user-icon'

const Tabs = createBottomTabNavigator()

interface RootStackParamList {
  Home: string
  'My Profile': string
  Analytics: string
  MyStuff: string
  CreateStuff: string
}

export const BottomTabNavigator = () => {
  return (
    <Tabs.Navigator
      initialRouteName="Home"
      screenOptions={({ route }) => ({
        tabBarActiveTintColor: themeColors.black,
        tabBarInactiveTintColor: themeColors.gray300,
        tabBarIcon: ({ color }) => {
          const iconSize = route.name === 'Post Item' ? 45 : 20
          const icons: any = {
            //@todo type this properly
            Home: <HomeIcon color={color} />,
            'My Profile': <UserIcon color={color} />,
            Analytics: <AnalyticsIcon color={color} />,
            'My Closet': <MyClosetIcon color={color} />,
            'Post Item': <PostListingIcon color={color} />,
          }
          return (
            <View
              style={{
                paddingTop: 10,
                paddingBottom: 6,
              }}
            >
              <Text>{icons[route.name]}</Text>
            </View>
          )
        },
        tabBarLabel: ({ color }: any) => {
          // if (route.name === 'CreateStuff') {
          //   return null
          // }
          return <Text style={{ color, fontSize: 12 }}>{route.name}</Text>
        },

        tabBarStyle: {
          backgroundColor: themeColors.white,
        },
      })}
    >
      <Tabs.Screen
        name={'Home'}
        component={HomeStackNavigator}
        options={{
          headerShown: false,
        }}
      />
      <Tabs.Screen
        name={'My Closet'}
        component={MyStuffStackNavigator}
        options={{
          headerShown: false,
        }}
      />
      <Tabs.Screen
        name={'Post Item'}
        component={CreateStuffStackNavigator}
        options={{
          headerShown: false,
        }}
      />
      <Tabs.Screen
        name={'Analytics'}
        component={AnalyticsStackNavigator}
        options={{
          headerShown: false,
        }}
      />
      <Tabs.Screen
        name={'My Profile'}
        component={ProfileStackNavigator}
        options={{
          headerShown: false,
        }}
      />
    </Tabs.Navigator>
  )
}
