import { Dimensions, Image, StyleSheet, Text, View } from 'react-native'
import React, { useState } from 'react'
import { themeColors, themeFontSizes } from '../../../themes'
import Carousel from 'react-native-reanimated-carousel'
import { getSalesStatus } from './product-card'
import { themeFontFamily } from '../../../themes/theme-font-family'
import { DocumentData } from 'firebase/firestore'
import { BaseButton } from '../../../components/base-button'
import { formatDate } from '../../../utils/helpers'

type ItemsDetailsProps = {
  snapPoints?: any
  onConfirmPress?: any
  bottomSheetModalRef?: any
  item: DocumentData
  isLoadingItem?: boolean
  onOpenButtomSheet: () => void
}

const RenderItem = ({ item }: { item: any }) => {
  const width = Dimensions.get('window').width

  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          width: width / 1.12,
          flex: 1,
          justifyContent: 'center',
        }}
      >
        <Image
          source={{ uri: item?.large }}
          style={{
            resizeMode: 'cover',
            width: '100%',
            height: '100%',
          }}
        />
      </View>
    </View>
  )
}

const ItemProperties = ({
  leftText,
  rightText,
  lists,
}: {
  leftText: string
  rightText?: string | number
  lists?: any[]
}) => {
  if (!!lists && lists?.length > 0) {
    return (
      <View style={styles.itemProperties}>
        <View style={styles.itemPropertiesLeft}>
          <Text style={styles.itemPropertiesLeftText}>{leftText}</Text>
        </View>
        <>
          {!!lists && lists?.length > 0 && (
            <View
              style={[styles.itemPropertiesRight, { flexDirection: 'row', alignItems: 'center' }]}
            >
              {lists?.map((list, index) => {
                return (
                  <Text
                    style={[
                      styles.itemPropertiesRightText,
                      { marginRight: 6, textDecorationLine: 'underline' },
                    ]}
                  >
                    {list.name}
                  </Text>
                )
              })}
            </View>
          )}
        </>
      </View>
    )
  }

  return (
    <View style={styles.itemProperties}>
      {!!rightText && (
        <View style={styles.itemPropertiesLeft}>
          <Text style={styles.itemPropertiesLeftText}>{leftText}</Text>
        </View>
      )}
      <View style={styles.itemPropertiesRight}>
        <Text style={styles.itemPropertiesRightText}>{rightText}</Text>
      </View>
    </View>
  )
}

export function ProductDetail(props: ItemsDetailsProps) {
  const { item, onOpenButtomSheet } = props
  const width = Dimensions.get('window').width
  const indicators = [...new Array(item?.photos?.length).keys()]
  const [currentIndex, setCurrentIndex] = useState(0)
  const formatedPrice = (Number(item?.price) / 100).toFixed(2)
  const createdDate = !!item?.created && formatDate(item?.created)
  console.log(item)

  return (
    <View>
      <View
        style={{
          paddingHorizontal: 16,
        }}
      >
        <View style={styles.carouselWrapper}>
          <View style={[styles.statusBadge, { backgroundColor: getSalesStatus(item.status).bg }]}>
            <Text style={styles.badgeTitle}>{getSalesStatus(item.status).title}</Text>
          </View>
          <Carousel
            loop
            width={width / 1.12}
            height={width / 1.2}
            data={item?.photos}
            scrollAnimationDuration={1000}
            onSnapToItem={(index) => setCurrentIndex(index)}
            renderItem={({ item, index }) => <RenderItem item={item} />}
          />
        </View>
        <View style={styles.carouselIndicatorWrapper}>
          {indicators?.map((_, index) => {
            const activeIndicator = index === currentIndex
            return (
              <View
                key={index}
                style={{
                  height: 12,
                  width: 12,
                  backgroundColor: activeIndicator ? themeColors.black : 'transparent',
                  borderRadius: 15,
                  marginLeft: 1,
                  borderWidth: 1,
                  marginStart: 6,
                }}
              />
            )
          })}
        </View>

        <View style={{ paddingTop: 8 }}>
          <Text style={styles.itemName}>{item?.name}</Text>
          <Text style={styles.formatedPrice}>${formatedPrice}</Text>

          <View style={{ paddingVertical: 10 }}>
            <Text style={styles.descriptionTitle}>Description</Text>
            <Text style={styles.itemDescription}>{item.description}</Text>
          </View>

          <BaseButton
            onPress={onOpenButtomSheet}
            title="Edit listing"
            backgroundColor={themeColors.black}
            color={themeColors.white}
            marginTop={10}
          />
        </View>
      </View>
      <View style={{ height: 8, backgroundColor: '#ECEDF1', width: '100%' }} />
      <View style={{ paddingVertical: 16, paddingHorizontal: 16 }}>
        <Text style={styles.descriptionTitle}>Details</Text>
        <ItemProperties rightText={item?.itemSize?.sizeName} leftText={'size'} />
        <ItemProperties rightText={item?.brand?.brandName} leftText={'brand'} />
        <ItemProperties rightText={item?.itemCondition?.conditionName} leftText={'condition'} />
        <ItemProperties lists={item?.itemCategoryHierarchy} leftText={'category'} />
        <ItemProperties rightText={createdDate as string} leftText={'posted'} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  carouselWrapper: {
    borderWidth: 1,
    borderColor: themeColors.inputBoarder,
    marginVertical: 8,
    position: 'relative',
  },

  carouselIndicatorWrapper: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 8,
    minWidth: 48,
    alignSelf: 'center',
    borderWidth: 1,
    paddingEnd: 6,
    borderRadius: 28,
    backgroundColor: themeColors.carouselIndicator,
    borderColor: themeColors.carouselIndicator,
  },

  statusBadge: {
    position: 'absolute',
    top: 10,
    left: 10,
    borderWidth: 1,
    padding: 6,
    borderRadius: 24,
    zIndex: 2,
  },

  badgeTitle: {
    fontSize: themeFontSizes.sm,
    fontWeight: '400',
    color: themeColors.black,
    fontFamily: 'chalet',
    lineHeight: 12,
    textTransform: 'uppercase',
    letterSpacing: 0.02,
  },

  itemName: {
    fontSize: 20,
    lineHeight: 22,
    fontFamily: 'chalet',
    fontWeight: '500',
    letterSpacing: 0.05,
  },

  formatedPrice: {
    fontSize: themeFontSizes.lg,
    lineHeight: 22,
    fontFamily: themeFontFamily.itcGaramondStd,
    fontWeight: '400',
    letterSpacing: 0.05,
    marginTop: 5,
  },

  descriptionTitle: {
    fontSize: 20,
    lineHeight: 22,
    fontFamily: themeFontFamily.itcGaramondStd,
    fontWeight: '600',
    opacity: 0.8,
  },

  itemDescription: {
    fontSize: themeFontSizes.lg,
    lineHeight: 20,
    fontFamily: themeFontFamily.itcGaramondStd,
    fontWeight: '300',
    opacity: 0.8,
  },

  itemProperties: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '55%',
    marginTop: 12,
  },

  itemPropertiesLeft: {
    width: '50%',
  },

  itemPropertiesRight: {
    width: '50%',
  },

  itemPropertiesLeftText: {
    fontSize: themeFontSizes.lg,
    fontWeight: '500',
    fontFamily: themeFontFamily.itcGaramondStd,
    lineHeight: 22,
    letterSpacing: 0.05,
    textTransform: 'capitalize',
  },

  itemPropertiesRightText: {
    fontSize: themeFontSizes.lg,
    fontWeight: '400',
    fontFamily: 'chalet',
    lineHeight: 20,
    opacity: 0.8,
  },

  suggestedPriceSection: {
    paddingVertical: 16,
  },

  suggestedPriceTitle: {
    fontFamily: 'chalet',
    fontSize: themeFontSizes.lg,
    fontWeight: '400',
    lineHeight: 22,
    letterSpacing: 0.05,
  },

  suggestedPriceText: {
    fontFamily: 'chalet',
    fontSize: themeFontSizes.md,
    fontWeight: '400',
    lineHeight: 20,
    opacity: 0.8,
  },

  suggestedPriceSubTitle: {
    fontFamily: themeFontFamily.itcGaramondStd,
    fontSize: themeFontSizes.md,
    fontWeight: '300',
    lineHeight: 20,
    opacity: 0.8,
    marginVertical: 4,
  },

  status: {
    backgroundColor: themeColors.gray100,
    paddingVertical: 16,
    borderRadius: 16,
    marginTop: 10,
  },
})
