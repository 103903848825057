import { useEffect, useState } from 'react'
import { useAuthentication } from '../../../hooks/use-authentication'
import { axiosGet } from '../../../utils/https-utils'
import { supportedMarketplacesType } from '../../../utils/types'
//https://us-central1-sellstuff-cfb37.cloudfunctions.net/
export const useGetProfilePlatforms = () => {
  const [profilePlatforms, setProfilePlatforms] = useState<supportedMarketplacesType[]>([])
  const [isLoadingPlatform, setIsLoadingPlatform] = useState<boolean>(false)
  const { token } = useAuthentication()
  const fetchProfilePlatforms = async () => {
    setIsLoadingPlatform(true)
    const data = await axiosGet({
      authorization: token,
      url: '/profilePlatforms',
    })
    if (data) {
      setProfilePlatforms(data)
      setIsLoadingPlatform(false)
    }
  }

  useEffect(() => {
    fetchProfilePlatforms()
  }, [])

  return {
    profilePlatforms,
    isLoadingPlatform,
  }
}
