import { Dimensions, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { LineChart } from 'react-native-chart-kit'
import { themeColors } from '../../../themes'

type MonthlySalesChartProps = {
  totalSalesByMonthForChart: any[]
}
export function MonthlySalesChart(props: MonthlySalesChartProps) {
  const { totalSalesByMonthForChart } = props
  return (
    <View>
      <LineChart
        data={{
          labels: ['January', 'February', 'March', 'April', 'May', 'June'],
          datasets: [
            {
              data: totalSalesByMonthForChart,
            },
          ],
        }}
        width={Dimensions.get('window').width} // from react-native
        height={220}
        yAxisLabel="$"
        yAxisSuffix="k"
        yAxisInterval={1} // optional, defaults to 1
        chartConfig={{
          // backgroundColor: 'white',
          backgroundGradientFrom: themeColors.gray100,
          backgroundGradientTo: themeColors.gray100,
          decimalPlaces: 2, // optional, defaults to 2dp
          // color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
          color: (opacity = 1) => themeColors.primary,
          labelColor: (opacity = 1) => themeColors.gray500,
          propsForDots: {
            r: '6',
            strokeWidth: '2',
            stroke: themeColors.primary,
          },
          propsForBackgroundLines: {
            backgroundColor: 'green',
          },
        }}
        bezier
        style={{
          marginVertical: 8,
        }}
      />
    </View>
  )
}

const styles = StyleSheet.create({})
