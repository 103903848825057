import { DocumentData } from 'firebase/firestore'

export const getSoldItems = (items: DocumentData[]) =>
  items.filter((item) => item.status === 'sold')
export function getTotalSales(mercariListings: DocumentData[], depopListings: DocumentData[]) {
  // get sold items
  const mrt1 = getSoldItems(mercariListings)
  const mrt2 = getSoldItems(depopListings)

  // calculate total sales
  let totalSales = [...mrt1, ...mrt2].reduce((acc, marketplace) => {
    return acc + parseInt(marketplace.price)
  }, 0)
  return totalSales
}
