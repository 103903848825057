export const itemConditons = [
  {
    id: 1,
    description: 'New',
    value: 'ConditionNew',
    slug: 'ConditionNew',
  },
  {
    id: 2,
    description: 'Like new',
    value: 'ConditionLikenew',
    slug: 'ConditionLikenew',
  },
  {
    id: 3,
    description: 'Good',
    value: 'ConditionGood',
    slug: 'ConditionGood',
  },
  {
    id: 4,
    description: 'Fair',
    value: 'ConditionFair',
    slug: 'ConditionFair',
  },
  {
    id: 5,
    description: 'Poor',
    value: 'ConditionPoor',
    slug: 'ConditionPoor',
  },
]

export const depopItemConditons = [
  {
    description: 'Brand New',
    value: 'react-select-4-option-0',
    slug: 'react-select-4-option-0',
  },
  {
    description: 'Like new',
    value: 'react-select-4-option-1',
    slug: 'react-select-4-option-1',
  },
  {
    description: 'Used - Excellent',
    value: 'react-select-4-option-2',
    slug: 'react-select-4-option-2',
  },
  {
    description: 'Used - Good',
    value: 'react-select-4-option-3',
    slug: 'react-select-4-option-3',
  },
  {
    description: 'Used - Fair',
    value: 'react-select-4-option-4',
    slug: 'react-select-4-option-4',
  },
]

export const shippingOptions = [
  // {
  //   label: 'Free',
  //   title: 'Ship on your own',
  //   id: 'slef-shipping-1',
  //   description: 'You provide your own label and ship the item. It is not covered by delivery protection.'
  // },
  {
    label: 'prepaid',
    title: 'Standard shipping',
    id: 'prepaid-shipping-1',
    description:
      "Best for sending smal items across US. We'll email you a label and you'll ship the item. Include delivery protection.",
  },
]

export const ShippingPayerOptions = [
  {
    id: 1,
    name: 'FreeShippingNoButton',
    description: 'The buyer',
  },
  {
    id: 2,
    name: 'FreeShippingYesButton',
    description: "I'll pay",
  },
]
