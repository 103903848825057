import { Ionicons } from '@expo/vector-icons'
import { BottomSheetModal } from '@gorhom/bottom-sheet'
import React, { useMemo, useRef } from 'react'
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  StyleProp,
  ViewStyle,
  Pressable,
  ScrollView,
} from 'react-native'
import { themeColors, themeFontSizes } from '../themes'
import { themeFontFamily } from '../themes/theme-font-family'
import {
  categoriesType,
  DepopSubCategoryType,
  ItemConditons,
  subCategoriesType,
  ShippingPayerType,
} from '../utils/types'
import { BaseBottomSheetModal } from './base-bottom-sheet'

type BaseDropDownInputProps = {
  textInputStyle?: StyleProp<ViewStyle>
  value?: string
  snapPointsValue: string[]
  title: string
  lists:
    | categoriesType[]
    | subCategoriesType[]
    | ItemConditons[]
    | DepopSubCategoryType[]
    | ShippingPayerType[]
  handleSelect: (item: any) => void // todo fix type here
  placeholder: string
  errorMsg?: string
  setErrorState: (value: string) => void
  isDisabled?: boolean
}

export function BaseDropDownInput(props: BaseDropDownInputProps) {
  const {
    value,
    textInputStyle,
    title,
    lists,
    handleSelect,
    placeholder,
    errorMsg,
    setErrorState,
    snapPointsValue = ['40%'],
    isDisabled,
  } = props
  const bottomSheetModalRef = useRef<BottomSheetModal>(null)
  const snapPoints = useMemo(() => snapPointsValue, [])

  const onOpenButtomSheet = () => {
    if (isDisabled) return
    bottomSheetModalRef.current?.present()
  }

  const onConfirmPress = () => {
    bottomSheetModalRef.current?.close()
  }

  return (
    <View>
      <Pressable
        onPress={onOpenButtomSheet}
        style={[
          styles.textInputContainer,
          textInputStyle,
          { borderColor: !!errorMsg ? 'red' : themeColors.inputBoarder },
        ]}
      >
        <TextInput
          onPressIn={onOpenButtomSheet}
          style={styles.input}
          placeholder={placeholder}
          editable={false}
          value={value}
        />
        <Ionicons name="chevron-down-outline" size={24} color={themeColors.gray500} />
      </Pressable>
      {!!errorMsg && <Text style={styles.errorText}>{errorMsg}</Text>}
      <BaseBottomSheetModal
        snapPoints={snapPoints}
        onDismiss={onConfirmPress}
        bottomSheetModalRef={bottomSheetModalRef}
      >
        <View style={{ paddingVertical: 10, paddingHorizontal: 16 }}>
          <Text style={{ fontSize: themeFontSizes.lg, fontWeight: '500' }}>{title}</Text>
        </View>

        <ScrollView style={{ paddingHorizontal: 16, marginBottom: 20 }}>
          {lists?.map((item, index) => {
            return (
              <Pressable
                key={index}
                style={{ paddingVertical: 10 }}
                onPress={() => {
                  handleSelect(item)
                  onConfirmPress()
                  setErrorState('')
                }}
              >
                <Text>{item?.description ?? item?.name}</Text>
              </Pressable>
            )
          })}
        </ScrollView>
      </BaseBottomSheetModal>
    </View>
  )
}

const styles = StyleSheet.create({
  textInputContainer: {
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: themeColors.white,
    paddingHorizontal: 16,
    borderRadius: 2,
    borderColor: themeColors.inputBoarder,
  },

  input: {
    flex: 1,
    backgroundColor: 'transparent',
    color: '#424242',
    paddingVertical: 16,
  },

  errorText: {
    color: 'red',
    fontFamily: themeFontFamily.itcGaramondStd,
    fontSize: themeFontSizes.md,
    lineHeight: 24,
    fontWeight: '400',
  },
})
