import React, { useState } from 'react'
import { Control, FieldErrorsImpl, FieldValues } from 'react-hook-form'
import { View, Text, StyleSheet } from 'react-native'
import { ControlledInput } from '../../../components/controlled-input'
import { themeFontSizes } from '../../../themes'
import { themeFontFamily } from '../../../themes/theme-font-family'
import WeightUnitPicker from './weight-units-options'

type ShippingOptionProps = {
  control: Control<FieldValues, any>
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any
    }>
  >
  defaultWeightValue?: string
  weightInPounds?: boolean
  onChange?: () => void
}

export function PrepaidShippingOption(props: ShippingOptionProps) {
  const { control, errors, defaultWeightValue = '', weightInPounds = true } = props
  const [isWeightInPounds, setIsWeightInPounds] = useState(weightInPounds)

  return (
    <View style={styles.prepaidShippingContainer}>
      <Text style={styles.preparedShippingTitle}>How much does your item weigh?</Text>
      <Text style={styles.preparedShippingSubTitle}>
        Remember to add some weight for packaging.
      </Text>

      <ControlledInput
        required={true}
        placeholder="Item weight *"
        name={`${isWeightInPounds ? 'weightInPounds' : 'weightInOz'}`}
        control={control}
        error={Boolean(errors)}
        keyboardType="numeric"
        inputContainer={{ marginTop: 10, marginBottom: 10 }}
        defaultValue={defaultWeightValue}
        onChange={props.onChange}
      />
      <WeightUnitPicker onChange={setIsWeightInPounds} />
    </View>
  )
}

const styles = StyleSheet.create({
  prepaidShippingContainer: {
    paddingVertical: 16,
  },

  preparedShippingTitle: {
    fontSize: themeFontSizes.lg,
    lineHeight: 22,
    fontFamily: 'chalet',
    fontWeight: '400',
  },

  preparedShippingSubTitle: {
    fontFamily: themeFontFamily.itcGaramondStd,
    fontSize: themeFontSizes.md,
    fontWeight: '300',
    lineHeight: 20,
    opacity: 0.8,
  },
})
