import React, { useState } from 'react'
import { StyleSheet, Text, TextInput, View } from 'react-native'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { themeColors, themeFontSizes } from '../../themes'
import { BaseButton } from '../../components/base-button'
import { sendPasswordResetEmail } from 'firebase/auth'
import { auth } from '../../config/firebase'
import { SuccessAlertModal } from './components/success-alert-modal'

const forgotPasswordScheme = yup.object().shape({
  email: yup.string().required().email(),
})

interface FormData {
  email: string
}

export function ForgotPasswordScreen() {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [title, setTitle] = useState<string>('')

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<FormData>({
    resolver: yupResolver(forgotPasswordScheme),
  })

  // firebase reset password
  const onSubmit = async (data: FormData) => {
    setIsLoading(true)
    try {
      const response = await sendPasswordResetEmail(auth, data.email)
      setIsLoading(false)
      setIsVisible(true)
      setTitle('Email sent')
      setMessage('We have sent you an email with instructions to reset your password.')
    } catch (error) {
      setIsLoading(false)
      setIsVisible(true)
      setTitle('Error')
      setMessage('Something went wrong. Please try again.')
    }
  }

  return (
    <View style={styles.container}>
      <View
        style={{
          paddingBottom: 16,
        }}
      >
        <Text
          style={{
            fontFamily: 'chalet',
            fontWeight: '600',
            fontSize: themeFontSizes.lg,
            lineHeight: 24,
            color: themeColors.black,
          }}
        >
          Reset password
        </Text>
        <Text
          style={{
            fontFamily: 'chalet',
            fontWeight: '400',
            fontSize: themeFontSizes.md,
            lineHeight: 18,
            color: themeColors.black,
            width: '90%',
            marginTop: 8,
          }}
        >
          Enter the email associated with your account and we'll send an email with instruction to
          reset your password.
        </Text>
      </View>
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <View style={styles.textInputStyle}>
            <TextInput
              style={[
                styles.input,
                {
                  fontSize: themeFontSizes.md,
                  color: themeColors.placeHolderColor,
                  fontFamily: 'chalet',
                  fontWeight: '400',
                  lineHeight: 14,
                },
              ]}
              placeholder="Email address"
              onChangeText={(value: string) => {
                onChange(value)
              }}
              placeholderTextColor={themeColors.placeHolderColor}
            />
          </View>
        )}
        name="email"
        rules={{ required: true }}
      />

      <View>
        <BaseButton
          onPress={handleSubmit(onSubmit)}
          isLoading={isLoading}
          title="Log in"
          backgroundColor={themeColors.black}
          marginBottom={16}
          marginTop={16}
        />
      </View>

      <SuccessAlertModal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        title={title}
        message={message}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: themeColors.appBackground,
    paddingTop: 16,
    paddingHorizontal: 16,
  },

  textInputStyle: {
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: themeColors.white,
    paddingHorizontal: 16,
    borderRadius: 2,
    borderColor: themeColors.inputBoarder,
  },

  input: {
    flex: 1,
    backgroundColor: 'transparent',
    height: 46,
  },
})
