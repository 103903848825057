import React, { useContext, useState } from 'react'
import { StyleSheet, Text, View, TextInput } from 'react-native'
import { TransactionContext } from '../context/TransactionProvider'
import { themeColors, themeFontSizes } from '../themes'
import { BaseButton } from './base-button'
import Modal from 'react-native-modal'

export const TwoFactorModal = ({
  modalVisible,
  setModalVisible,
}: {
  modalVisible: boolean
  setModalVisible: (e: boolean) => void
}) => {
  const { transactions } = useContext(TransactionContext)
  const [code, setCode] = useState<string>('')

  return (
    <View style={{ flex: 1 }}>
      <Modal isVisible={modalVisible}>
        <View
          style={{
            flex: 1,
            backgroundColor: 'white',
            marginVertical: 150,
            paddingHorizontal: 20,
            paddingVertical: 24,
          }}
        >
          <View>
            <Text style={styles.title}>2FA required </Text>
            <Text style={styles.subTitle}>Enter the verification code sent by.</Text>
          </View>
          {transactions
            .filter(({ RequestType, Platform }) => RequestType === 'mfa')
            .map((transaction, index) => {
              console.log(transaction)
              return (
                <View key={index}>
                  <View style={styles.textInputStyle}>
                    <TextInput
                      style={styles.input}
                      placeholder="Verification code"
                      onChangeText={(value: string) => {
                        setCode(value)
                      }}
                    />
                  </View>
                  <BaseButton
                    onPress={() => {
                      transaction.sendResponse(code)
                      console.log({ code })
                      setModalVisible(false)
                    }}
                    title="Submit"
                    backgroundColor={themeColors.black}
                    color={themeColors.white}
                  />
                </View>
              )
            })}
        </View>
      </Modal>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  title: {
    textAlign: 'center',
    fontSize: themeFontSizes['2xl'],
  },

  subTitle: {
    textAlign: 'center',
    fontSize: themeFontSizes.md,
    color: themeColors.gray500,
    marginTop: 14,
    marginBottom: 30,
  },

  textInputStyle: {
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: themeColors.gray100,
    paddingHorizontal: 16,
    borderRadius: 25,
    borderColor: themeColors.gray100,
  },

  input: {
    flex: 1,
    backgroundColor: 'transparent',
    color: '#424242',
    paddingVertical: 16,
  },
})
