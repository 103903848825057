export const themeColors = {
  tabBarBacground: '#FE306A',
  primary: '#FE306A',
  tabBarActiveColor: '#FF97B4',
  white: 'white',
  secondary: '#477DFF',
  logoText: '#AB2862',
  gray100: '#F4F4F4',
  gray200: '#DBDCDC',
  gray300: '#777878',
  gray400: '#DADADA',
  gray500: '#85888A',
  grey800: '#191919',
  link: '#477DFF',
  black: '#000000',
  appBackground: '#F5F5F5',
  inputBoarder: '#E8E8E8',
  placeHolderColor: '#777878',
  soldBgBadge: '#FF6D4B',
  offerBgBadge: '#DCFC73',
  listedBgBadge: '#E1D2F3',
  carouselIndicator: '#F9F8F3',
  red100: '#FFEFEC',
  red200: '#FF6D4B',
  darkBlue: '#000229',
  blue: '#054FF0',
}
