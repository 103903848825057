import {
  ActivityIndicator,
  ButtonProps,
  GestureResponderEvent,
  Pressable,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import React, { ReactElement, ReactNode } from 'react'
import { themeColors, themeFontSizes } from '../themes'
import { themeFontFamily } from '../themes/theme-font-family'

interface BaseButtonType extends ButtonProps {
  title: string
  isLoading?: boolean
  onPress?: ((event: GestureResponderEvent) => void) | undefined
  backgroundColor: string
  marginTop?: string | number
  marginBottom?: string | number
  isDisabled?: boolean
  color?: string
  hasLeftIcon?: boolean
  leftIcon?: JSX.Element | ReactElement
}

export function BaseButton(props: BaseButtonType) {
  const {
    isLoading,
    onPress,
    backgroundColor = themeColors.primary,
    title,
    marginTop = 16,
    marginBottom = 24,
    isDisabled,
    color = themeColors.white,
    hasLeftIcon,
    leftIcon,
    ...rest
  } = props

  if (isLoading || isDisabled) {
    return (
      <Pressable
        disabled={true}
        onPress={onPress}
        style={[
          styles.button,
          {
            backgroundColor: themeColors.gray200,
            marginTop,
            marginBottom,
            borderColor: themeColors.inputBoarder,
          },
        ]}
        {...rest}
      >
        {isLoading ? (
          <ActivityIndicator size={25} color={themeColors.white} />
        ) : (
          <Text style={[styles.buttonText, { color }]}>{title}</Text>
        )}
      </Pressable>
    )
  }
  return (
    <Pressable
      disabled={isLoading}
      onPress={onPress}
      style={[
        styles.button,
        {
          backgroundColor,
          marginTop,
          marginBottom,
          flexDirection: 'row',
          alignItems: 'center',
        },
      ]}
      {...rest}
    >
      {hasLeftIcon && (
        <View
          style={{
            marginRight: 10,
          }}
        >
          {leftIcon}
        </View>
      )}
      <Text style={[styles.buttonText, { color }]}>{title}</Text>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  button: {
    height: 52,
    justifyContent: 'center',
    borderRadius: 6,
    borderWidth: 1,
    borderColor: themeColors.inputBoarder,
  },

  buttonText: {
    textTransform: 'capitalize',
    color: themeColors.white,
    textAlign: 'center',
    fontWeight: '500',
    fontSize: themeFontSizes.md,
    fontFamily: themeFontFamily.medium,
    marginTop: 3,
  },
})
