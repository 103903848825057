import { View, Text, StyleSheet, Pressable } from 'react-native'
import React, { useContext, useMemo, useRef, useState } from 'react'
import { themeColors, themeFontSizes } from '../../../themes'
import { themeFontFamily } from '../../../themes/theme-font-family'
import { ProductDetail } from '../../product-detail/components/product-detail'
import { goToMyCloset } from '../../../service/navigation-service'
import { DepopListings } from './depop-listings'
import { useGetDepopListings } from '../../../hooks/use-get-depop-listings'
import { supportedMarketplacesType } from '../../../utils/types'
import { doc, DocumentData, getDoc, getFirestore } from 'firebase/firestore'
import { AuthContext } from '../../../context/auth-context'
import { BottomSheetModal } from '@gorhom/bottom-sheet'

type DepopListingsSectionProps = {
  profilePlatforms: supportedMarketplacesType[]
  isLoadingPlatform: boolean
  ready: boolean
}
export const DepopListingsSection = (props: DepopListingsSectionProps) => {
  const { profilePlatforms, isLoadingPlatform, ready } = props
  const { listings, loading, setLoading } = useGetDepopListings()

  return (
    <View>
      <View style={styles.section}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Text style={styles.sectionTitleItalics}>Depop</Text>
          <Text style={styles.sectionTitle}>items</Text>
        </View>

        <Pressable onPress={goToMyCloset} style={styles.viewMore}>
          <Text style={styles.seeAllText}>View all</Text>
        </Pressable>
      </View>
      <View>
        <DepopListings
          listings={listings}
          ready={ready}
          isLoadingItems={loading}
          profilePlatforms={profilePlatforms}
          isLoadingPlatform={isLoadingPlatform}
        />
      </View>

      {/* <ProductDetail
        item={listing}
        isLoadingItem={loading}
        onConfirmPress={onConfirmPress}
        bottomSheetModalRef={bottomSheetModalRef}
        snapPoints={snapPoints}
      /> */}
    </View>
  )
}

const styles = StyleSheet.create({
  section: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingVertical: 16,
  },

  viewMore: {
    borderBottomWidth: 1,
    borderColor: themeColors.gray300,
    paddingBottom: 2,
  },

  seeAllText: {
    fontSize: themeFontSizes.md,
    color: themeColors.black,
    fontWeight: '400',
    fontFamily: 'chalet',
    lineheight: 17,
    // textDecorationLine: 'underline'
  },

  sectionTitle: {
    fontSize: themeFontSizes.lg,
    color: themeColors.black,
    fontWeight: '500',
    marginStart: 5,
    opacity: 0.8,
    fontFamily: 'chalet',
  },

  sectionTitleItalics: {
    fontSize: themeFontSizes.lg,
    color: themeColors.black,
    fontWeight: '300',
    fontFamily: themeFontFamily.itcGaramondStd,
    fontStyle: 'italic',
    opacity: 0.8,
  },

  marketplaceLinkText: {
    fontSize: themeFontSizes.sm,
    lineHeight: 14,
    color: themeColors.black,
    fontWeight: '400',
    fontFamily: 'chalet',
  },

  noItemTitle: {
    fontSize: themeFontSizes.lg,
    lineHeight: 14,
    color: themeColors.black,
    fontWeight: '400',
    fontFamily: 'chalet',
    opacity: 0.8,
    marginBottom: 8,
  },

  noItemContent: {
    fontFamily: themeFontFamily.itcGaramondStd,
    fontWeight: '300',
    fontSize: themeFontSizes.md,
    lineHeight: 20,
    color: themeColors.black,
    opacity: 0.8,
  },
})
