import { useContext, useEffect, useState } from 'react'
import { View } from 'react-native'
import { TransactionContext } from '../context/TransactionProvider'
import { TwoFactorModal } from './two-factor-modal'

export const TwoFactorErrorHandler = () => {
  const { transactions } = useContext(TransactionContext)
  const [modalVisible, setModalVisible] = useState(false)
  const containsMFA = transactions.some((obj) => obj.RequestType === 'mfa')

  useEffect(() => {
    if (transactions.length > 0 && containsMFA) {
      setModalVisible(true)
    }
  }, [transactions])
  // if user is not in auth screen show this..
  // transactions.filter(({RequestType, Platform}) => RequestType === "mfa").map((transaction) => {
  //   console.log(transaction);
  //   // return <TwoFactorError platform={Platform} />
  // });

  return (
    <View>
      {modalVisible && (
        <TwoFactorModal modalVisible={modalVisible} setModalVisible={setModalVisible} />
      )}
    </View>
  )
}
