import MixpanelAnalytics from '@benawad/expo-mixpanel-analytics'
import Constants from 'expo-constants'

const analytics = new MixpanelAnalytics(Constants.manifest?.extra?.mixpanelToken)

export const initMixPanel = () => {
  return analytics
}

// todo this events is not in use for now it is for button events
export const track = (trackId: string, data?: any) => {
  console.log('trackId', trackId)

  analytics.track(trackId as string, data)
}

// todo this events is not in use for now it is for button events
export const registerUser = (data: any) => {
  analytics.register(data)
}
