import { View, Text, StyleSheet, Pressable, ActivityIndicator, Image } from 'react-native'
import React from 'react'
import { themeColors, themeFontSizes } from '../../../themes'
import { supportedMarketplacesType } from '../../../utils/types'
import { Ionicons } from '@expo/vector-icons'
import { marketPlaceMap } from '../../home/component/connected-marketplace'
import { DocumentData } from 'firebase/firestore'

type ProfilePlatformTypes = {
  handleOpenPlatformLogin: (id: string, platformName: string) => void
  platform: supportedMarketplacesType | DocumentData | undefined
  isLoadingPlatformLogin: boolean
  selectedMarketplace: boolean
}
export const ProfilePlatform = (props: ProfilePlatformTypes) => {
  const { handleOpenPlatformLogin, platform, isLoadingPlatformLogin, selectedMarketplace } = props
  // console.log(platform)

  if (!platform?.ready) {
    return (
      <Pressable
        style={styles.platformContainer}
        onPress={() => handleOpenPlatformLogin(platform?.id, platform?.name)}
      >
        <View style={styles.platformWrapper}>
          <View>
            {/* @ts-ignore */}
            <Image
              source={marketPlaceMap[platform?.name]}
              style={{ borderRadius: 24, width: 30, height: 30 }}
            />
          </View>
          <View style={styles.platformInfo}>
            <Text style={styles.platformName}>{platform?.name}</Text>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Ionicons name="close-outline" size={24} color={themeColors.primary} />
              <Text style={styles.linkText}>Not Linked</Text>
            </View>
          </View>
        </View>
        {isLoadingPlatformLogin && selectedMarketplace ? (
          <View>
            <ActivityIndicator />
          </View>
        ) : (
          <Ionicons name="arrow-forward-outline" size={24} />
        )}
      </Pressable>
    )
  }

  return (
    <Pressable
      style={styles.platformContainer}
      onPress={() => handleOpenPlatformLogin(platform.id, platform.name)}
    >
      <View style={styles.platformWrapper}>
        <View>
          {/* @ts-ignore */}
          <Image
            source={{ uri: marketPlaceMap[platform?.name] }}
            style={{ borderRadius: 24, width: 30, height: 30 }}
          />
        </View>
        <View style={styles.platformInfo}>
          <Text style={styles.platformName}>{platform?.name}</Text>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Ionicons name="checkmark-outline" size={24} color={themeColors.secondary} />
            <Text style={styles.linkText}>Linked</Text>
          </View>
        </View>
      </View>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  platformWrapper: {
    flexDirection: 'row',
  },

  platformContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1.5,
    borderColor: themeColors.gray400,
    paddingVertical: 17,
  },

  platformInfo: {
    paddingLeft: 16,
  },

  platformName: {
    fontWeight: '400',
    fontSize: themeFontSizes.md,
    color: themeColors.darkBlue,
    letterSpacing: 0.08,
    lineHeight: 20,
    fontFamily: 'chalet',
    textTransform: 'capitalize',
  },

  linkText: {
    fontWeight: '400',
    fontSize: themeFontSizes.md,
    color: themeColors.gray300,
    letterSpacing: 0.08,
    lineHeight: 20,
    fontFamily: 'chalet',
  },
})
