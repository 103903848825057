import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  ActivityIndicator,
  Pressable,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  View,
  Platform
} from 'react-native'
import { gotoForgotPasswordScreen, goToSignUpScreen } from '../../service/navigation-service'
import { GoogleLogoSvgIcon, themeColors, themeFontSizes } from '../../themes'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { GoogleAuthProvider, signInWithEmailAndPassword, signInWithCredential } from 'firebase/auth'
import { auth } from '../../config/firebase'
import { AuthContext } from '../../context/auth-context'
import { BaseButton } from '../../components/base-button'
import { useGetUserProfile } from '../../hooks/use-get-user-profile'
import { registerUser, track } from '../../utils/mix-panel-utils'
import { configValue } from '../../config/config-values'
import * as Google from 'expo-auth-session/providers/google'
import * as WebBrowser from 'expo-web-browser'
import { FaceBookAuth } from './components/facebook-auth'
import { Logo } from '../../icons/logo'
import { themeFontFamily } from '../../themes/theme-font-family'
import { AppUserContext } from '../../context/app-user-context'
import {AppleAuth} from "./components/apple-auth";

const loginScheme = yup.object().shape({
  email: yup.string().required().email(),
  password: yup.string().required(),
})

interface FormData {
  email: string
  password: string
}

WebBrowser.maybeCompleteAuthSession()

// @Todo, add a loader for social auth.
export function LoginScreen() {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { setAuthState } = useContext(AuthContext)
  const [loadingSocialAuth, setLoadingSocialAuth] = useState<boolean>(false)
  const { setUserState, userState } = useContext(AppUserContext)

  const [request, response, promptAsync] = Google.useIdTokenAuthRequest({
    clientId: configValue.clientId,
    webClientId: configValue.webClientId,
  })

  useEffect(() => {
    track('login_screen')
  }, [])

  useEffect(() => {
    if (response?.type === 'success') {
      const { id_token } = response.params
      const credential = GoogleAuthProvider.credential(id_token)
      setLoadingSocialAuth(true)
      signInWithCredential(auth, credential)
        .then(({ user }) => {
          if (user) {
            // make api call to validate token
            fetch(`${configValue.apiHost}/validateToken`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                // @ts-ignore
                Authorization: `${user.stsTokenManager.accessToken}`,
              },
            })
              .then(async (response) => {
                const res = await response.json()
                if (res.message === 'success') {
                  // @ts-ignore
                  setAuthState({
                    token: user.stsTokenManager.accessToken,
                    email: user.email,
                    isLoggedIn: true,
                    userId: user.uid,
                    profileImage: { uri: user.photoURL },
                    name: user.displayName,
                  })
                  // get user
                  // setMountUser(Math.random());
                  setUserState({ user: { email: user.email, isLoggedIn: true, userId: user.uid } })
                  track('google_login_success')
                  registerUser({ email: user?.email })
                  setLoadingSocialAuth(false)
                  return
                }
              })
              .catch((error) => {
                setLoadingSocialAuth(false)
                track('google_login_error')
                console.log('error: ', error)
              })
          }
        })
        .catch((error) => {
          console.log('--error--', error)
          setLoadingSocialAuth(false)
          alert(error?.message)
          track('google_login_error', error?.message)
          return error
        })
    }
  }, [response])

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<FormData>({
    resolver: yupResolver(loginScheme),
  })

  //FORM SUBMISTION
  const onSubmit = useCallback((data: FormData) => {
    const { email, password } = data
    setIsLoading(true)
    signInWithEmailAndPassword(auth, email, password)
      .then(({ user }) => {
        if (user) {
          // make api call to validate token
          fetch(`${configValue.apiHost}/validateToken`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              // @ts-ignore
              Authorization: `${user.stsTokenManager.accessToken}`,
            },
          })
            .then(async (response) => {
              const res = await response.json()
              if (res.message === 'success') {
                // @ts-ignore
                setAuthState({
                  token: user.stsTokenManager.accessToken,
                  email: user.email,
                  isLoggedIn: true,
                  userId: user.uid,
                })
                // get user
                // setMountUser(Math.random());
                setUserState({ user: { email: user.email, isLoggedIn: true, userId: user.uid } })
                track('email_login_success')
                registerUser({ email: user?.email })
                setIsLoading(false)
                return
              }
            })
            .catch((error) => {
              setIsLoading(false)
              console.log('error: ', error)
            })
        }
      })
      .catch((error) => {
        console.log('--error--', error)
        setIsLoading(false)
        alert(error?.message)
        track('email_login_error', error?.message)
        return error
      })
  }, [])


  return (
    <SafeAreaView>
      {/* <KeyboardAvoidingView behavior={'padding'} style={styles.container}> */}
      <ScrollView
        style={styles.container}
        showsVerticalScrollIndicator={false}
        keyboardDismissMode={'interactive'}
      >
        <View style={styles.logoWrapper}>
          <View style={{ borderBottomWidth: 1.5, paddingBottom: 10 }}>
            <Logo />
          </View>

          <View style={{ marginTop: 16, width: '60%' }}>
            <Text
              style={{
                fontSize: themeFontSizes.lg,
                lineHeight: 38,
                fontWeight: '500',
                fontFamily: 'Druk-medium',
                textTransform: 'uppercase',
                opacity: 0.8,
              }}
            >
              Welcome Back
            </Text>
          </View>

          <View style={styles.socialsWrapper}>
            <FaceBookAuth
              isLogin={true}
              setUserState={setUserState}
              setLoadingSocialAuth={setLoadingSocialAuth}
              trackId={'facebook_login'}
            />
            <BaseButton
              onPress={() => promptAsync()}
              marginTop={0}
              title="Countinue with Google"
              color={themeColors.black}
              backgroundColor={themeColors.white}
              hasLeftIcon={true}
              leftIcon={<GoogleLogoSvgIcon />}
              marginBottom={16}
            />
            {
              Platform.OS === 'ios' && (
                  <AppleAuth isLogin={true} setUserState={setUserState} setLoadingSocialAuth={setLoadingSocialAuth} trackId={'apple_login'}/>
              )
            }

            <View style={{ flexDirection: 'row', alignItems: 'center', paddingBottom: 12 }}>
              <View style={{ flex: 1, height: 1, backgroundColor: themeColors.gray300 }} />
              <View>
                <Text
                  style={{
                    width: 50,
                    textAlign: 'center',
                    fontFamily: themeFontFamily.itcGaramondStd,
                    fontSize: themeFontSizes.md,
                    color: themeColors.gray300,
                  }}
                >
                  OR
                </Text>
              </View>
              <View style={{ flex: 1, height: 1, backgroundColor: themeColors.gray300 }} />
            </View>
          </View>

          <View style={[styles.form]}>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <View style={styles.textInputStyle}>
                  <TextInput
                    style={[
                      styles.input,
                      {
                        fontSize: themeFontSizes.md,
                        color: themeColors.placeHolderColor,
                        fontFamily: 'chalet',
                        fontWeight: '400',
                        lineHeight: 14,
                      },
                    ]}
                    placeholder="Email address"
                    onChangeText={(value: string) => {
                      onChange(value)
                    }}
                    placeholderTextColor={themeColors.placeHolderColor}
                  />
                  {/* <Ionicons name="mail-outline" size={24} /> */}
                </View>
              )}
              name="email"
              rules={{ required: true }}
            />

            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <View style={[styles.textInputStyle, { marginTop: 16 }]}>
                  <TextInput
                    style={[
                      styles.input,
                      {
                        fontSize: themeFontSizes.md,
                        color: themeColors.placeHolderColor,
                        fontFamily: 'chalet',
                        fontWeight: '400',
                        lineHeight: 14,
                      },
                    ]}
                    placeholder="Password"
                    secureTextEntry
                    onChangeText={(value: string) => {
                      onChange(value)
                    }}
                    placeholderTextColor={themeColors.placeHolderColor}
                  />
                  {/* <Ionicons name="lock-closed-outline" size={24} /> */}
                </View>
              )}
              name="password"
              rules={{ required: true }}
            />

            <Pressable style={{ paddingVertical: 16 }} onPress={gotoForgotPasswordScreen}>
              <Text style={styles.forgotPasswordText}>Forgot your password?</Text>
            </Pressable>
          </View>
        </View>

        <View style={styles.buttonContainer}>
          <BaseButton
            onPress={handleSubmit(onSubmit)}
            isLoading={isLoading}
            title="Log in"
            backgroundColor={themeColors.black}
            marginBottom={16}
            marginTop={0}
          />

          <BaseButton
            onPress={goToSignUpScreen}
            marginTop={0}
            title="Create account"
            color={themeColors.black}
            backgroundColor={themeColors.white}
          />
        </View>
      </ScrollView>
      {loadingSocialAuth && (
        <View style={styles.loading}>
          <ActivityIndicator size="large" color={themeColors.white} />
        </View>
      )}
      {/* </KeyboardAvoidingView> */}
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    backgroundColor: themeColors.appBackground,
    paddingTop: 16,
    paddingHorizontal: 16,
  },

  logoWrapper: {
    flexBasis: 450,
    flexGrow: 0,
    flexShrink: 1,
  },

  logoContainer: {
    width: '52%',
    borderRadius: 15,
    paddingHorizontal: 6,
    paddingTop: 54,
  },

  title: {
    textAlign: 'center',
    fontSize: themeFontSizes['2xl'],
  },

  socialsWrapper: {
    paddingTop: 16,
  },

  form: {
    width: '100%',
  },

  textInputStyle: {
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: themeColors.white,
    paddingHorizontal: 16,
    borderRadius: 2,
    borderColor: themeColors.inputBoarder,
  },

  input: {
    flex: 1,
    backgroundColor: 'transparent',
    height: 46,
  },

  forgotPasswordText: {
    color: themeColors.black,
    textDecorationLine: 'underline',
    fontStyle: 'italic',
    fontFamily: 'itc-garamond-std',
    fontWeight: '300',
  },

  buttonContainer: {
    flexBasis: 130,
    flexGrow: 1,
    flexShrink: 0,
    justifyContent: 'flex-end',
  },

  loading: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
})
