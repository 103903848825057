import { createStackNavigator } from '@react-navigation/stack'
import { useContext } from 'react'
import { AuthTabNav } from './auth-stack-navigation'
import { BottomTabNavigator } from './bottom-tab-navigation'
import { useAuthentication } from '../hooks/use-authentication'
import { AppUserContext } from '../context/app-user-context'
import { FtueContext } from '../context/ftue-context'
import { PlatformLoginScreen } from '../screen/account/platform-login-screen'

const RootNavigationStack = createStackNavigator()

export function RootNavigator() {
  const { userState } = useContext(AppUserContext)
  const { ftueState } = useContext(FtueContext)
  useAuthentication()

  // if ftue show create account
  // if (!userState.user && !!ftueState?.isFTUE) {
  //   return <CreateAccountScreen />
  // };

  // if user and ftue show platform login
  if (!!ftueState?.isFTUE) {
    // return platform login...
    return <PlatformLoginScreen />
  }

  return (
    <RootNavigationStack.Navigator>
      <RootNavigationStack.Screen
        name="Main"
        component={userState?.user ? BottomTabNavigator : AuthTabNav}
        options={{ headerShown: false }}
      />
    </RootNavigationStack.Navigator>
  )
}
