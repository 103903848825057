import Constants from 'expo-constants'

export type ConfigType = {
  env: string
  apiHost: string
  iosKey: string
  expoClientID: string
  clientId: string
  webClientId: string
  fbid: string
}

export function devConfig(): ConfigType {
  return {
    env: 'dev',
    apiHost: Constants.manifest?.extra?.apiBaseUrlDev,
    iosKey: Constants.manifest?.extra?.IOS_KEY,
    expoClientID: Constants.manifest?.extra?.expoClientID,
    clientId: Constants.manifest?.extra?.clientId,
    fbid: Constants.manifest?.extra?.fbid,
    webClientId: Constants.manifest?.extra?.webClientId,
  }
}

export function localConfig(): ConfigType {
  return {
    env: 'local',
    apiHost: Constants.manifest?.extra?.apiBaseUrlLocal,
    iosKey: Constants.manifest?.extra?.IOS_KEY,
    expoClientID: Constants.manifest?.extra?.expoClientID,
    clientId: Constants.manifest?.extra?.clientId,
    fbid: Constants.manifest?.extra?.fbid,
    webClientId: Constants.manifest?.extra?.webClientId,
  }
}

export function liveConfig(): ConfigType {
  return {
    env: 'live',
    apiHost: '',
    iosKey: '',
    expoClientID: '',
    clientId: '',
    fbid: '',
    webClientId: '',
  }
}

export function liveFirebaseConfig(): ConfigType {
  return {
    env: 'live',
    apiHost: '',
    iosKey: '',
    expoClientID: '',
    clientId: '',
    fbid: '',
    webClientId: '',
  }
}

// add index.ts with the values you need.
export const configValue = devConfig()
