export const themeFontFamily = {
  bold: 'Druk-bold',
  medium: 'Druk-medium',
  mediumItalics: 'Druk-medium-italics',
  regular: 'Druk-regular',
  super: 'Druk-super',
  superItalics: 'Druk-super-italics',
  heavy: 'Druk-heavy',
  heavyItalics: 'Druk-heavy-italics',
  itcGaramondStd: 'itc-garamond-std',
}
