import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { View } from "react-native";
import { DepopContainerType, DepopDataType, DepopSectionType, GenericDepopFormType } from "./datatype";
import { DepopSection } from "./depop-section";

interface IDepopCreateStuff {
  sections: DepopContainerType[];
  depopFormData: (formData: GenericDepopFormType[]) => void;
};

function flattenDepopData(data: DepopDataType[][]): GenericDepopFormType[] {
  let newData: GenericDepopFormType[] = [];
  for (const section of data) {
    newData = newData.concat(flattenDepopDataType(section));
  }
  return newData;
}

function flattenDepopDataType(data: DepopDataType[]): GenericDepopFormType[] {
  let newData: GenericDepopFormType[] = [];
  data
    .filter((item) => item.id)
    .forEach((item) => {
      newData.push({
        id: item.id,
        value: item.value as string,
        type: item.type,
      });
      newData = newData.concat(flattenDepopDataType(item.selectedValues ?? []));
    });

  return newData;
}


export const DepopCreateStuff = ({sections: sections, depopFormData}: IDepopCreateStuff) => {
  const value = useRef<DepopDataType[][]>(new Array(sections.length).fill([]));

  const onChange = useCallback((_value: DepopDataType[], index: number) => {
    value.current[index] = _value;
    console.info("DEPOP FORM DATA", value.current);
    depopFormData(flattenDepopData(value.current));
  }, [value]);

  return (
    <View>
      {
        sections.map((section, index) =>  (
          <DepopSection onChange={(value) => onChange(value, index)} key={section.section} data={section as DepopContainerType}></DepopSection>
        ))
      }
  </View>);
};