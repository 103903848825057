import { View, Text, StyleSheet, Pressable, ActivityIndicator } from 'react-native'
import React from 'react'
import { supportedMarketplacesType } from '../../../utils/types'
import { themeColors, themeFontSizes } from '../../../themes'
//@ts-ignore
import MercariLogo from '../../../../assets/mercari-icon.png'
import DepopLogo from '../../../../assets/depop-logo.png'
import usePlatformStatus from '../../../hooks/usePlatformStatus'
import { mergeArrays } from '../../../utils/merge-arrays'

type MyStuffMarketplacesProps = {
  profilePlatforms: supportedMarketplacesType[]
  isLoadingPlatform: boolean
  setSelectedMarketplace: (marketplace: string) => void
  selectedMarketplace: string
}

export const marketPlaceMap = {
  Mercari: MercariLogo,
  depop: DepopLogo,
}

export const MyStuffMarketplaces = (props: MyStuffMarketplacesProps) => {
  const { profilePlatforms, isLoadingPlatform, setSelectedMarketplace, selectedMarketplace } = props
  const { data } = usePlatformStatus()
  const mergedData = mergeArrays(profilePlatforms, data)

  if (isLoadingPlatform) {
    return (
      <View>
        <View
          style={[
            styles.platformWrapper,
            {
              marginRight: 10,
              marginTop: 10,
            },
          ]}
        >
          <ActivityIndicator size="small" color={themeColors.gray300} />
          <Text style={[styles.plaformIsLoadingText]}>Loading...</Text>
        </View>
      </View>
    )
  }

  return (
    <View>
      <View style={styles.platformWrapper}>
        {mergedData?.map((platform, index) => {
          const isSelected = selectedMarketplace === platform?.id
          return (
            <View key={platform?.id}>
              {platform?.ready && (
                <Pressable
                  style={[
                    styles.platformNameContainer,
                    {
                      backgroundColor: isSelected ? themeColors.secondary : themeColors.white,
                    },
                  ]}
                  onPress={() => setSelectedMarketplace(platform?.id)}
                >
                  <Text
                    style={[
                      styles.platformNameText,
                      {
                        color: isSelected ? themeColors.white : themeColors.black,
                      },
                    ]}
                  >
                    {platform?.name}
                  </Text>
                </Pressable>
              )}
            </View>
          )
        })}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  platformNameContainer: {
    borderWidth: 1,
    borderColor: themeColors.black,
    borderRadius: 16,
    paddingHorizontal: 10,
    paddingVertical: 4,
    // width: '30%',
    marginRight: 10,
    marginTop: 10,
  },

  platformNameText: {
    fontSize: themeFontSizes.lg,
    fontFamily: 'chalet',
    fontWeight: '400',
    lineHeight: 22,
    textTransform: 'capitalize',
    textAlign: 'center',
  },

  platformWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  plaformIsLoadingText: {
    fontSize: themeFontSizes.sm,
    lineHeight: 14,
    color: themeColors.black,
    fontWeight: '400',
    fontFamily: 'chalet',
    marginLeft: 8,
  },
})
