import { createContext } from 'react'
import useTransaction, { Transaction } from '../hooks/useTransaction'

type contextType = {
  transactions: Transaction[]
}

const initialState = {
  transactions: [],
}

export const TransactionContext = createContext<contextType>(initialState as any)
export function TransactionProvider({ children }: { children: React.ReactNode }) {
  const transactions = useTransaction()

  return (
    <TransactionContext.Provider value={{ transactions }}>{children}</TransactionContext.Provider>
  )
}
