import { Image, Pressable, StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { themeColors, themeFontSizes } from '../../../themes'
import { themeFontFamily } from '../../../themes/theme-font-family'
import { Ionicons } from '@expo/vector-icons'
// todo MP icon doesn't return in listing data
//@ts-ignore
import mercariIcon from '../../../../assets/mercari-icon.png'
import { goProductDetail } from '../../../service/navigation-service'
import { DocumentData } from 'firebase/firestore'

enum SALES_STATUS {
  'SOLD' = 'sold',
  'ON_SALE' = 'on_sale',
  'OFFER' = 'offer',
}

type productCardType = {
  data: DocumentData
  layout?: 'list' | 'grid'
  platform: string
}

export const getSalesStatus = (status: string) => {
  switch (status) {
    case SALES_STATUS.SOLD:
      return { title: 'sold', bg: themeColors.soldBgBadge }
    case SALES_STATUS.ON_SALE:
      return { title: 'listed', bg: themeColors.listedBgBadge }
    case SALES_STATUS.OFFER:
      return { title: 'offer', bg: themeColors.offerBgBadge }
    default:
      return { title: 'on_sale', bg: themeColors.listedBgBadge }
  }
}
export function ProductCard(props: productCardType) {
  const { data, layout, platform } = props
  const { price, name, photos, numLikes, status } = data
  const formatedPrice = (Number(price) / 100).toFixed(2)

  if (layout === 'grid') {
    return (
      <Pressable
        onPress={() =>
          goProductDetail({ productId: data?.id ?? data?.itemId, product: data, platform })
        }
        style={[styles.container, { minWidth: 172, marginEnd: 0, height: 210 }]}
      >
        <View
          style={[
            styles.imageWrapper,
            {
              width: 172,
            },
          ]}
        >
          <Image style={styles.image} source={{ uri: photos[0]?.large }} />
        </View>
        {/* Note:: todo MP icon doesn't return in listing data */}
        <View style={styles.logoIcon}>
          <Image style={styles.logoImage} source={{ uri: mercariIcon }} />
        </View>

        <View
          style={{
            position: 'absolute',
            top: 10,
            left: 10,
            backgroundColor: getSalesStatus(status).bg,
            borderWidth: 1,
            padding: 6,
            borderRadius: 24,
          }}
        >
          <Text
            style={{
              fontSize: themeFontSizes.sm,
              fontWeight: '400',
              color: themeColors.black,
              fontFamily: 'chalet',
              lineHeight: 12,
              textTransform: 'uppercase',
              letterSpacing: 0.02,
            }}
          >
            {getSalesStatus(status).title}
          </Text>
        </View>
        <View style={styles.contentContainer}>
          <Text style={styles.price}>${formatedPrice}</Text>
          <Text style={styles.productName} numberOfLines={1}>
            {name}
          </Text>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Ionicons
              name={numLikes > 0 ? 'heart' : 'heart-outline'}
              size={16}
              color={themeColors.black}
            />
            <Text
              style={{
                fontSize: themeFontSizes.sm,
                fontWeight: '400',
                color: themeColors.black,
                fontFamily: 'chalet',
                lineHeight: 12,
                textTransform: 'uppercase',
                letterSpacing: 0.02,
                marginLeft: 4,
              }}
            >
              {numLikes}
            </Text>
          </View>
        </View>
      </Pressable>
    )
  }

  return (
    <Pressable
      onPress={() =>
        goProductDetail({ productId: data?.id ?? data?.itemId, product: data, platform })
      }
      style={[styles.container]}
    >
      <View style={styles.imageWrapper}>
        <Image style={styles.image} source={{ uri: photos[0]?.large }} />
      </View>
      {/* Note:: todo MP icon doesn't return in listing data */}
      <View style={styles.logoIcon}>
        <Image style={styles.logoImage} source={{ uri: mercariIcon }} />
      </View>

      <View
        style={{
          position: 'absolute',
          top: 10,
          left: 10,
          backgroundColor: getSalesStatus(status).bg,
          borderWidth: 1,
          padding: 6,
          borderRadius: 24,
        }}
      >
        <Text
          style={{
            fontSize: themeFontSizes.sm,
            fontWeight: '400',
            color: themeColors.black,
            fontFamily: 'chalet',
            lineHeight: 12,
            textTransform: 'uppercase',
            letterSpacing: 0.02,
          }}
        >
          {getSalesStatus(status).title}
        </Text>
      </View>
      <View style={styles.contentContainer}>
        <Text style={styles.price}>${formatedPrice}</Text>
        <Text style={styles.productName} numberOfLines={1}>
          {name}
        </Text>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Ionicons name={numLikes === 0 ? 'heart-outline' : 'heart'} size={16} />
          <Text style={styles.likes}>{numLikes} Likes</Text>
        </View>
      </View>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  container: {
    minWidth: 140,
    minHeight: 190,
    backgroundColor: themeColors.white,
    borderRadius: 6,
    marginEnd: 16,
    position: 'relative',
  },

  logoIcon: {
    position: 'absolute',
    width: 25,
    height: 25,
    borderRadius: 20,
    right: 10,
    top: 10,
  },

  logoImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
    borderRadius: 20,
  },

  imageWrapper: {
    width: 140,
    height: 124,
    // paddingBottom: 10,
  },

  image: {
    height: '100%',
    width: '100%',
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },

  price: {
    fontSize: themeFontSizes.lg,
    fontWeight: '400',
    color: themeColors.black,
    fontFamily: 'chalet',
    lineHeight: 20,
  },

  productName: {
    fontSize: themeFontSizes.md,
    fontWeight: '300',
    color: themeColors.black,
    fontFamily: themeFontFamily.itcGaramondStd,
    lineHeight: 20,
    maxWidth: 110,
  },

  contentContainer: {
    padding: 8,
  },

  likes: {
    fontSize: themeFontSizes.md,
    fontWeight: '400',
    color: themeColors.black,
    fontFamily: 'chalet',
    lineHeight: 20,
    marginStart: 5,
  },
})
