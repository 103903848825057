import React, { useCallback, useEffect, useRef, useState } from 'react';
import { View } from 'react-native'
import { DepopCheckboxType, DepopDataType, DepopInputType, DepopOptionType } from './datatype';
import { DepopDropDownInput } from './depop-dropdown-input';
import { DepopCheckbox } from './depop-checkbox';
import { DepopTextInput } from './depop-text-input';

type IDepopInput = {
  input: DepopInputType;
  onChange: (value: DepopDataType) => void;
};

export const DepopInput = ({input, onChange}: IDepopInput) => {
  const value = useRef<DepopDataType>();
  console.info("Rerendering DepopInput", value.current, input.id);

  const _onChange = useCallback((newValue: DepopDataType) => {
    value.current = newValue;
    onChange(newValue);
  }, [value]);

  return (
    <View>
      {
        !input.type &&
          <DepopDropDownInput
            {...input}
            onChange={_onChange}
            snapPointsValue={["80%"]}
            placeholder={`Select ${input.label}*`}
            textInputStyle={{ marginTop: 10 }}
          />
      }
      {
        input.type === "number" &&
          <DepopTextInput
          {...input}
          onChange={_onChange}
          style={{ height: 40, borderColor: 'gray', borderWidth: 1 }}
          option={input}
        />
      }
      {
        input.type === "text" && !input.isList &&
          <DepopTextInput
            {...input}
            onChange={_onChange}
            option={input}
            style={{ height: 40, borderColor: 'gray', borderWidth: 1 }}
          />
      }
      {
        input.type === 'checkbox' &&
          <DepopCheckbox
            {...input}
            onChange={_onChange}
            option={input as DepopCheckboxType}
            label={input.label}
            id={input.id}
            default={input.default}
            options={[]}
            isList={false}
          />
      }
    </View>
  );
};
