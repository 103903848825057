import Modal from 'react-native-modal'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { themeColors, themeFontSizes } from '../../../themes'
import { BaseButton } from '../../../components/base-button'
import { themeFontFamily } from '../../../themes/theme-font-family'
import { goToLoginScreen } from '../../../service/navigation-service'

type SuccessAlertModalType = {
  title: string
  message: string
  isVisible: boolean
  setIsVisible: (value: boolean) => void
}

export function SuccessAlertModal(props: SuccessAlertModalType) {
  const { title, message, isVisible, setIsVisible } = props

  return (
    <View style={{ flex: 1 }}>
      <Modal isVisible={isVisible}>
        <View style={styles.container}>
          <View style={styles.titleWrapper}>
            <Text style={styles.title}>{title}</Text>
          </View>

          <View style={styles.messageWrapper}>
            <Text style={styles.message}>{message}</Text>
          </View>
          <View
            style={{
              flex: 1,
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <BaseButton
              onPress={() => {
                setIsVisible(false)
                goToLoginScreen()
              }}
              marginTop={16}
              marginBottom={0}
              title="Continue"
              color={themeColors.black}
              backgroundColor={themeColors.white}
            />
          </View>
        </View>
      </Modal>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    marginVertical: 180,
    paddingHorizontal: 20,
    paddingVertical: 24,
  },

  titleWrapper: {},

  title: {
    textAlign: 'center',
    fontSize: themeFontSizes.xl,
    fontFamily: themeFontFamily.regular,
  },

  messageWrapper: {
    paddingVertical: 16,
  },

  message: {
    fontSize: themeFontSizes.lg,
    color: themeColors.black,
    fontFamily: 'chalet',
    lineHeight: 22,
  },
})
