import { Image, Platform, Pressable, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React, { useMemo, useRef } from 'react'
import { BottomSheetModal } from '@gorhom/bottom-sheet'
import { BaseBottomSheetModal } from '../../../components/base-bottom-sheet'
import { BaseButton } from '../../../components/base-button'
import { themeColors, themeFontSizes } from '../../../themes'
import * as ImagePickers from 'expo-image-picker'
import { Ionicons } from '@expo/vector-icons'
import { themeFontFamily } from '../../../themes/theme-font-family'

type ProductImageUploadType = {
  setProductImages: (value: ImagePickers.ImagePickerAsset[]) => void
  productImages: (ImagePickers.ImagePickerAsset & { large?: string })[]
  imageErrorMsg: string
  setErrorState: (value: string) => void
  setHasImageChanged: (value: boolean) => void
}

export function ProductImageUpload(props: ProductImageUploadType) {
  const { setProductImages, productImages, imageErrorMsg, setErrorState, setHasImageChanged } =
    props
  const isWeb = Platform.OS === 'web'
  const snapPointValue = isWeb ? '50%' : '40%'

  const bottomSheetModalRef = useRef<BottomSheetModal>(null)
  const snapPoints = useMemo(() => [snapPointValue], [])

  const onOpenButtomSheet = async () => {
    if (Platform.OS === 'web') {
      try {
        let result = await ImagePickers.launchImageLibraryAsync({
          mediaTypes: ImagePickers.MediaTypeOptions.Images,
          allowsEditing: true,
        })
        if (!result.canceled) {
          console.info('FILENAME', result.assets[0].fileName)
        }
        const mappedAssets = await assetToBlob(result?.assets ?? [])
        setProductImages([...productImages, ...mappedAssets])
        setHasImageChanged(true)
        setErrorState('')
      } catch (error) {
        console.log('---', error)
        setHasImageChanged(false)
        return error
      }

      return
    }
    bottomSheetModalRef.current?.present()
  }

  const onConfirmPress = () => {
    bottomSheetModalRef.current?.close()
  }

  const openCamera = async () => {
    const formdata = new FormData()
    onConfirmPress()
    try {
      let result: ImagePickers.ImagePickerResult = await ImagePickers.launchCameraAsync()

      if (!result.canceled) {
        setProductImages([...productImages, ...(result?.assets ?? [])])
        setHasImageChanged(true)
      }
    } catch (error) {
      console.log('---', error)
      setHasImageChanged(false)
      return error
    }
  }
  const assetToBlob = async (assets: ImagePickers.ImagePickerAsset[]) => {
    return await Promise.all(
      assets.map(async (asset) => {
        const blob = await fetch(asset.uri).then((res) => res.blob())
        const fn = `${Date.now()}.${blob.type.split('/')[1]}`
        const newAsset: ImagePickers.ImagePickerAsset = {
          ...asset,
          fileName: fn,
        }
        console.info('FILENAME', newAsset, newAsset.fileName)
        return newAsset
      }) ?? [],
    )
  }
  const openMediaLibrary = async () => {
    onConfirmPress()
    try {
      let result = await ImagePickers.launchImageLibraryAsync({
        mediaTypes: ImagePickers.MediaTypeOptions.Images,
        allowsEditing: true,
      })
      if (!result.canceled) {
        console.info('FILENAME', result.assets[0].fileName)
      }
      const mappedAssets = await assetToBlob(result?.assets ?? [])
      setProductImages([...productImages, ...mappedAssets])
      setHasImageChanged(true)
    } catch (error) {
      console.log('---', error)
      setHasImageChanged(false)
      return error
    }
  }

  const handleRemoveImage = (index: number) => {
    productImages.splice(index, 1)
    setProductImages([...productImages])
    setHasImageChanged(false)
  }

  return (
    <View style={styles.container}>
      <Text style={styles.addPhotoTitle}>Add Photo(s) *</Text>
      {!!imageErrorMsg && <Text style={styles.errorText}>{imageErrorMsg}</Text>}
      <View style={styles.avatarWrapper}>
        <Pressable onPress={onOpenButtomSheet} style={[styles.avatarPlaceholder]}>
          <Ionicons name={'add-outline'} size={40} color={themeColors.black} />
        </Pressable>

        <>
          {productImages?.map((productImage, index) => {
            console.info('FILENAME', productImage, productImage.fileName)
            return (
              <View
                key={index}
                style={{
                  width: 100,
                  height: 100,
                  borderRadius: 2,
                  position: 'relative',
                  borderWidth: 2,
                  marginRight: 16,
                  borderColor: themeColors.inputBoarder,
                  marginBottom: 16,
                }}
              >
                <TouchableOpacity
                  onPress={() => handleRemoveImage(index)}
                  style={{
                    position: 'absolute',
                    borderRadius: 50,
                    right: -10,
                    top: -10,
                    zIndex: 1,
                    backgroundColor: themeColors.red100,
                    width: 35,
                    height: 35,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Ionicons name={'trash-outline'} size={25} color={themeColors.red200} />
                </TouchableOpacity>

                <Image
                  key={index}
                  source={{ uri: productImage?.uri ?? productImage?.large }}
                  style={[styles.image]}
                />
              </View>
            )
          })}
        </>
      </View>

      <BaseBottomSheetModal
        snapPoints={snapPoints}
        onDismiss={onConfirmPress}
        bottomSheetModalRef={bottomSheetModalRef}
      >
        <View style={styles.bottomSheetContainer}>
          <View style={styles.header}>
            <Text style={styles.title}>Select a photo</Text>
          </View>

          <View style={styles.buttonContainer}>
            <BaseButton
              onPress={openCamera}
              marginTop={16}
              marginBottom={0}
              title="Take photo"
              backgroundColor={themeColors.black}
            />
            <BaseButton
              onPress={openMediaLibrary}
              // marginTop={16}
              marginBottom={16}
              title="Choose from library"
              color={themeColors.black}
              backgroundColor={themeColors.white}
            />
            <BaseButton
              onPress={onConfirmPress}
              marginTop={0}
              marginBottom={0}
              title="Cancel"
              backgroundColor={themeColors.gray500}
            />
          </View>
        </View>
      </BaseBottomSheetModal>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    // paddingHorizontal: 16
  },

  avatarWrapper: {
    // justifyContent: 'space-around',
    alignItems: 'center',
    paddingTop: 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  addPhotoTitle: {
    fontSize: themeFontSizes.md,
    // textTransform: 'capitalize',
    color: themeColors.black,
    fontWeight: '300',
    fontFamily: themeFontFamily.itcGaramondStd,
    lineHeight: 20,
    marginTop: 8,
    opacity: 0.8,
  },

  header: {
    borderBottomWidth: 1,
    paddingVertical: 24,
    borderColor: themeColors.gray200,
    marginBottom: 10,
  },

  title: {
    fontSize: themeFontSizes.md,
    textAlign: 'center',
    fontWeight: '600',
    fontFamily: 'chalet',
    lineHeight: 22,
    opacity: 0.8,
  },

  avatarPlaceholder: {
    width: 100,
    height: 100,
    borderRadius: 2,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: themeColors.inputBoarder,
    marginRight: 16,
    marginBottom: 16,
  },

  image: {
    width: '100%',
    height: '100%',
    borderRadius: 3,
  },

  errorText: {
    color: 'red',
    fontFamily: themeFontFamily.itcGaramondStd,
    fontSize: themeFontSizes.md,
    lineHeight: 24,
    fontWeight: '400',
  },

  photoText: {
    fontSize: themeFontSizes.md,
    color: 'black',
    marginTop: 16,
    fontWeight: '600',
  },

  bottomSheetContainer: {},

  buttonContainer: {
    paddingHorizontal: 24,
  },

  button: {
    height: 50,
    justifyContent: 'center',
    marginTop: 16,
    marginBottom: 24,
    borderRadius: 20,
  },

  buttonText: {
    textTransform: 'uppercase',
    color: themeColors.white,
    textAlign: 'center',
    fontWeight: '700',
  },
})
