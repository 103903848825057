import { View, Text, StyleSheet, ActivityIndicator, TouchableOpacity } from 'react-native'
import React from 'react'
import { themeColors, themeFontSizes } from '../../../themes'
import { themeFontFamily } from '../../../themes/theme-font-family'
import { supportedMarketplacesType } from '../../../utils/types'
import Checkbox from 'expo-checkbox'

type CrossPostMarketplacesProps = {
  isLoadingPlatform: boolean
  profilePlatforms: supportedMarketplacesType[]
  selectedMarketplacesId: string[]
  handleMarketplaceSelect: (marketplace: supportedMarketplacesType) => void
}
export const CrossPostMarketplaces = (props: CrossPostMarketplacesProps) => {
  const { isLoadingPlatform, profilePlatforms, selectedMarketplacesId, handleMarketplaceSelect } =
    props
  return (
    <View style={styles.crossPostContainer}>
      <Text style={styles.crossPostText}>Cross post</Text>
      <Text style={styles.crossPostTextSubtitle}>
        Select the marketplaces you would like to crosspost this item to.
      </Text>
      {isLoadingPlatform && (
        <View style={styles.loader}>
          <ActivityIndicator size={30} />
        </View>
      )}
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {profilePlatforms?.length > 0 &&
          profilePlatforms?.map((marketplace, index) => {
            const isChecked = selectedMarketplacesId.includes(marketplace?.id)
            return (
              <TouchableOpacity
                key={index}
                // disabled={marketplace?.id === 'depop'} // todo remove this and displace a modal if user tries to select a mp that is not logged in
                style={[
                  styles.checkboxContainer,
                  {
                    borderWidth: 1,
                    paddingHorizontal: 10,
                    paddingVertical: 4,
                    marginRight: 10,
                    // width: '30%',
                    borderRadius: 15,
                    backgroundColor: isChecked ? themeColors.secondary : themeColors.white,
                  },
                ]}
                onPress={() => handleMarketplaceSelect(marketplace)}
              >
                <Text
                  style={[
                    styles.marketPlaceText,
                    {
                      color: isChecked ? themeColors.white : themeColors.black,
                    },
                  ]}
                >
                  {marketplace.name}
                </Text>
              </TouchableOpacity>
            )
          })}
      </View>
    </View>
  )
  return (
    <View style={styles.crossPostContainer}>
      <Text style={styles.crossPostText}>Cross post to</Text>
      <Text style={styles.crossPostTextSubtitle}>
        Select the marketplaces you would like to crosspost this item to.
      </Text>
      {isLoadingPlatform && (
        <View style={styles.loader}>
          <ActivityIndicator size={30} />
        </View>
      )}
      {profilePlatforms?.length > 0 &&
        profilePlatforms?.map((marketplace, index) => {
          const isChecked = selectedMarketplacesId.includes(marketplace?.id)
          return (
            <TouchableOpacity
              key={index}
              style={styles.checkboxContainer}
              onPress={() => handleMarketplaceSelect(marketplace)}
            >
              <Checkbox
                // style={styles.checkbox}
                value={isChecked}
                // onValueChange={setChecked}
                color={isChecked ? '#4630EB' : undefined}
              />
              <View style={{ marginLeft: 16 }}>
                <Text style={styles.marketPlaceText}>{marketplace.name}</Text>
              </View>
            </TouchableOpacity>
          )
        })}
    </View>
  )
}

const styles = StyleSheet.create({
  crossPostContainer: {
    paddingVertical: 16,
    maxWidth: 250,
  },

  crossPostText: {
    fontFamily: 'chalet',
    fontSize: themeFontSizes.lg,
    fontWeight: '400',
    lineHeight: 22,
    letterSpacing: 0.05,
  },

  crossPostTextSubtitle: {
    fontFamily: themeFontFamily.itcGaramondStd,
    fontSize: themeFontSizes.md,
    fontWeight: '300',
    lineHeight: 20,
    opacity: 0.8,
    marginTop: 4,
  },

  loader: {
    paddingVertical: 16,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
  },

  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 16,
  },

  marketPlaceText: {
    fontFamily: 'chalet',
    fontSize: themeFontSizes.md,
    fontWeight: '400',
    lineHeight: 20,
    letterSpacing: 0.1,
    textTransform: 'capitalize',
    textAlign: 'center',
  },
})
