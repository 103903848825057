export function percentageOfTotalSales(totalMercariSales = 0, totalDepopSales = 0) {
  if (totalDepopSales === 0 && totalMercariSales === 0) {
    return [
      {
        name: 'Mercari',
        percentage: 0,
        logo: 'https://res.cloudinary.com/code-freak/image/upload/v1667844493/mercari-logo_o37tyt.png',
      },
      {
        name: 'depop',
        percentage: 0,
        logo: '../../assets/images/depop-logo.png',
      },
    ]
  }

  const totalSales = totalMercariSales + totalDepopSales
  const percentageOfMecariSales = (100 * totalMercariSales) / totalSales
  const percentageOfDepopSales = (100 * totalDepopSales) / totalSales

  return [
    {
      name: 'Mercari',
      percentage: percentageOfMecariSales,
      logo: 'https://res.cloudinary.com/code-freak/image/upload/v1667844493/mercari-logo_o37tyt.png',
    },
    {
      name: 'depop',
      percentage: percentageOfDepopSales,
      logo: 'https://res.cloudinary.com/code-freak/image/upload/v1667844835/poshmark-icon_m5ogz7.png',
    },
  ]
}
