import AsyncStorage from '@react-native-async-storage/async-storage'
import { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react'

export interface authType {
  isLoggedIn: boolean
  email: string
  token: string | null
  userId: string
  name?: string
  profileImage?: {
    uri: string | null
  }
}

type contextType = {
  authState: authType
  setAuthState: Dispatch<SetStateAction<authType>>
}

const initialState: authType = {
  isLoggedIn: false,
  email: '',
  token: null,
  userId: '',
  name: '',
  profileImage: {
    uri: null,
  },
}

export const AuthContext = createContext<contextType>(initialState as any)
export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [authState, setAuthState] = useState(initialState)
  useEffect(() => {
    AsyncStorage.getItem('auth')
      .then((value: any) => {
        if (value) {
          setAuthState(JSON.parse(value))
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    AsyncStorage.setItem('auth', JSON.stringify(authState))
  }, [authState])

  return <AuthContext.Provider value={{ authState, setAuthState }}>{children}</AuthContext.Provider>
}
