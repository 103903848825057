import { ActivityIndicator, Pressable, ScrollView, StyleSheet, Text, View } from 'react-native'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { themeColors, themeFontSizes } from '../../themes'
import { BaseButton } from '../../components/base-button'
import { FtueContext } from '../../context/ftue-context'
import { useGetProfilePlatforms } from './hooks/use-get-profile-platforms'
import { BaseBottomSheetModal } from '../../components/base-bottom-sheet'
import { BottomSheetModal } from '@gorhom/bottom-sheet'
import { PlatformLoginBottomSheet } from './platform-login-bottomsheet'
import usePlatformStatus from '../../hooks/usePlatformStatus'
import { Logo } from '../../icons/logo'
import { ProfilePlatform } from '../profile/component/profile-platform'
import useJob from '../../hooks/useJob'
import { AlertModal } from '../profile/component/alert-modal'
import { mergeArrays } from '../../utils/merge-arrays'

export function PlatformLoginScreen() {
  const [isLoadingPlatformLogin, setIsLoadingPlatformLogin] = useState<boolean>(false)
  const { setFtueState } = useContext(FtueContext)
  const [platformId, setPlatformId] = useState<string>('')
  const [platformName, setPlatformName] = useState<string>('')
  const [loggedInPlatformId, setLoggedInPlatformId] = useState<string[]>([])
  const { profilePlatforms, isLoadingPlatform } = useGetProfilePlatforms()
  const { data } = usePlatformStatus()
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const [jobId, setJobId] = useState('')
  const job = useJob(jobId)
  const mergedData = mergeArrays(profilePlatforms, data)

  useEffect(() => {
    if (job?.ready) {
      setIsLoadingPlatformLogin(false)
      return
    }

    if (job?.error) {
      setTitle('Error')
      setMessage(
        'An error has occured while connecting to your martketplace, please try again later.',
      )
      setIsVisible(true)
      return
    }

    if (job?.result) {
      setTitle('Success')
      setMessage(
        'You have successfully connected to your marketplace, enjoy the full experience of sellstuff.',
      )
      setIsVisible(true)
      return
    }

    return () => {}
  }, [job])

  const bottomSheetModalRef = useRef<BottomSheetModal>(null)
  const snapPoints = useMemo(() => ['90%'], [])

  const onOpenButtomSheet = () => {
    bottomSheetModalRef.current?.present()
  }

  const onConfirmPress = () => {
    bottomSheetModalRef.current?.close()
  }

  const handleOpenPlatformLogin = (id: string, name: string) => {
    onOpenButtomSheet()
    setPlatformId(id)
    setPlatformName(name)
  }

  const handleSubmit = () => {
    setFtueState({ isFTUE: false })
  }

  return (
    <ScrollView style={styles.container}>
      <View style={{ borderBottomWidth: 1.5, paddingBottom: 10 }}>
        <Logo />
      </View>

      <View style={{ marginTop: 40, width: '90%' }}>
        <Text style={styles.loginTitle}>Marketplace Login</Text>
        <Text style={styles.subTitle}>
          You can link/login to your other marketplaces by tapping on these buttons below.
        </Text>
      </View>

      <View>
        {isLoadingPlatform && (
          <View
            style={{ paddingVertical: 17, justifyContent: 'flex-start', alignItems: 'flex-start' }}
          >
            <ActivityIndicator size="large" color={themeColors.gray300} />
          </View>
        )}
        {profilePlatforms?.length > 0 &&
          mergedData?.map((platform) => {
            const selectedMarketplace = platformId === platform?.id
            return (
              <ProfilePlatform
                key={platform?.id}
                platform={platform}
                selectedMarketplace={selectedMarketplace}
                handleOpenPlatformLogin={handleOpenPlatformLogin}
                isLoadingPlatformLogin={isLoadingPlatformLogin}
              />
            )
          })}
      </View>

      <BaseButton
        onPress={handleSubmit}
        marginTop={16}
        marginBottom={16}
        title="Continue"
        backgroundColor={themeColors.black}
      />

      <Pressable onPress={() => setFtueState({ isFTUE: false })}>
        <Text
          style={{
            color: themeColors.black,
            fontSize: themeFontSizes.md,
            textAlign: 'center',
            fontWeight: '500',
            lineHeight: 20,
            fontFamily: 'Druk-medium',
          }}
        >
          Skip
        </Text>
      </Pressable>

      <AlertModal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        title={title}
        message={message}
      />
      <BaseBottomSheetModal
        snapPoints={snapPoints}
        onDismiss={onConfirmPress}
        bottomSheetModalRef={bottomSheetModalRef}
      >
        <PlatformLoginBottomSheet
          platformId={platformId}
          onConfirmPress={onConfirmPress}
          platformName={platformName}
          setLoggedInPlatformId={setLoggedInPlatformId}
          loggedInPlatformId={loggedInPlatformId}
          setIsLoadingPlatform={setIsLoadingPlatformLogin}
          setJobId={setJobId}
        />
      </BaseBottomSheetModal>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: themeColors.appBackground,
    paddingVertical: 30,
    paddingHorizontal: 16,
  },

  loginTitle: {
    fontSize: themeFontSizes.xl,
    lineHeight: 30,
    fontWeight: '500',
    fontFamily: 'Druk-medium',
    textTransform: 'uppercase',
    opacity: 0.8,
  },

  subTitle: {
    fontSize: themeFontSizes.md,
    color: themeColors.black,
    lineHeight: 20,
    marginTop: 14,
    marginBottom: 20,
    fontFamily: 'chalet',
  },
})
