import { collection, DocumentData, getDocs, getFirestore } from 'firebase/firestore'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/auth-context'

export const useGetMercariListings = () => {
  const [listings, setListings] = useState<DocumentData[]>([])
  const [loading, setLoading] = useState(false)
  const db = getFirestore()
  const { authState } = useContext(AuthContext)

  useEffect(() => {
    if (!authState.isLoggedIn) return
    setLoading(true)
    const activeListings = collection(
      db,
      'profiles',
      authState.userId,
      'platforms',
      'mercari',
      'activeListings',
    )
    getDocs(activeListings).then((querySnapshot) => {
      const items = querySnapshot.docs.map((doc) => doc.data())
      setListings(items)
      setLoading(false)
    })
    return () => {}
  }, [])

  return { listings, loading, setLoading }
}
