import { ActivityIndicator, ScrollView, StyleSheet, View } from 'react-native'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { ProductDetail } from './components/product-detail'
import { useRoute } from '@react-navigation/native'
import { DocumentData, getFirestore, doc, getDoc } from 'firebase/firestore'
import { themeColors } from '../../themes'
import { BaseButton } from '../../components/base-button'
import { AuthContext } from '../../context/auth-context'
import { BottomSheetModal } from '@gorhom/bottom-sheet'
import { ProductUpdate } from './components/product-update'
import { axiosPost } from '../../utils/https-utils'
import { track } from '../../utils/mix-panel-utils'
import { DeleteItemAlert } from './components/delete-item-alert'
import useJob from '../../hooks/useJob'

export const ProductDetailScreen = () => {
  const route = useRoute()
  const { authState } = useContext(AuthContext)
  const db = getFirestore()
  const [jobId, setJobId] = useState<string>('')
  const [listing, setListing] = useState<DocumentData>({})
  const [loading, setLoading] = useState(false)
  const [loadingItemDelete, setLoadingItemDelete] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  const { product, productId, platform } = route.params as unknown as {
    product: DocumentData
    productId: string
    platform: string
  }
  const bottomSheetModalRef = useRef<BottomSheetModal>(null)

  const job = useJob(jobId)

  console.log({ job })
  useEffect(() => {
    setLoading(true)
    const activeListing = doc(
      db,
      'profiles',
      authState.userId,
      'platforms',
      'mercari',
      'activeListings',
      productId,
    )
    getDoc(activeListing).then((doc) => {
      if (doc.exists()) {
        const item = doc.data()
        setListing(item)
        setLoading(false)
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!')
        setLoading(false)
      }
    })
  }, [])

  const onOpenButtomSheet = () => {
    bottomSheetModalRef.current?.present()
  }

  const onConfirmPress = () => {
    bottomSheetModalRef.current?.close()
  }

  const handleDeleteListing = async () => {
    const postItem = [
      {
        itemId: productId,
        platform,
      },
    ]
    try {
      setLoadingItemDelete(true)
      const res = await axiosPost({
        url: 'deleteItem',
        data: postItem,
        contentType: 'application/json',
      })

      if (res) {
        console.log({ res })
        setJobId(res?.jobId[0])
        track('delete_item_data', postItem)
        return
      }
    } catch (error) {
      setLoadingItemDelete(false)
      track('delete_item_error', error)
      console.log('--error--', error)
    }
  }

  if (loading) {
    return (
      <View
        style={{
          height: 210,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ActivityIndicator size="large" color={themeColors.black} />
      </View>
    )
  }

  return (
    <>
      <ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
        <ProductDetail item={listing} onOpenButtomSheet={onOpenButtomSheet} />
        <View style={{ paddingHorizontal: 16 }}>
          <BaseButton
            onPress={() => setIsVisible(true)}
            title="Delete listing"
            backgroundColor={themeColors.white}
            color={themeColors.soldBgBadge}
            marginTop={0}
          />
        </View>
      </ScrollView>
      <ProductUpdate
        bottomSheetModalRef={bottomSheetModalRef}
        onConfirmPress={onConfirmPress}
        item={listing}
        platform={platform}
      />

      <DeleteItemAlert
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        itemName={listing?.name}
        isDeleted={job?.ready}
        handleDeleteListing={handleDeleteListing}
        isLoading={(loadingItemDelete || job?.syncing) && !job?.ready}
      />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 16,
    backgroundColor: themeColors.white,
  },
})
