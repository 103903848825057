import { Ionicons } from "@expo/vector-icons";
import { BottomSheetModal } from "@gorhom/bottom-sheet";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { View as Fragment, Text, StyleSheet, TextInput, StyleProp, ViewStyle, Pressable, ScrollView } from "react-native";
import { themeColors, themeFontSizes } from "../../themes";
import { themeFontFamily } from "../../themes/theme-font-family";
import { BaseBottomSheetModal } from "../base-bottom-sheet";
import { DepopDataType, DepopInputType, DepopOptionType } from "./datatype";
import { DepopInput } from "./depop-input";

type DepopDropDownInputProps = DepopInputType & {
  id: string;
  textInputStyle?: StyleProp<ViewStyle>;
  snapPointsValue: string[];
  label: string;
  options: DepopOptionType[];
  placeholder: string;
  isDisabled?: boolean;
  isList?: boolean;
  default: any;
  onChange: (value: DepopDataType, index: number) => void
};

export function DepopDropDownInput({ id, type, textInputStyle, label: title, options, onChange, placeholder, snapPointsValue = ['40%'], isDisabled }: DepopDropDownInputProps) {
  const bottomSheetModalRef = useRef<BottomSheetModal>(null);
  const snapPoints = useMemo(() => snapPointsValue, []);
  const [selected, setSelected] = useState<DepopOptionType>();
  const value = useRef<DepopDataType>();
  const _onChangeChild = useCallback((_value: DepopDataType) => {
    const newValue = {
      id: id,
      value: value.current?.value || _value.value,
      selectedValues: (value?.current?.selectedValues || []).filter((v) => v.id !== _value.id).concat(_value),
    };
    value.current = newValue;
    onChange(newValue);
  }, [value]);
  const onOpenButtomSheet = () => {
    if(isDisabled) return;
    bottomSheetModalRef.current?.present();
  };

  const onConfirmPress = () => {
    bottomSheetModalRef.current?.close();
  };

  return (
    <Fragment>
      <Pressable onPress={onOpenButtomSheet} style={[styles.textInputContainer, textInputStyle, { borderColor: themeColors.inputBoarder }]}>
        <TextInput
          onPressIn={onOpenButtomSheet}
          style={styles.input}
          placeholder={placeholder}
          editable={false}
          value={selected?.label ?? ""}
        />
        <Ionicons name="chevron-down-outline" size={24} color={themeColors.gray500} />
      </Pressable>
      <BaseBottomSheetModal
        snapPoints={snapPoints}
        onDismiss={onConfirmPress}
        bottomSheetModalRef={bottomSheetModalRef}
      >
        <Fragment style={{ paddingVertical: 10, paddingHorizontal: 16 }}>
          <Text style={{ fontSize: themeFontSizes.lg, fontWeight: '500' }}>{title}</Text>
        </Fragment>

        <ScrollView style={{ paddingHorizontal: 16, marginBottom: 20 }}>
          {options?.map((item, index) => {
            return (
              <Pressable key={index} style={{ paddingVertical: 10 }} onPress={() => { 
                setSelected(item);
                onChange({
                  id: id,
                  value: item.id,
                  type: type,
                }, index);
                onConfirmPress();
              }}>
                <Text>{item.label}</Text>
              </Pressable>
            )
          })}
        </ScrollView>
      </BaseBottomSheetModal>
      {
        Object.values(selected?.inputs ?? {}).map((input) => {
            return (<DepopInput onChange={_onChangeChild} key={input.id} input={input}></DepopInput>);
        })
      }
    </Fragment>
  )
}

const styles = StyleSheet.create({
  textInputContainer: {
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: themeColors.white,
    paddingHorizontal: 16,
    borderRadius: 2,
    borderColor: themeColors.inputBoarder
  },

  input: {
    flex: 1,
    backgroundColor: 'transparent',
    color: '#424242',
    paddingVertical: 16,
  },

  errorText: {
    color: 'red',
    fontFamily: themeFontFamily.itcGaramondStd,
    fontSize: themeFontSizes.md,
    lineHeight: 24,
    fontWeight: '400'
  }
})