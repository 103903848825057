import { Ionicons } from '@expo/vector-icons'
import React, { ReactNode, useState } from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'
import { themeColors, themeFontSizes } from '../themes'

export const Accordion = ({
  title,
  children,
  initiallyExpanded = false,
}: {
  title: string
  children: ReactNode
  initiallyExpanded?: Boolean
}) => {
  const [expanded, setExpanded] = useState(initiallyExpanded)

  return (
    <View>
      <TouchableOpacity
        onPress={() => setExpanded(!expanded)}
        style={{
          borderWidth: 1,
          borderBottomWidth: expanded ? 0 : 1,
          borderColor: themeColors.inputBoarder,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 10,
        }}
      >
        <Text style={styles.accordionTitle}>{title}</Text>
        <Ionicons name={expanded ? 'remove-outline' : 'add-outline'} size={24} />
      </TouchableOpacity>
      {expanded && (
        <View
          style={{ borderWidth: 1, paddingHorizontal: 10, borderColor: themeColors.inputBoarder }}
        >
          {children}
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  accordionTitle: {
    fontFamily: 'chalet',
    fontSize: themeFontSizes.lg,
    color: themeColors.black,
    lineHeight: 24,
  },
})
