import React from 'react'
import { ProcessingModalComponent } from './processing-modal-component'
import { goHome } from '../../../service/navigation-service'

// todo handle loading
type ProcessingModalProps = {
  visible: boolean
  setProcessingModal: (e: boolean) => void
  syncing: boolean
  error?: string
  jobId?: string
  ready: boolean
}

export const ProcessingModal = (props: ProcessingModalProps) => {
  const { visible, setProcessingModal, syncing, error, ready, jobId } = props

  if (syncing) {
    return (
      <ProcessingModalComponent
        visible={visible}
        setProcessingModal={setProcessingModal}
        message={
          'Uplaoding your item to the market place, kindly note that this might take a while...'
        }
        title={'Processing'}
        isProcessing={true}
      />
    )
  }

  if (!!error) {
    return (
      <ProcessingModalComponent
        visible={visible}
        setProcessingModal={setProcessingModal}
        message={`${error} - ${jobId}`}
        title={'Oops Error'}
        isProcessing={false}
      />
    )
  }

  if (ready) {
    return (
      <ProcessingModalComponent
        visible={visible}
        setProcessingModal={setProcessingModal}
        message={'Your item has been successfully listed on the marketplace.'}
        title={'Success!!'}
        isProcessing={false}
        onRoute={goHome}
      />
    )
  }

  return <></>
}
