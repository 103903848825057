import React, { useState } from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'

interface IProps {
  onChange: (isPounds: boolean) => void
}

const WeightUnitPicker = ({ onChange }: IProps) => {
  const [isPoundsSelected, setIsPoundsSelected] = useState(true)

  const handleUnitChange = (isPounds: boolean) => {
    setIsPoundsSelected(isPounds)
    onChange(isPounds)
  }

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={() => handleUnitChange(true)}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View
            style={{
              ...styles.option,
              borderColor: isPoundsSelected ? 'black' : 'gray',
            }}
          >
            {isPoundsSelected && <View style={styles.selected} />}
          </View>
          <Text>Pounds</Text>
        </View>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => handleUnitChange(false)}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View
            style={{
              ...styles.option,
              borderColor: !isPoundsSelected ? 'black' : 'gray',
            }}
          >
            {!isPoundsSelected && <View style={styles.selected} />}
          </View>
          <Text>Ounces</Text>
        </View>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    gap: 30,
    display: 'flex',
    flexDirection: 'row',
  } as any,
  selected: {
    height: 8,
    width: 8,
    borderRadius: 4,
    backgroundColor: 'black',
  },
  option: {
    height: 16,
    width: 16,
    borderRadius: 8,
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 8,
  },
})
export default WeightUnitPicker
