import { yupResolver } from '@hookform/resolvers/yup'
import * as ImagePickers from 'expo-image-picker'
import { getFirestore, doc, onSnapshot } from 'firebase/firestore'
import React, { useCallback, useEffect, useState } from 'react'
import { FieldErrors, useForm } from 'react-hook-form'
import { SafeAreaView, ScrollView, StyleSheet, View } from 'react-native'
import * as yup from 'yup'

import { Accordion } from '../../components/accordion'
import { BaseButton } from '../../components/base-button'
import { BaseDropDownInput } from '../../components/base-dropdown-input'
import { ControlledInput } from '../../components/controlled-input'
import { DepopContainerType, GenericDepopFormType } from "../../components/depop/datatype";
import { DepopCreateStuff } from "../../components/depop/depop-create-stuff";
import useJob from '../../hooks/useJob'
import useTransaction from '../../hooks/useTransaction'
import { Logo } from '../../icons/logo'
import { themeColors, themeFontSizes } from '../../themes'
import { GenericFields, MercariPostFormFields } from "../../types/form-data";
import { axiosPost } from '../../utils/https-utils'
import { track } from '../../utils/mix-panel-utils'
import { itemConditons, ShippingPayerOptions } from '../../utils/static-data'
import {
  categoriesType,
  shippingOptionType,
  ShippingPayerType,
  sizeType,
  subCategoriesType,
  supportedMarketplacesType,
} from '../../utils/types'
import { useGetProfilePlatforms } from '../account/hooks/use-get-profile-platforms'

import { CrossPostMarketplaces } from './component/cross-post-marketplaces'
import { PrepaidShippingOption } from './component/prepaid-shipping-options'
import { ProcessingModal } from './component/processing-modal'
import { ProductImageUpload } from './component/product-image-upload'
import { ShippingChoicesModal } from './component/shipping-choices-modal'
import { ShippingOptions } from './component/shipping-options'

export type FormValues = {
  title: string
  description: string
  price: string
  productImages: ImagePickers.ImageInfo[]
}

const ErrorMessages = {

  title: 'Title is required',
  description: 'Description must be atleast 5 words',
  price: 'Price is required',
  condition: 'Please select a condition',
  category: 'Please select a category',
  productImages: 'Please upload at least 1 image',
  subCategory: 'Please select a subCategory',
  shippingWeight: 'Please enter a valid weight',
} as const

const productUploadScheme = yup.object().shape({
  title: yup.string().required(),
  description: yup
    .string()
    .required('Description is required')
    .matches(/(\b\w+\b[\s\r\n]*){5,}/, ErrorMessages.description),
  price: yup.string().required(),
  weightInPounds: yup.string(),
  weightInOz: yup.string(),
})

export enum MercariCondition {
  New = 'ConditionNew',
  LikeNew = 'ConditionLikenew',
  Good = 'ConditionGood',
  Fair = 'ConditionFair',
  Poor = 'ConditionPoor',
}

export function CreateStuffScreen() {
  const [productImages, setProductImages] = useState<ImagePickers.ImageInfo[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [, setShippingError] = useState<
    | {
        [key: string]: (typeof ErrorMessages)['shippingWeight']
      }
    | string
  >('')
  // MERCARI STATE
  const [selectedSize, setSelectedSize] = useState<sizeType>()
  const [itemCondition, setItemCondition] = useState<any>({})
  const [selectedSubSubCatetories, setSelectedSubSubCatetories] = useState<any>() // type this
  const [selectedCategory, setSelectedCategory] = useState<categoriesType>()
  const [selectedSubCategory, setSelectedSubCategory] = useState<subCategoriesType>()
  const [selectedMarketplacesId, setSelectedMarketplacesId] = useState<string[]>(['mercari'])
  const [selectedShippingOptionId, setSelectedShippingOptionId] =
    useState<string>('prepaid-shipping-1')
  const [categoryList, setCatetoryList] = useState<any[]>([])
  const [imageErrorMsg, setImageErrorMsg] = useState<string>('')
  const [categoryErrorMsg, setCategoryErrorMsg] = useState<string>('')
  const [subCategoryErrorMsg, setSubCategoryErrorMsg] = useState<string>('')
  const [subSubCategoryErrorMsg, setSubSubCategoryErrorMsg] = useState<string>('')
  const [descriptionErrorMsg, setDescriptionErrorMsg] = useState<string>('')
  const [sizeErrorMsg, setSizeErrorMsg] = useState<string>('')
  const [itemConditionErrorMsg, setItemConditionErrorMsg] = useState<string>('')
  const [shippingChoicesModalVisible, setShippingChoicesModalVisible] = useState<boolean>(false)
  const [processingModalVisible, setProcessingModalVisible] = useState<boolean>(false)
  const [selectedShippingPayer, setSelectedShippingPayer] = useState<ShippingPayerType>(
    ShippingPayerOptions[0],
  )
  // DEPOP STATES
  const categoriesJson = require('../../../assets/depop-categories.json');
  const [depopCategoryList] = useState<DepopContainerType[]>(categoriesJson);
  const [selectedDepopCategory] = useState<categoriesType>();
  const [depopItemCondition] = useState<any>({});
  const [, setCatchError] = useState<any>();
  const [depopFormData, setDepopFormData] = useState<GenericDepopFormType[]>([]);
  const [jobId, setJobId] = useState<string>('');
  console.info("depopFormData", depopFormData);
  const { profilePlatforms, isLoadingPlatform } = useGetProfilePlatforms();
  const job = useJob(jobId);
  const transactions = useTransaction();
  const containsShippingChoices = transactions.some(obj => obj.RequestType === "shippingChoices");
  const db = getFirestore();

  useEffect(() => {
    const docPlatform = doc(db, 'platforms', 'mercari')
    const unsub = onSnapshot(docPlatform, (doc) => {
      const categories = doc.data()?.categories
      const categoriesJson = JSON.parse(categories)
      setCatetoryList(categoriesJson)
    })

    const depopPlatform = doc(db, 'platforms', 'depop')
    onSnapshot(depopPlatform, () => {
      // let categories = doc.data()?.categories;
      // setDepopCategoryList(categoriesJson);
    });

    return unsub
  }, [])

  useEffect(() => {
    if (transactions?.length > 0 && containsShippingChoices) {
      setShippingChoicesModalVisible(true)
    }
  }, [transactions])

  useEffect(() => {
    if (job?.ready) {
      track('post_item_success')
    }
    if (job.ready || !!job.error) {
      setProcessingModalVisible(true)
      setIsLoading(false)
    }
  }, [job])

  const handleMarketplaceSelect = useCallback(
    (item: supportedMarketplacesType) => {
      const isActive = selectedMarketplacesId.includes(item.id)
      const newActive = isActive
        ? selectedMarketplacesId.filter((x) => x !== item.id)
        : selectedMarketplacesId.concat(item.id)
      setSelectedMarketplacesId(newActive)
    },
    [selectedMarketplacesId],
  )

  const handleShippingMethod = useCallback(
    (item: shippingOptionType) => {
      setSelectedShippingOptionId(item.id)
    },
    [selectedShippingOptionId],
  )

  const handleSelectCategory = (item: categoriesType) => {
    setSelectedCategory(item)
  }

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(productUploadScheme),
  });

  const handleErrorMessage = (errors: FieldErrors<FormValues>) => {
    const { weightInPounds, weightInOz } = getValues()
    if (errors.description) {
      setDescriptionErrorMsg(errors.description.message as string)
    }
    if (!productImages.length) {
      setImageErrorMsg(ErrorMessages.productImages)
    }

    if (!selectedCategory && !selectedDepopCategory) {
      setCategoryErrorMsg(ErrorMessages.category)
    }
    if (Boolean(selectedCategory) && !selectedSubCategory) {
      setSubCategoryErrorMsg(ErrorMessages.subCategory)
    }

    if (
      !!selectedSubCategory &&
      selectedSubCategory?.subsubcategories?.length > 0 &&
      selectedSubSubCatetories === undefined
    ) {
      setSubSubCategoryErrorMsg('Please select sub sub category')
    }

    if (
      !!selectedSubCategory &&
      selectedSubCategory?.sizes?.length > 0 &&
      selectedSize === undefined
    ) {
      setSizeErrorMsg('Please select size')
    }

    if (itemCondition?.value && depopItemCondition?.value === undefined) {
      setItemConditionErrorMsg('Please select item condition')
    }

    if (!weightInPounds && !weightInOz) {
      setShippingError({ message: ErrorMessages.shippingWeight })
    } else {
      setShippingError('')
    }
  }

  const assembleMercariFormData = (data: MercariPostFormFields) => {
    return {
      weightInPounds: data.weightInPounds,
      weightInOz: data.weightInOz,
      category: selectedCategory?.id,
      subCategory: selectedSubCategory?.id,
      subCategorySize: selectedSize?.id,
      subSubCategory: selectedSubSubCatetories?.id,
      condition: itemCondition?.value,
      shipping: selectedShippingOptionId,
      smartPrice: false,
      minSmartPrice: "",
      smartOffer: false,
      smartOfferPrice: "",
      buyerPaysShipping: true,
      shippingPayer: selectedShippingPayer.name,
    }
  };

  const assembleDepopFormData = () => {
    return depopFormData
  };

  const assembleFormData = async (data: GenericFields) => {
    console.info("assembleFormData", data);
    const formData = new FormData();
    const marketplaceData: {[k: string]: any} = {};
    selectedMarketplacesId.forEach((marketplaceId) => {
      if (marketplaceId === 'mercari') {
        marketplaceData[marketplaceId] = assembleMercariFormData(data as MercariPostFormFields);
      } else if (marketplaceId === 'depop') {
        marketplaceData[marketplaceId] = assembleDepopFormData();
      }
    });
    console.info("marketplaceData", JSON.stringify(marketplaceData, null, 2));
    formData.append("platforms", JSON.stringify(marketplaceData));
    formData.append("fields", JSON.stringify(data));
    await addImagesToFormData(formData);
    return formData;
  }

  const addImagesToFormData = async (formData: FormData) => {
    const images = productImages.filter((image) => {
      if (Object.keys(image).length !== 0) {
        return true;
      }
      return false;
    });

    for (const image of images) {
      const blob = await fetch(image.uri)
        .then((res) => res.blob())
      formData.append(
        "file",
        blob,
        image.fileName ?? 'filename.png'
      );
    }
  }

  const onSubmit = async (data: any) => {
    const wordCount = data.description.split(' ').length
    if (wordCount < 5) {
      setDescriptionErrorMsg('Description must be at least 5 words')
      return
    }

    try {
      const formData = await assembleFormData(data)

      setIsLoading(true)

      const res = await axiosPost({
        url: 'postItem',
        data: formData
      });

      if (res) {
        setJobId(res?.jobId[0]);
        track('post_item_data', formData)
        return;
      }
    } catch (error) {
      setIsLoading(false)
      setCatchError(error)
      track('post_item_error', error)
      console.log('--error--', error)
    }
  }

  return (
    <ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
      <SafeAreaView>
        <View style={[styles.headerWrapper]}>
          <View style={{ paddingBottom: 10 }}>
            <Logo />
          </View>
        </View>

        <View>
          <CrossPostMarketplaces
            profilePlatforms={profilePlatforms}
            selectedMarketplacesId={selectedMarketplacesId}
            handleMarketplaceSelect={handleMarketplaceSelect}
            isLoadingPlatform={isLoadingPlatform}
          />
          {/* Product image */}
          <ProductImageUpload
            setProductImages={setProductImages}
            productImages={productImages}
            imageErrorMsg={imageErrorMsg}
            setErrorState={setImageErrorMsg}
            setHasImageChanged={() => {}}
          />

          <ControlledInput
            control={control}
            name="title"
            placeholder="Item Name*"
            error={!!errors.title}
            required={true}
          />

          {/* Product description */}
          <ControlledInput
            control={control}
            name="description"
            placeholder="Item Descriptions*"
            error={!!errors.description}
            hasWordCountError={!!descriptionErrorMsg}
            descriptionErrorMsg={descriptionErrorMsg}
            required={true}
            inputContainer={{ marginTop: 10 }}
            inputStyle={{ height: 100, justifyContent: 'flex-start' }}
            multiline={true}
            onFocus={() => setDescriptionErrorMsg('')}
          />

          {/* Product price */}
          <ControlledInput
            control={control}
            name="price"
            placeholder="Item Price ($)*"
            error={!!errors.price}
            keyboardType="number-pad"
            required={true}
            inputContainer={{ marginTop: 10 }}
            hasCurrencySymbol={true}
          />

          <View style={{ marginVertical: 10 }}>
            {profilePlatforms?.length > 0 &&
              selectedMarketplacesId.includes("mercari") && (
                <Accordion title="Mercari Fields">
                  {/* product Category */}
                  <BaseDropDownInput
                    title="Category"
                    value={selectedCategory?.description}
                    snapPointsValue={["80%"]}
                    lists={categoryList}
                    handleSelect={handleSelectCategory}
                    placeholder={"Select category*"}
                    textInputStyle={{ marginTop: 10 }}
                    errorMsg={categoryErrorMsg}
                    setErrorState={setCategoryErrorMsg}
                  />

                  {/* product Sub Category */}
                  <>
                    {!!selectedCategory &&
                      selectedCategory?.subcategories?.length > 0 && (
                        <BaseDropDownInput
                          title="Subcategory"
                          value={selectedSubCategory?.description}
                          snapPointsValue={["80%"]}
                          lists={selectedCategory?.subcategories}
                          handleSelect={setSelectedSubCategory}
                          placeholder={"Select subcategory*"}
                          textInputStyle={{ marginTop: 10 }}
                          errorMsg={subCategoryErrorMsg}
                          setErrorState={setSubCategoryErrorMsg}
                        />
                      )}
                  </>

                  {/* product Sub sub Category */}
                  <>
                    {!!selectedSubCategory &&
                      selectedSubCategory?.subsubcategories?.length > 0 && (
                        <BaseDropDownInput
                          title="Sub Subcategory"
                          value={selectedSubSubCatetories?.description}
                          snapPointsValue={["80%"]}
                          lists={selectedSubCategory?.subsubcategories}
                          handleSelect={setSelectedSubSubCatetories}
                          placeholder={"Select sub subcategory*"}
                          textInputStyle={{ marginTop: 10 }}
                          errorMsg={subSubCategoryErrorMsg}
                          setErrorState={setSubSubCategoryErrorMsg}
                        />
                      )}
                  </>

                  {/* Product size */}
                  <>
                    {!!selectedSubCategory &&
                      selectedSubCategory?.sizes?.length > 0 && (
                        <BaseDropDownInput
                          title="Item Sizes"
                          value={selectedSize?.description}
                          snapPointsValue={["80%"]}
                          lists={selectedSubCategory?.sizes}
                          handleSelect={setSelectedSize}
                          placeholder={"Select size*"}
                          textInputStyle={{ marginTop: 10 }}
                          errorMsg={sizeErrorMsg}
                          setErrorState={setSizeErrorMsg}
                        />
                      )}
                  </>

                  {/* Product Condition */}
                  <BaseDropDownInput
                    title="Item Condition"
                    value={itemCondition.description}
                    snapPointsValue={["40%"]}
                    lists={itemConditons}
                    handleSelect={setItemCondition}
                    placeholder={"Select item condition*"}
                    textInputStyle={{ marginTop: 10 }}
                    errorMsg={itemConditionErrorMsg}
                    setErrorState={setItemConditionErrorMsg}
                  />

                  {/* Product Delivery Method */}
                  <ShippingOptions
                    selectedShippingOptionId={selectedShippingOptionId}
                    selectedShippingPayer={selectedShippingPayer}
                    handleShippingMethod={handleShippingMethod}
                    handleShippingPayerChange={setSelectedShippingPayer}
                  />

                  {/* prepaid shipping option selected  */}
                  {selectedShippingOptionId?.includes("prepaid-shipping-1") && (
                    <PrepaidShippingOption control={control} errors={errors} />
                  )}
                </Accordion>
              )}
          </View>

          {/* Depop */}
          <View>
            {selectedMarketplacesId.includes("depop") && (
              <Accordion title="Depop Fields">
                <DepopCreateStuff
                  sections={depopCategoryList}
                  depopFormData={setDepopFormData}
                  />
              </Accordion>
            )}
          </View>

          <ShippingChoicesModal
            visible={shippingChoicesModalVisible}
            setShippingChoicesModalVisible={setShippingChoicesModalVisible}
            transactions={transactions}
          />

          <ProcessingModal
            visible={processingModalVisible}
            setProcessingModal={setProcessingModalVisible}
            syncing={job?.syncing}
            error={job?.error}
            ready={job?.ready}
            jobId={jobId}
          />

          <BaseButton
            onPress={handleSubmit(onSubmit, handleErrorMessage)}
            isLoading={isLoading || job?.syncing}
            title="Post item"
            backgroundColor={themeColors.black}
            color={themeColors.white}
          />
        </View>
      </SafeAreaView>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: themeColors.white,
    flex: 1,
    paddingHorizontal: 16,
  },

  headerWrapper: {
    paddingTop: 16,
  },

  accordionTitle: {
    fontFamily: 'chalet',
    fontSize: themeFontSizes.lg,
    color: themeColors.black,
    lineHeight: 24,
  },
})
