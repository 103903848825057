import { Pressable, StyleSheet, Text, View } from 'react-native'
import React, { useContext, useEffect, useState } from 'react'
import { ResponseType } from 'expo-auth-session'
import { getAuth, OAuthProvider, signInWithCredential, signInWithPopup } from 'firebase/auth'
import { axiosGet } from '../../../utils/https-utils'
import { AuthContext } from '../../../context/auth-context'
import { FtueContext } from '../../../context/ftue-context'
import { themeColors} from '../../../themes'
import * as WebBrowser from 'expo-web-browser'
import { configValue } from '../../../config/config-values'
import { registerUser, track } from '../../../utils/mix-panel-utils'
import { BaseButton } from '../../../components/base-button'
import * as AppleAuthentication from 'expo-apple-authentication';
import { AntDesign } from '@expo/vector-icons';

WebBrowser.maybeCompleteAuthSession()

type AppleAuthProps = {
  isLogin?: boolean
  setUserState?: any // todo fix this
  setLoadingSocialAuth: (data: boolean) => void
  trackId: string
}

async function onAppleButtonPress() {
  const provider = new OAuthProvider('apple.com');

  // Start the sign-in request
  const credential = await AppleAuthentication.signInAsync({
    requestedScopes: [
      AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
      AppleAuthentication.AppleAuthenticationScope.EMAIL,
    ],
  });

  // Ensure Apple returned a user identityToken
  if (!credential.identityToken) {
    throw new Error('Apple Sign-In failed - no identify token returned');
  }

  // Create a Firebase credential from the response
  const { identityToken, nonce } = credential;
  const appleCredential = provider.credential({idToken: identityToken as string, rawNonce: nonce});

  const firebaseAuth = getAuth();
  // Sign the user in with the credential
  return signInWithCredential(firebaseAuth, appleCredential);

}

export const AppleAuth = (props: AppleAuthProps) => {
  const { isLogin, setUserState, setLoadingSocialAuth, trackId } = props
  const { setAuthState } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleAppleAuth = async () => {
    setIsLoading(true);
    setLoadingSocialAuth(true)
    try {
     const resp = await onAppleButtonPress()
      if (resp) {
        // make api call to validate token
        axiosGet({
          url: '/validateToken',
        }).then(async (response) => {
          if (response.message === 'success') {
            const {_tokenResponse, user} = resp
            setAuthState({
              token: _tokenResponse.idToken,
              email: _tokenResponse.email,
              isLoggedIn: true,
              userId: _tokenResponse.localId,
              profileImage: {uri: user.photoURL},
              name: user.displayName || _tokenResponse.email,
            })
            if (isLogin) {
              track(trackId)
              registerUser({email: _tokenResponse.email})
              setUserState({
                user: {
                  email: _tokenResponse.email,
                  isLoggedIn: true,
                  userId: _tokenResponse.localId,
                  name: _tokenResponse.displayName,
                  profileImage: {uri: user.photoURL},
                },
              })
              setLoadingSocialAuth(false)
              return
            }
            setLoadingSocialAuth(false)
            return
          }
        })
      }
    }
    catch (e) {
      console.log('--error--', e)
      setLoadingSocialAuth(false)
      track(trackId+'_error');
      alert(e?.message)
      return e
    }
  }

  return (
      <BaseButton
        onPress={handleAppleAuth}
        marginTop={0}
        title="Continue with Apple"
        color={themeColors.black}
        backgroundColor={themeColors.white}
        hasLeftIcon={true}
        leftIcon={<AntDesign name="apple1" size={26} color="black" />}
        marginBottom={16}
      />
  )
}

const styles = StyleSheet.create({})
