import React, { useContext, useEffect, useState } from 'react'
import {
  ScrollView,
  ActivityIndicator,
  Pressable,
  SafeAreaView,
  StyleSheet,
  Text,
  TextInput,
  View,
  Platform,
} from 'react-native'
import { goToLoginScreen } from '../../service/navigation-service'
import { GoogleLogoSvgIcon, themeColors, themeFontSizes } from '../../themes'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithCredential,
  OAuthProvider,
} from 'firebase/auth'
import { auth } from '../../config/firebase'
import { AuthContext } from '../../context/auth-context'
import { BaseButton } from '../../components/base-button'
import { FtueContext } from '../../context/ftue-context'
import { CountrySelectionScreen } from './country-selection'
import { UserCountryContext } from '../../context/user-country-context'
import { axiosGet } from '../../utils/https-utils'
import * as Google from 'expo-auth-session/providers/google'
import { configValue } from '../../config/config-values'
import * as WebBrowser from 'expo-web-browser'
import * as AppleAuthentication from 'expo-apple-authentication'
import { FaceBookAuth } from './components/facebook-auth'
import { Logo } from '../../icons/logo'
import { themeFontFamily } from '../../themes/theme-font-family'
import { registerUser, track } from '../../utils/mix-panel-utils'
import { AppUserContext } from '../../context/app-user-context'
import {AppleAuth} from "./components/apple-auth";

WebBrowser.maybeCompleteAuthSession()

const signUpScheme = yup.object().shape({
  email: yup.string().required().email(),
  password: yup.string().required(),
  confirmPassword: yup.string().required(),
})

interface FormData {
  email: string
  password: string
  confirmPassword: string
}

export function SignUpScreen() {
  const [checked, onChange] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [loadingSocialAuth, setLoadingSocialAuth] = useState<boolean>(false)
  const { userCountry } = useContext(UserCountryContext)
  const { setAuthState } = useContext(AuthContext)
  const { setFtueState } = useContext(FtueContext)
  const { setUserState, userState } = useContext(AppUserContext)

  const [request, response, promptAsync] = Google.useIdTokenAuthRequest({
    clientId: configValue.clientId,
    expoClientId: configValue.expoClientID,
    iosClientId: configValue.iosKey,
  })

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<FormData>({
    resolver: yupResolver(signUpScheme),
  })

  const onCheckmarkPress = () => {
    onChange(!checked)
  }

  //FORM SUBMISTION
  const onSubmit = async (data: FormData) => {
    const { email, password, confirmPassword } = data
    setIsLoading(true)
    createUserWithEmailAndPassword(auth, email, password)
      .then(({ user }) => {
        if (user) {
          // make api call to validate token
          axiosGet({
            url: '/validateToken',
          })
            .then(async (response) => {
              if (response.message === 'success') {
                // @ts-ignore
                setAuthState({
                  token: user.stsTokenManager.accessToken,
                  email: user.email,
                  isLoggedIn: true,
                  userId: user.uid,
                })
                setFtueState({ isFTUE: true })
                // setMountUser(Math.random());
                setUserState({ user: { email: user.email, isLoggedIn: true, userId: user.uid } })
                setIsLoading(false)
                track('email_signup_success')
                registerUser({ email: user?.email })
                return
              }
            })
            .catch((error) => {
              setIsLoading(false)
              track('email_signup_error')
              console.log('error: ', error)
            })
        }
      })
      .catch((error) => {
        console.log('--error--', error)
        setIsLoading(false)
        alert(error?.message)
        track('email_signup_error')
        return error
      })
  }

  // GOOGLE AUTH
  useEffect(() => {
    if (response?.type === 'success') {
      const { id_token } = response.params
      const credential = GoogleAuthProvider.credential(id_token)
      setLoadingSocialAuth(true)
      signInWithCredential(auth, credential)
        .then(({ user }) => {
          if (user) {
            // make api call to validate token
            axiosGet({
              url: '/validateToken',
            })
              .then(async (response) => {
                if (response.message === 'success') {
                  // @ts-ignore
                  setAuthState({
                    token: user.stsTokenManager.accessToken,
                    email: user.email,
                    isLoggedIn: true,
                    userId: user.uid,
                    profileImage: { uri: user.photoURL },
                    name: user.displayName,
                  })
                  setFtueState({ isFTUE: true })
                  setUserState({
                    user: {
                      email: user.email,
                      isLoggedIn: true,
                      userId: user.uid,
                      name: user.displayName,
                      profileImage: { uri: user.photoURL },
                    },
                  })
                  track('google_signup_success')
                  registerUser({ email: user?.email })
                  setLoadingSocialAuth(false)
                  return
                }
              })
              .catch((error) => {
                setLoadingSocialAuth(false)
                track('google_signup_error')
              })
          }
        })
        .catch((error) => {
          setLoadingSocialAuth(false)
          track('google_signup_error')
          alert(error?.message)
          return error
        })
    }
  }, [response])

  // apple signup
  const handleAppleSignIn = async () => {
    try {
      const appleCredential = await AppleAuthentication.signInAsync({
        requestedScopes: [
          AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
          AppleAuthentication.AppleAuthenticationScope.EMAIL,
        ],
      })
      const { identityToken, email, fullName } = appleCredential
      const provider = new OAuthProvider('apple.com')
      provider.addScope('email')
      provider.addScope('fullName')
      const credential = provider.credential({
        idToken: identityToken as string,
      })
      signInWithCredential(auth, credential)
        .then(({ user }) => {
          if (user) {
            // make api call to validate token
            axiosGet({
              url: '/validateToken',
            })
              .then(async (response) => {
                if (response.message === 'success') {
                  // @ts-ignore
                  setAuthState({
                    token: user.stsTokenManager.accessToken,
                    email: user.email,
                    isLoggedIn: true,
                    userId: user.uid,
                    profileImage: { uri: user.photoURL },
                    name: user.displayName,
                  })
                  setFtueState({ isFTUE: true })
                  setIsLoading(false)
                  return
                }
              })
              .catch((error) => {
                setIsLoading(false)
                console.log('error: ', error)
              })
          }
        })
        .catch((error) => {
          console.log('--error--', error)
          setIsLoading(false)

          alert(error?.message)
          return error
        })
    } catch (error) {
      console.log('--error::-', error)
    }
  }

  // if no country is selected display this view
  if (!userCountry.country) {
    return <CountrySelectionScreen />
  }

  return (
    <SafeAreaView>
      {/* <KeyboardAvoidingView behavior={'padding'} style={styles.container}> */}
      <ScrollView
        style={styles.container}
        showsVerticalScrollIndicator={false}
        keyboardDismissMode={'interactive'}
      >
        <View style={styles.logoWrapper}>
          <View style={{ borderBottomWidth: 1.5, paddingBottom: 10 }}>
            <Logo />
          </View>

          <View style={{ marginTop: 16, width: '90%' }}>
            <Text
              style={{
                fontSize: themeFontSizes.lg,
                lineHeight: 38,
                fontWeight: '500',
                fontFamily: 'Druk-medium',
                textTransform: 'uppercase',
                opacity: 0.8,
              }}
            >
              Create Account
            </Text>
          </View>

          <View style={styles.socialsWrapper}>
            <FaceBookAuth
              setLoadingSocialAuth={setLoadingSocialAuth}
              trackId={'facebook_signup'}
              setUserState={setUserState}
            />
            <BaseButton
              onPress={() => promptAsync()}
              marginTop={0}
              title="Countinue with Google"
              color={themeColors.black}
              backgroundColor={themeColors.white}
              hasLeftIcon={true}
              leftIcon={<GoogleLogoSvgIcon />}
              marginBottom={16}
            />
            {
              Platform.OS === 'ios' && (
                  <AppleAuth isLogin={true} setUserState={setUserState} setLoadingSocialAuth={setLoadingSocialAuth} trackId={'apple_signup'}/>
              )
            }

            <View style={{ flexDirection: 'row', alignItems: 'center', paddingBottom: 12 }}>
              <View style={{ flex: 1, height: 1, backgroundColor: themeColors.gray300 }} />
              <View>
                <Text
                  style={{
                    width: 50,
                    textAlign: 'center',
                    fontFamily: themeFontFamily.itcGaramondStd,
                    fontSize: themeFontSizes.md,
                    color: themeColors.gray300,
                  }}
                >
                  OR
                </Text>
              </View>
              <View style={{ flex: 1, height: 1, backgroundColor: themeColors.gray300 }} />
            </View>

            {/* <Pressable
              onPress={() => {
                promptAsync()
              }}
              style={[styles.socialsContainer, { marginHorizontal: 24 }]}
            >
              <GoogleLogoSvgIcon />
            </Pressable> */}

            {/* <Pressable
              onPress={handleAppleSignIn}
              style={styles.socialsContainer}>
              <Ionicons name={'logo-apple'} size={40} />
            </Pressable> */}
          </View>

          <View style={[styles.form]}>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <View style={styles.textInputStyle}>
                  <TextInput
                    style={[
                      styles.input,
                      {
                        fontSize: themeFontSizes.md,
                        color: themeColors.placeHolderColor,
                        fontFamily: 'chalet',
                        fontWeight: '400',
                        lineHeight: 14,
                      },
                    ]}
                    placeholder="Email address"
                    onChangeText={(value: string) => {
                      onChange(value)
                    }}
                    placeholderTextColor={themeColors.placeHolderColor}
                  />
                  {/* <Ionicons name="mail-outline" size={24} /> */}
                </View>
              )}
              name="email"
              rules={{ required: true }}
            />

            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <View style={[styles.textInputStyle, { marginTop: 16 }]}>
                  <TextInput
                    style={[
                      styles.input,
                      {
                        fontSize: themeFontSizes.md,
                        color: themeColors.placeHolderColor,
                        fontFamily: 'chalet',
                        fontWeight: '400',
                        lineHeight: 14,
                      },
                    ]}
                    placeholder="Password"
                    secureTextEntry
                    onChangeText={(value: string) => {
                      onChange(value)
                    }}
                    placeholderTextColor={themeColors.placeHolderColor}
                  />
                  {/* <Ionicons name="lock-closed-outline" size={24} /> */}
                </View>
              )}
              name="password"
              rules={{ required: true }}
            />

            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <View style={[styles.textInputStyle, { marginTop: 16 }]}>
                  <TextInput
                    style={[
                      styles.input,
                      {
                        fontSize: themeFontSizes.md,
                        color: themeColors.placeHolderColor,
                        fontFamily: 'chalet',
                        fontWeight: '400',
                        lineHeight: 14,
                      },
                    ]}
                    placeholder="Confirm password"
                    secureTextEntry
                    onChangeText={(value: string) => {
                      onChange(value)
                    }}
                    placeholderTextColor={themeColors.placeHolderColor}
                  />
                  {/* <Ionicons name="lock-closed-outline" size={24} /> */}
                </View>
              )}
              name="confirmPassword"
              rules={{ required: true }}
            />
          </View>
        </View>

        <View style={styles.buttonContainer}>
          <BaseButton
            onPress={handleSubmit(onSubmit)}
            isLoading={isLoading}
            title="continue"
            backgroundColor={themeColors.black}
          />

          <Pressable style={styles.altAction} onPress={goToLoginScreen}>
            <Text style={styles.textAction}>Already have an account?</Text>
            <Text style={styles.textLink}>Log in</Text>
          </Pressable>
        </View>
      </ScrollView>
      {loadingSocialAuth && (
        <View style={styles.loading}>
          <ActivityIndicator size="large" color={themeColors.white} />
        </View>
      )}
      {/* </KeyboardAvoidingView> */}
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    backgroundColor: themeColors.appBackground,
    paddingTop: 16,
    paddingHorizontal: 16,
  },

  logoWrapper: {
    flexBasis: 440,
    flexGrow: 0,
    flexShrink: 1,
  },

  buttonContainer: {
    flexBasis: 120,
    flexGrow: 1,
    flexShrink: 0,
    justifyContent: 'flex-end',
  },

  socialsWrapper: {
    paddingTop: 16,
  },

  form: {
    width: '100%',
  },

  textInputStyle: {
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: themeColors.white,
    paddingHorizontal: 16,
    borderRadius: 2,
    borderColor: themeColors.inputBoarder,
    // height: 46
  },

  input: {
    flex: 1,
    backgroundColor: 'transparent',
    height: 46,
  },

  altAction: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  textAction: {
    fontWeight: '300',
    color: themeColors.black,
    fontStyle: 'italic',
    lineHeight: 20,
    fontSize: themeFontSizes.md,
    fontFamily: 'itc-garamond-std',
  },

  textLink: {
    color: themeColors.black,
    textDecorationLine: 'underline',
    fontWeight: '400',
    marginStart: 5,
    fontFamily: 'chalet',
    lineHeight: 17,
    opacity: 0.8,
  },

  loading: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
})
