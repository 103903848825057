import {
  Alert,
  EmitterSubscription,
  Keyboard,
  KeyboardAvoidingView,
  KeyboardEvent,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { themeColors, themeFontSizes } from '../../themes'
import { Ionicons } from '@expo/vector-icons'
import { BaseButton } from '../../components/base-button'
import { BottomSheetModal } from '@gorhom/bottom-sheet'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { BaseBottomSheetModal } from '../../components/base-bottom-sheet'
import { UserCountryContext } from '../../context/user-country-context'
import { goToLandingScreen } from '../../service/navigation-service'
import { track } from '../../utils/mix-panel-utils'
import { Logo } from '../../icons/logo'

enum SUPPORTED_COUNTRY_ENUM {
  'CANADA' = 'canada',
  'UNITED STATES' = 'united states',
  'UNITED KINDOM' = 'united kindom',
  'OTHERS' = 'Others',
}

const supportedCountries = [
  {
    name: 'United States',
    unicodeFlag: '🇺🇸',
  },
  {
    name: 'Canada',
    unicodeFlag: '🇨🇦',
  },

  {
    name: 'United Kingdom',
    unicodeFlag: '🇬🇧',
  },

  {
    name: 'Others',
    unicodeFlag: '',
  },
]

const ssuggestCountryScheme = yup.object().shape({
  country: yup.string().required(),
})

export function CountrySelectionScreen() {
  const { setUserCountry } = useContext(UserCountryContext)
  const [selectedCountry, setSelectedCountry] = useState('')
  const [displayForm, setDisplayForm] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(ssuggestCountryScheme),
  })
  const [keyboardOffset, setKeyboardOffset] = useState(0)
  const onKeyboardShow = (event: { endCoordinates: { height: React.SetStateAction<number> } }) =>
    setKeyboardOffset(event.endCoordinates.height)
  const onKeyboardHide = () => setKeyboardOffset(0)
  const keyboardDidShowListener = useRef<any>(null)
  const keyboardDidHideListener = useRef<any>(null)

  useEffect(() => {
    keyboardDidShowListener.current = Keyboard.addListener('keyboardWillShow', onKeyboardShow)
    keyboardDidHideListener.current = Keyboard.addListener('keyboardWillHide', onKeyboardHide)

    return () => {
      keyboardDidShowListener.current.remove()
      keyboardDidHideListener.current.remove()
    }
  }, [])

  const bottomSheetModalRef = useRef<BottomSheetModal>(null)
  const snapPoints = useMemo(() => ['90%'], [])

  const onOpenButtomSheet = () => {
    bottomSheetModalRef.current?.present()
  }

  const onConfirmPress = () => {
    bottomSheetModalRef.current?.close()
  }

  const handleSelect = (value: any) => {
    if (value.name !== SUPPORTED_COUNTRY_ENUM.OTHERS) {
      setDisplayForm(false)
      setSelectedCountry(value.name)
      onConfirmPress()
    } else {
      setDisplayForm(true)
    }
  }

  const handleContinue = () => {
    setUserCountry({ country: selectedCountry })
  }

  const onSubmit = (data: any) => {
    // @todo api success
    track('unsupported_country_event', data)
    Alert.alert('Success', `Your request has been submitted`, [
      // {
      //   text: "Cancel",
      //   // onPress: () => console.log("Cancel Pressed"),
      //   style: "cancel"
      // },
      {
        text: 'Ok',
        onPress: () => {
          goToLandingScreen()
          setUserCountry({ country: undefined })
          setSelectedCountry('')
          onConfirmPress()
          setDisplayForm(false)
          // route to landing screen
        },
      },
    ])
  }

  const isDisabled = supportedCountries.filter((e) => e.name === selectedCountry).length > 0

  return (
    <View style={styles.container}>
      <View style={styles.logoWrapper}>
        <View style={{ borderBottomWidth: 1.5, paddingBottom: 10 }}>
          <Logo />
        </View>

        <View style={{ marginTop: 40, width: '90%' }}>
          <Text
            style={{
              fontSize: themeFontSizes.md,
              lineHeight: 18,
              fontWeight: '500',
              fontFamily: 'Druk-medium',
              // textTransform: 'uppercase',
              // opacity: 0.8,
            }}
          >
            Add country
          </Text>
        </View>
        <View>
          <Text style={styles.info}>
            SellStuff depends on your region to serve you better, please select your current region
            to continue.
          </Text>
        </View>
      </View>

      <Pressable onPress={onOpenButtomSheet} style={[styles.textInputStyle, { marginTop: 16 }]}>
        <TextInput
          style={[
            styles.input,
            {
              fontSize: themeFontSizes.md,
              color: themeColors.placeHolderColor,
              fontFamily: 'chalet',
              fontWeight: '400',
              lineHeight: 14,
            },
          ]}
          placeholder="Select Country"
          value={selectedCountry}
          editable={false}
          onPressIn={onOpenButtomSheet}
        />
        <Ionicons name="chevron-down-outline" size={24} />
      </Pressable>
      <BaseButton
        onPress={handleContinue}
        isLoading={isLoading}
        isDisabled={!isDisabled}
        title="Continue"
        backgroundColor={themeColors.black}
      />
      <BaseBottomSheetModal
        snapPoints={snapPoints}
        onDismiss={onConfirmPress}
        bottomSheetModalRef={bottomSheetModalRef}
      >
        <ScrollView
          showsVerticalScrollIndicator={false}
          keyboardDismissMode={'interactive'}
          style={{ flex: 1 }}
        >
          <View style={{ marginBottom: keyboardOffset > 0 ? 258 : 0 }}>
            <View
              style={{
                paddingHorizontal: 16,
                paddingVertical: 16,
              }}
            >
              <Text
                style={{
                  fontSize: themeFontSizes.lg,
                  fontWeight: '500',
                  fontFamily: 'Druk-medium',
                }}
              >
                Select Country
              </Text>
              <Text style={styles.info}>
                Kindly note that the supported countries are based on the locations of our supported
                platforms. If your nation is not included here, kindly click on others and request
                that we expand to your area.
              </Text>
            </View>

            {supportedCountries?.map((country, index) => {
              const active = country.name === selectedCountry
              return (
                <Pressable
                  onPress={() => handleSelect(country)}
                  style={styles.flexItem}
                  key={index}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                    }}
                  >
                    {/* <Text style={{ marginEnd: 10 }}>{country.unicodeFlag}</Text> */}
                    <Text
                      style={{
                        fontSize: themeFontSizes.md,
                        color:
                          displayForm && country.name === SUPPORTED_COUNTRY_ENUM.OTHERS
                            ? themeColors.secondary
                            : 'black',
                      }}
                    >
                      {country.name}
                    </Text>
                  </View>
                  {active && (
                    <View>
                      <Ionicons
                        name="checkmark-circle-outline"
                        size={16}
                        color={themeColors.primary}
                      />
                    </View>
                  )}
                </Pressable>
              )
            })}

            <>
              {displayForm && (
                <View
                  style={{
                    paddingHorizontal: 16,
                    paddingTop: 45,
                    paddingBottom: 10,
                  }}
                >
                  <Text
                    style={{
                      fontSize: themeFontSizes.md,
                      fontWeight: '400',
                      marginBottom: 10,
                    }}
                  >
                    Sorry we aren’t able to support your region, please enter your country to add a
                    request for future support.
                  </Text>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <View style={[styles.textInputStyle, { marginTop: 10 }]}>
                        <TextInput
                          style={[
                            styles.input,
                            {
                              fontSize: themeFontSizes.md,
                              color: themeColors.placeHolderColor,
                              fontFamily: 'chalet',
                              fontWeight: '400',
                              lineHeight: 14,
                            },
                          ]}
                          placeholder="Enter your country"
                          onChangeText={(value: string) => {
                            onChange(value)
                          }}
                        />
                      </View>
                    )}
                    name="country"
                    rules={{ required: true }}
                  />

                  <BaseButton
                    onPress={handleSubmit(onSubmit)}
                    isLoading={isLoading}
                    isDisabled={isLoading}
                    title="Submit"
                    backgroundColor={themeColors.black}
                  />
                </View>
              )}
            </>
          </View>
        </ScrollView>
      </BaseBottomSheetModal>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: themeColors.appBackground,
    paddingVertical: 30,
    paddingHorizontal: 16,
  },

  logoWrapper: {},

  logoContainer: {
    backgroundColor: themeColors.primary,
    width: '52%',
    borderRadius: 15,
    paddingHorizontal: 6,
    paddingTop: 54,
  },

  formContainer: {
    flex: 2.5,
    backgroundColor: themeColors.white,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    paddingHorizontal: 24,
  },

  handle: {
    borderWidth: 1,
    height: 5,
    width: '12%',
    alignSelf: 'center',
    marginTop: 10,
    borderRadius: 15,
    backgroundColor: themeColors.gray200,
    borderColor: themeColors.gray200,
    marginBottom: 30,
  },

  title: {
    textAlign: 'center',
    fontSize: themeFontSizes['2xl'],
  },

  form: {
    width: '100%',
  },

  textInputStyle: {
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: themeColors.white,
    paddingHorizontal: 16,
    borderRadius: 2,
    borderColor: themeColors.inputBoarder,
  },

  input: {
    flex: 1,
    backgroundColor: 'transparent',
    height: 46,
  },

  forgotPasswordText: {
    textAlign: 'right',
    color: themeColors.gray500,
    marginVertical: 24,
  },

  button: {
    height: 50,
    justifyContent: 'center',
    marginTop: 16,
    marginBottom: 24,
    borderRadius: 20,
  },

  buttonText: {
    textTransform: 'uppercase',
    color: themeColors.white,
    textAlign: 'center',
    fontWeight: '700',
  },

  avatarWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 20,
  },

  avatarPlaceholder: {
    width: 100,
    height: 100,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: themeColors.gray100,
    paddingStart: 6,
  },

  image: {
    width: 100,
    height: 100,
    borderRadius: 50,
  },

  info: {
    fontSize: themeFontSizes.md,
    marginVertical: 16,
    lineHeight: 18,
    color: themeColors.gray300,
    fontFamily: 'chalet',
  },

  flexItem: {
    borderBottomWidth: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 16,
    paddingVertical: 16,
    borderBottomColor: themeColors.gray200,
  },
})
