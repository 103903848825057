import { View, Text, StyleSheet, Pressable, ActivityIndicator, Image } from 'react-native'
import React from 'react'
import { goProfile } from '../../../service/navigation-service'
import { supportedMarketplacesType } from '../../../utils/types'
import { themeColors, themeFontSizes } from '../../../themes'
import { Ionicons } from '@expo/vector-icons'
//@ts-ignore
import MercariLogo from '../../../../assets/mercari-icon.png'
import DepopLogo from '../../../../assets/depop-logo.png'
import usePlatformStatus from '../../../hooks/usePlatformStatus'
import { mergeArrays } from '../../../utils/merge-arrays'
import { themeFontFamily } from '../../../themes/theme-font-family'

type ConnectedMarketplaceProps = {
  profilePlatforms: supportedMarketplacesType[]
  isLoadingPlatform: boolean
  ready: boolean
}

export const marketPlaceMap = {
  Mercari: MercariLogo,
  depop: DepopLogo,
}

export const ConnectedMarketplace = (props: ConnectedMarketplaceProps) => {
  const { profilePlatforms, isLoadingPlatform, ready } = props
  const { data } = usePlatformStatus()
  const mergedData = mergeArrays(profilePlatforms, data)

  if (isLoadingPlatform) {
    return (
      <View>
        <Text>Connected Marketplaces</Text>
        <View
          style={[
            styles.platformWrapper,
            {
              marginRight: 10,
              marginTop: 10,
            },
          ]}
        >
          <ActivityIndicator size="small" color={themeColors.gray300} />
          <Text style={[styles.plaformIsLoadingText]}>Loading...</Text>
        </View>
      </View>
    )
  }

  return (
    <View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Text style={styles.sectionTitleItalics}>Connected</Text>
        <Text style={styles.sectionTitle}>Marketplaces</Text>
      </View>

      <View style={styles.platformWrapper}>
        {mergedData?.map((platform, index) => {
          return (
            <View style={styles.logoWrapper} key={index}>
              {ready && (
                <View style={styles.activeIcon}>
                  {platform?.ready ? (
                    <Ionicons name={'checkmark-circle'} size={18} color={'green'} />
                  ) : (
                    <Ionicons name={'close-circle'} size={18} color={'red'} />
                  )}
                </View>
              )}
              <Pressable style={styles.platformLogoWrapper}>
                <Image
                  key={platform.id}
                  // @ts-ignore
                  source={marketPlaceMap[platform?.name]}
                  style={{ width: 24, height: 24, borderRadius: 24 }}
                />
              </Pressable>
            </View>
          )
        })}

        <Pressable
          onPress={() => goProfile()}
          style={[
            styles.platformLogoWrapper,
            {
              paddingLeft: 1.5,
            },
          ]}
        >
          <Ionicons name={'add-circle'} size={28} color={'black'} style={{ marginRight: 0 }} />
        </Pressable>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  platformWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  platformLogoWrapper: {
    justifyContent: 'center',
    width: 30,
    height: 30,
    borderRadius: 16,
    alignItems: 'center',
    borderWidth: 2,
    backgroundColor: themeColors.white,
    marginRight: 10,
    marginTop: 10,
  },

  logoWrapper: {
    position: 'relative',
  },

  activeIcon: {
    position: 'absolute',
    top: 3,
    zIndex: 1,
    right: 0,
    backgroundColor: themeColors.appBackground,
    height: 20,
    width: 20,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
  },

  sectionTitleItalics: {
    fontSize: themeFontSizes.lg,
    color: themeColors.black,
    fontWeight: '300',
    fontFamily: themeFontFamily.itcGaramondStd,
    fontStyle: 'italic',
    opacity: 0.8,
  },

  sectionTitle: {
    fontSize: themeFontSizes.lg,
    color: themeColors.black,
    fontWeight: '500',
    marginStart: 5,
    opacity: 0.8,
    fontFamily: 'chalet',
  },

  plaformIsLoadingText: {
    fontSize: themeFontSizes.sm,
    lineHeight: 14,
    color: themeColors.black,
    fontWeight: '400',
    fontFamily: 'chalet',
    marginLeft: 8,
  },
})
