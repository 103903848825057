import axios from 'axios'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { configValue } from '../config/config-values'

const axiosInstance = axios.create({
  baseURL: configValue.apiHost,
  timeout: 10000,
})

export const axiosGet = async ({
  url,
  authorization,
  params,
}: {
  url: string
  authorization?: string
  params?: any
}) => {
  const auth = (await AsyncStorage.getItem('auth')) as any
  const authJson = JSON.parse(auth)
  const authToken = authorization ? `${authorization}` : `${authJson.token}`

  console.log('--token--', authToken)

  let response
  try {
    response = await axiosInstance.get(url, {
      params,
      headers: {
        Authorization: authToken,
        'Content-Type': 'application/json',
      },
    })
  } catch (error) {
    return error
  }
  return response?.data
}

export const axiosPost = async ({
  url,
  data,
  contentType = 'multipart/form-data',
  authorization,
}: {
  url: string
  data: any
  contentType?: string
  authorization?: string
}) => {
  const auth = (await AsyncStorage.getItem('auth')) as any
  const authJson = JSON.parse(auth)
  const authToken = authorization ?? `${authJson.token}`
  console.log('--token--', authToken)
  console.info('URL', url)
  let response
  try {
    response = await axiosInstance.post(url, data, {
      headers: {
        Authorization: authToken,
        'Content-Type': contentType,
      },
    })
  } catch (error) {
    return error
  }
  return response?.data
}

export const axiosPut = async ({
  url,
  data,
  contentType = 'application/json',
}: {
  url: string
  data: any
  contentType?: string
}) => {
  const token = await AsyncStorage.getItem('token')
  if (!token) {
    throw new Error('No Auth token provided')
  }
  const authToken = token ? `${token}` : ''
  let response
  try {
    response = await axiosInstance.put(url, data, {
      headers: {
        Authorization: authToken,
        'Content-Type': contentType,
      },
    })
  } catch (error) {
    return error
  }
  return response?.data
}

export const axiosDelete = async ({ url }: { url: string; data: any; contentType?: string }) => {
  const auth = (await AsyncStorage.getItem('auth')) as any
  const authJson = JSON.parse(auth)
  const authToken = `${authJson.token}` ?? ''
  let response
  try {
    response = await axiosInstance.delete(url, {
      headers: {
        Authorization: authToken,
        'Content-Type': 'application/json',
      },
    })
  } catch (error) {
    return error
  }
  return response?.data
}
