import React, { useState } from 'react'
import { Image, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { Logo } from '../../icons/logo'
import { themeColors, themeFontSizes } from '../../themes'
import { themeFontFamily } from '../../themes/theme-font-family'
import { ProgressBar } from './component/progress-bar'
import { MonthlySalesChart } from './component/monthly-sales-chart'
import {
  calculateChartMonthlySales,
  getTotalMonthlySales,
  getTotalSales,
  getTotalSalesByMarketplace,
  percentageOfTotalSales,
} from '../../helpers'
import { useGetMercariListings } from '../../hooks/use-get-mercari-listings'
import { marketPlaceMap } from '../home/component/connected-marketplace'

// TODO switch view between monthly chart and yearly chart.

type chartViewType = 'monthly' | 'yearly'

export function AnalyticsScreen() {
  const [showMToggleChartView, setToggleChartView] = useState<chartViewType>('monthly')
  const { listings: mercariItems, loading } = useGetMercariListings()

  const handleToggleChartView = (chartView: chartViewType) => {
    setToggleChartView(chartView)
  }

  // get total sales by Marketplace
  const mercariTotalSales = getTotalSalesByMarketplace(mercariItems)

  // percentageOfTotalSales
  const percentageOfMarketplaceTotalSales = percentageOfTotalSales(mercariTotalSales, 0)

  // get total sales
  const totalSales = getTotalSales(mercariItems, [])

  // get total sales by month
  const totalSalesByMonth = getTotalMonthlySales(mercariItems, [])

  // calculate total sales by month for chart
  const totalSalesByMonthForChart = calculateChartMonthlySales(mercariItems, [])

  return (
    <View style={styles.container}>
      <View style={[styles.headerWrapper]}>
        <View>
          <View style={{ paddingBottom: 16 }}>
            <Logo />
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              paddingBottom: 16,
            }}
          >
            <Text
              style={{
                fontSize: themeFontSizes.xl,
                fontFamily: themeFontFamily.itcGaramondStd,
                fontWeight: '300',
                lineHeight: 20,
                opacity: 0.8,
                marginRight: 8,
              }}
            >
              My
            </Text>
            <Text
              style={{
                fontSize: themeFontSizes.xl,
                fontFamily: 'chalet',
                fontWeight: '400',
                lineHeight: 22,
              }}
            >
              Analytics
            </Text>
          </View>
        </View>
      </View>

      <ScrollView showsVerticalScrollIndicator={false}>
        {/* Total sales card */}
        <View style={styles.totalSalesCard}>
          <Text style={styles.totalSalesCardTitle}>Total sales</Text>
          <Text style={styles.totalSalesCardAmount}>${totalSales.toLocaleString()}</Text>
        </View>

        {/* Sales by MP */}
        <View style={styles.salesByMarketPlaceCard}>
          <Text style={styles.salesByMarketPlaceTitle}>Sales by Marketplace</Text>

          <View>
            {percentageOfMarketplaceTotalSales?.map((data, index) => {
              return (
                <View
                  key={index}
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 16,
                  }}
                >
                  <View
                    style={{
                      width: '35%',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <View
                      style={{
                        width: 25,
                        height: 25,
                        borderRadius: 20,
                      }}
                    >
                      {/* @ts-ignore */}
                      <Image
                        source={marketPlaceMap[data?.name]}
                        style={{
                          width: '100%',
                          height: '100%',
                          borderRadius: 20,
                        }}
                      />
                    </View>
                    <Text
                      style={{
                        fontWeight: '400',
                        fontSize: themeFontSizes.md,
                        marginLeft: 4,
                        fontFamily: 'chalet',
                        lineHeight: 17,
                        textTransform: 'capitalize',
                      }}
                    >
                      {data.name}
                    </Text>
                  </View>

                  <View
                    style={{
                      width: '65%',
                    }}
                  >
                    <ProgressBar percentage={data.percentage} />
                  </View>
                </View>
              )
            })}
          </View>
        </View>

        {/*Avg monthly sales */}
        <View style={styles.totalSalesCard}>
          <Text style={styles.totalSalesCardTitle}>Average monthly sales</Text>
          <Text style={styles.totalSalesCardAmount}>${totalSalesByMonth.toLocaleString()}</Text>
        </View>

        {/* Charts section*/}
        <View
          style={{
            backgroundColor: themeColors.white,
            paddingVertical: 24,
            paddingHorizontal: 24,
            marginVertical: 16,
          }}
        >
          <View
            style={{
              paddingBottom: 16,
            }}
          >
            <Text
              style={{
                fontWeight: '600',
                fontSize: themeFontSizes.lg,
                fontFamily: 'chalet',
                lineHeight: 22,
              }}
            >
              My sales
            </Text>
          </View>

          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <TouchableOpacity
              onPress={() => handleToggleChartView('monthly')}
              style={{
                paddingVertical: 8,
                borderWidth: 1,
                paddingHorizontal: 16,
                marginRight: 16,
                borderRadius: 15,
                backgroundColor:
                  showMToggleChartView === 'monthly' ? themeColors.black : themeColors.white,
              }}
            >
              <Text
                style={{
                  fontWeight: '400',
                  fontSize: themeFontSizes.md,
                  color: showMToggleChartView === 'monthly' ? themeColors.white : themeColors.black,
                  fontFamily: 'chalet',
                }}
              >
                Monthly
              </Text>
            </TouchableOpacity>
            {/* <TouchableOpacity
              onPress={() => handleToggleChartView('yearly')}
              style={{
                paddingVertical: 8,
                borderWidth: 1,
                paddingHorizontal: 16,
                marginRight: 16,
                borderRadius: 15,
                backgroundColor: showMToggleChartView === 'yearly' ? themeColors.black : themeColors.white
              }}
            >
              <Text
                style={{
                  fontWeight: '400',
                  fontSize: themeFontSizes.md,
                  color: showMToggleChartView === 'yearly' ? themeColors.white : themeColors.black,
                  fontFamily: 'chalet'
                }}
              >Yearly
              </Text>
            </TouchableOpacity> */}
          </View>
          <View style={{ paddingVertical: 16 }}>
            <MonthlySalesChart totalSalesByMonthForChart={totalSalesByMonthForChart} />
            {/* {
              showMToggleChartView === 'monthly' ?
                <MonthlySalesChart totalSalesByMonthForChart={totalSalesByMonthForChart} /> : <YearlySalesChart />
            } */}
          </View>
        </View>
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: themeColors.appBackground,
    paddingHorizontal: 16,
    flex: 1,
  },

  headerWrapper: {
    paddingTop: 16,
  },

  totalSalesCard: {
    backgroundColor: 'white',
    borderRadius: 3,
    padding: 16,
  },

  totalSalesCardTitle: {
    fontSize: themeFontSizes.lg,
    fontWeight: '400',
    color: themeColors.black,
    textTransform: 'capitalize',
    fontFamily: 'chalet',
    lineHeight: 22,
  },

  totalSalesCardAmount: {
    fontSize: themeFontSizes.xl,
    fontWeight: '400',
    color: themeColors.black,
    fontFamily: 'chalet',
    lineHeight: 18,
    marginTop: 16,
  },

  salesByMarketPlaceCard: {
    backgroundColor: 'white',
    borderRadius: 3,
    padding: 16,
    marginVertical: 16,
  },

  salesByMarketPlaceTitle: {
    fontSize: themeFontSizes.lg,
    fontWeight: '400',
    color: themeColors.black,
    textTransform: 'capitalize',
    fontFamily: 'chalet',
    lineHeight: 22,
    marginBottom: 16,
  },
})
