import {
  doc,
  DocumentData,
  DocumentSnapshot,
  getFirestore,
  onSnapshot,
  Timestamp,
} from 'firebase/firestore'
import { useState, useContext, useCallback, useEffect } from 'react'
import { AuthContext } from '../context/auth-context'

export type Job<T> = {
  createdAt: Timestamp
  method: string
  platform: string
  ready: boolean
  result: T
  syncing: boolean
  user: string
  id: string
  error?: string
}

const useJob = <T>(id: string) => {
  const [job, setJob] = useState<Job<T> | null>(null)
  const { authState } = useContext(AuthContext)

  const jobCallback = useCallback(
    (doc: DocumentSnapshot<DocumentData>) => {
      if (doc.exists()) {
        setJob({
          id: doc.id,
          ...doc.data(),
        } as Job<T>)
      } else {
        setJob(null)
      }
    },
    [setJob],
  )

  useEffect(() => {
    if (!authState?.isLoggedIn) {
      return () => {}
    }

    if (id === '') {
      return
    }

    const db = getFirestore()
    const job = doc(db, 'puppeteerJobs', id)
    const unsubscribe = onSnapshot(job, jobCallback)

    return unsubscribe
  }, [authState, id, setJob])

  if (job === null) {
    return {} as Job<T>
  }

  return job
}

export default useJob
