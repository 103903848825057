import { Ionicons } from '@expo/vector-icons'
import Checkbox from 'expo-checkbox'
import React, { useContext, useState } from 'react'
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Image,
  Pressable,
  Alert,
} from 'react-native'
import Modal from 'react-native-modal'
import { BaseButton } from '../../../components/base-button'
import { TransactionContext } from '../../../context/TransactionProvider'
import { shippingChoicesType, Transaction } from '../../../hooks/useTransaction'
import { themeColors, themeFontSizes } from '../../../themes'
import { themeFontFamily } from '../../../themes/theme-font-family'

// todo handle loading
type ShippingChoicesModalProps = {
  visible: boolean
  setShippingChoicesModalVisible: (e: boolean) => void
  transactions: Transaction[]
}

const txn = [
  {
    RequestType: 'shippingChoices',
    Platform: 'web',
    details: {
      shippingChoices: [
        {
          index: 0,
          title: 'FedEx',
          price: '$10.00',
          logo: 'https://www.fedex.com/content/dam/fedex/us-united-states/images/logos/fedex-logo.svg',
        },
        {
          index: 1,
          title: 'UPS',
          price: '$10.00',
          logo: 'https://www.ups.com/assets/resources/images/logos/ups-logo.svg',
        },
        {
          index: 2,
          title: 'UPS',
          price: '$10.00',
          logo: 'https://www.ups.com/assets/resources/images/logos/ups-logo.svg',
        },
        {
          index: 3,
          title: 'UPS',
          price: '$10.00',
          logo: 'https://www.ups.com/assets/resources/images/logos/ups-logo.svg',
        },
        {
          index: 4,
          title: 'UPS',
          price: '$10.00',
          logo: 'https://www.ups.com/assets/resources/images/logos/ups-logo.svg',
        },
        {
          index: 5,
          title: 'UPS',
          price: '$10.00',
          logo: 'https://www.ups.com/assets/resources/images/logos/ups-logo.svg',
        },
        {
          index: 6,
          title: 'UPS',
          price: '$10.00',
          logo: 'https://www.ups.com/assets/resources/images/logos/ups-logo.svg',
        },
      ],
    },
  },
]

export const ShippingChoicesModal = (props: ShippingChoicesModalProps) => {
  const { visible, setShippingChoicesModalVisible, transactions } = props
  const [selectedShippingChoiceIndex, setSelectedShippingChoiceIndex] = useState<number>(0)
  const [showConfirmationAlert, setShowConfirmationAlert] = useState<boolean>(false)
  const [shippingTitle, setShippingTitle] = useState<string>('')

  const handleSelect = (item: shippingChoicesType) => {
    setSelectedShippingChoiceIndex(item.index)
    setShippingTitle(item.title)
  }

  return (
    <View style={{ flex: 1 }}>
      <Modal isVisible={visible}>
        <View style={styles.modalContainer}>
          <View>
            <Text style={styles.title}>Shipping Choices </Text>
            <Text style={styles.subTitle}>Select your shipping choice.</Text>
          </View>
          <ScrollView showsVerticalScrollIndicator={false}>
            {transactions
              ?.filter(({ RequestType, Platform }) => RequestType === 'shippingChoices')
              .map((transaction, index) => {
                console.log('==', transaction)
                const { details } = transaction
                return (
                  <View key={index}>
                    {details?.shippingChoices.map((shippingChoice, idx) => {
                      const isChecked = selectedShippingChoiceIndex === shippingChoice.index
                      return (
                        <Pressable
                          onPress={() => {
                            transaction.sendResponse(shippingChoice.index as unknown as string)
                            setShippingChoicesModalVisible(false)
                            // handleSelect(shippingChoice);
                            // setShowConfirmationAlert(true)
                          }}
                          style={[styles.shippingContainer]}
                          key={idx}
                        >
                          <View
                            style={{ flexDirection: 'row', alignItems: 'center', width: '80%' }}
                          >
                            <View
                              style={{
                                width: 60,
                                height: 50,
                                marginEnd: 14,
                              }}
                            >
                              <Image
                                source={{ uri: shippingChoice.logo }}
                                style={{ width: 60, height: 50, resizeMode: 'contain' }}
                              />
                            </View>
                            <View>
                              <Text>{shippingChoice?.title}</Text>
                              <Text>{shippingChoice?.price}</Text>
                            </View>
                          </View>
                          {isChecked && (
                            <Ionicons
                              name="checkmark-circle-outline"
                              size={24}
                              color={themeColors.secondary}
                            />
                          )}
                        </Pressable>
                      )
                    })}
                    {/* <ConfirmationModal
                      visible={showConfirmationAlert}
                      setShowConfirmationAlert={setShowConfirmationAlert}
                      setShippingChoicesModalVisible={setShippingChoicesModalVisible}
                      selectedShippingChoiceIndex={selectedShippingChoiceIndex}
                      title={shippingTitle}
                      transaction={transaction}
                    /> */}
                  </View>
                )
              })}
          </ScrollView>
        </View>
      </Modal>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  modalContainer: {
    flex: 1,
    backgroundColor: themeColors.white,
    marginVertical: 30,
    paddingHorizontal: 20,
    paddingVertical: 24,
    borderRadius: 5,
  },

  confimationAlertContainer: {
    flex: 1,
    backgroundColor: themeColors.white,
    marginVertical: 180,
    paddingHorizontal: 20,
    paddingVertical: 24,
    borderRadius: 5,
  },

  title: {
    // textAlign: 'center',
    fontSize: themeFontSizes.xl,
    lineHeight: 22,
    fontWeight: '400',
    fontFamily: 'chalet',
    letterSpacing: 0.05,
  },

  subTitle: {
    fontSize: themeFontSizes.lg,
    color: themeColors.black,
    marginTop: 4,
    fontFamily: themeFontFamily.itcGaramondStd,
    fontWeight: '300',
    lineHeight: 20,
    opacity: 0.8,
  },

  textInputStyle: {
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: themeColors.gray100,
    paddingHorizontal: 16,
    borderRadius: 25,
    borderColor: themeColors.gray100,
  },

  input: {
    flex: 1,
    backgroundColor: 'transparent',
    color: '#424242',
    paddingVertical: 16,
  },

  shippingContainer: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: themeColors.gray200,
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    paddingVertical: 16,
    outlineColor: 'transparent',
  },
})

type ConfirmationModalProps = {
  visible: boolean
  transaction: Transaction
  title: string
  setShippingChoicesModalVisible: (value: boolean) => void
  setShowConfirmationAlert: (value: boolean) => void
  selectedShippingChoiceIndex: number
}
const ConfirmationModal = (props: ConfirmationModalProps) => {
  const {
    visible,
    title,
    transaction,
    setShippingChoicesModalVisible,
    setShowConfirmationAlert,
    selectedShippingChoiceIndex,
  } = props

  return (
    <View style={{ flex: 1 }}>
      <Modal isVisible={visible}>
        <View style={styles.confimationAlertContainer}>
          <View
            style={{
              marginBottom: 16,
            }}
          >
            <Text style={styles.title}>Confirm shipping choice</Text>
          </View>
          <View>
            <Text>You have selected {title} as your shipping choice, do you wish to procceed?</Text>
          </View>
          <BaseButton
            onPress={() => {
              transaction.sendResponse(selectedShippingChoiceIndex as unknown as string)
              setShippingChoicesModalVisible(false)
              setShowConfirmationAlert(false)
            }}
            title="Continue"
            backgroundColor={themeColors.black}
            color={themeColors.white}
            marginBottom={10}
          />
          <BaseButton
            onPress={() => {
              setShowConfirmationAlert(false)
            }}
            title="Cancel"
            backgroundColor={themeColors.white}
            color={themeColors.black}
            marginTop={0}
          />
        </View>
      </Modal>
    </View>
  )
}
